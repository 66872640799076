import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Link as ChakraLink,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { RiderIdentity } from '@tradeaze-packages/schemas';
import {
  useGetRiderDeliverySchedule,
  useSetDeliveryStopsSequence,
} from '@tradeaze/frontend/hooks';
import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DeliverySchedule } from '../delivery/schedule-view/delivery-schedule/DeliverySchedule';
import { IconText } from '../shared';

type RiderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  rider: RiderIdentity & { onDuty?: boolean | null };
};

const ExternalLink = ({ to, text }: { to: string; text: string }) => {
  return (
    <ChakraLink
      as={RouterLink}
      to={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconText icon={ExternalLinkIcon} text={text} fontSize="sm" />
    </ChakraLink>
  );
};

export const RiderModal = ({ isOpen, onClose, rider }: RiderModalProps) => {
  const riderScheduleQuery = useGetRiderDeliverySchedule(
    {
      riderId: rider?.riderId || '',
      date: new Date(), // today
    },
    {
      enabled: isOpen && !!rider?.riderId,
    },
  );

  const setStopsMutation = useSetDeliveryStopsSequence();

  const handleSaveStopOrder = useCallback(
    (stopIds: string[]) => {
      if (!riderScheduleQuery.data) return;

      setStopsMutation.mutate({
        deliveryScheduleId: riderScheduleQuery.data.deliveryScheduleId,
        deliveryStopIds: stopIds,
      });
    },
    [riderScheduleQuery.data, setStopsMutation],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxHeight={'85vh'} overflow='hidden' borderRadius={'lg'}>
        <ModalBody p={0} pb={6} overflowY='auto'>
          <ModalCloseButton />
          <DeliverySchedule
            rider={rider}
            fleetVehicle={rider.lastUsedVehicle || undefined}
            schedule={riderScheduleQuery.data ?? undefined}
            isLoadingSchedule={riderScheduleQuery.isLoading}
            isLoadingSave={setStopsMutation.isLoading}
            handleChangeMarkerIndices={() => null}
            handleSaveStopOrder={handleSaveStopOrder}
            extraButtons={
              <HStack spacing={4}>
                <ExternalLink
                  to={`/delivery-schedule?riderId=${rider.riderId}`}
                  text="Schedule"
                />
                <ExternalLink
                  to={`/fleet-management/riders/${rider.riderId}`}
                  text="Profile"
                />
              </HStack>
            }
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
