import { HStack } from '@chakra-ui/react';
import { Z_INDEX } from '../constants';
import { NAVBAR_WIDTH } from '../navbar';

export const MapControlsContainerMobile = ({
  children,
  topPx = 0,
  zIndex = Z_INDEX.MAP_CONTROLS,
}: {
  children: React.ReactNode;
  topPx?: number;
  zIndex?: number;
}) => {
  return (
    <HStack
      position={'fixed'}
      top={`${NAVBAR_WIDTH + topPx}px`}
      zIndex={zIndex}
      left={0}
      padding={3}
      paddingTop={4}
    >
      {children}
    </HStack>
  );
};
