import parsePhoneNumberFromString from 'libphonenumber-js';
import { z } from 'zod';
import { postcodeValidator } from '../utils';
import { PositionSchema } from './shared-schema';
import { MerchantSiteContactSchema } from './site-contact-schema';
import { CreateDeliveryItemSchema } from './delivery-item-schema';
import { DeliveryStatusSchema } from './delivery-status-schema';

export const DeliveryStopTypeSchema = z.enum(['DROP_OFF', 'PICK_UP']);

export const DeliveryStopContactSchema = MerchantSiteContactSchema.extend({
  trackingEnabled: z.boolean(),
  isPrimary: z.boolean(),
  companyId: z.string().nullish(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  siteContactId: z.string().optional(),
});

export const CommonDeliveryStopSchema = z.object({
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  deliveryStopId: z.string(),
  orderId: z.string(),
  deliveryId: z.string().nullish(), // todo - remove nullish after migrations
  /** a persisted value used to sort stops in a delivery schedule. Includes completed stops */
  stopSequence: z.number(),
  /** a calculated value (not persisted) for the index of the stop when looking at incomplete stops in drivers schedule to show how many stops left */
  incompleteStopIndex: z.number().nullish(),
  type: DeliveryStopTypeSchema,
  addressId: z.string().nullish(),
  addressLine1: z.string().min(1, { message: 'Delivery address is required' }),
  addressLine2: z.string().nullish(),
  addressLine3: z.string().nullish(),
  postCode: postcodeValidator.makeSchema(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  windowStart: z.string(),
  windowEnd: z.string(),
  deliveryScheduleId: z.string().nullish(),
  companyName: z.string().min(1, { message: 'Company name is required' }),
  companyId: z.string().nullish(),
  contacts: z.array(DeliveryStopContactSchema),
  failureReason: z.string().nullish(),
  collectionReference: z.string().nullish(),
  position: PositionSchema.nullish(),
  deliveryNotes: z.string().nullish(),
  arrivedAt: z.string().nullish(),
  leftAt: z.string().nullish(),
  completedAt: z.string().nullish(),
  proofName: z.string().nullish(),
  requiresSignature: z.boolean().nullish(),
  requiresImage: z.boolean().nullish(),
  linkedDeliveryStopId: z.string().nullish(),
  requestedSequence: z.number().nullish(),
});

export const PickupDeliveryStopSchema = CommonDeliveryStopSchema.extend({
  type: z.literal('PICK_UP'),
});

export const DropoffDeliveryStopSchema = CommonDeliveryStopSchema.extend({
  type: z.literal('DROP_OFF'),
});

export const DeliveryStopSchema = CommonDeliveryStopSchema;

const createOmittedFields = {
  createdAt: true,
  updatedAt: true,
  merchantOrderReference: true,
  arrivedAt: true,
  leftAt: true,
  completedAt: true,
  deliveryScheduleId: true,
  deliveryId: true,
} as const;

export const CreateDeliveryStopContactSchema = DeliveryStopContactSchema.extend(
  {
    siteContactId: z.string().optional(),
    contactName: z.string().min(1, { message: 'Contact name is required' }),
    contactNumber: z
      .string()
      .refine(
        (value) => parsePhoneNumberFromString(value, 'GB')?.isValid() || false,
        {
          message: 'Invalid UK phone number',
        },
      ),
    trackingEnabled: z.boolean(),
    isPrimary: z.boolean(),
  },
);

export const CreatePickupStopSchema = PickupDeliveryStopSchema.omit(
  createOmittedFields,
).extend({
  orderId: z.string().optional(),
  deliveryStopId: z.string().optional(),
  id: z.string().optional(),
  status: DeliveryStatusSchema.optional(),
  city: z.string().min(1, { message: 'City is required' }), // only required on create schema because we are making it required in retrospect
});

export const CreateAToBPickupStopSchema = CreatePickupStopSchema.extend({
  deliveryItems: z.array(CreateDeliveryItemSchema).min(1, {
    message: 'At least one delivery item is required',
  }),
  collectionReference: z
    .string()
    .min(1, { message: 'Pickup reference is required' }),
});

export const CreateMultiCollectionPickupStopSchema =
  CreatePickupStopSchema.extend({
    collectionReference: z
      .string()
      .min(1, { message: 'Pickup reference is required' }),
    deliveryItems: z.array(CreateDeliveryItemSchema).min(1, {
      message: 'At least one delivery item is required',
    }),
  });

export const CreateMultiDropPickupStopSchema = CreatePickupStopSchema;

export const CreateDropOffStopSchema = DropoffDeliveryStopSchema.omit(
  createOmittedFields,
).extend({
  orderId: z.string().optional(),
  deliveryStopId: z.string().optional(),
  status: DeliveryStatusSchema.optional(),
  contacts: z
    .array(CreateDeliveryStopContactSchema)
    .min(1, { message: 'At least one contact is required' })
    .max(5, { message: 'Maximum of five contacts allowed' })
    .refine((contacts) => contacts.some((contact) => contact.isPrimary), {
      message: 'At least one contact must be primary',
    }),
  id: z.string().optional(),
  city: z.string().min(1, { message: 'City is required' }), // only required on create schema because we are making it required in retrospect
});

export const CreateAToBDropOffStopSchema = CreateDropOffStopSchema;

export const CreateMultiCollectionDropOffStopSchema = CreateDropOffStopSchema;

export const CreateMultiDropDropOffStopSchema = CreateDropOffStopSchema.extend({
  deliveryItems: z.array(CreateDeliveryItemSchema).min(1, {
    message: 'At least one delivery item is required',
  }),
  collectionReference: z
    .string()
    .min(1, { message: 'Pickup reference is required' }),
  status: DeliveryStatusSchema.optional(),
});

export const CreateDeliveryStopSchema = z.discriminatedUnion('type', [
  CreatePickupStopSchema,
  CreateDropOffStopSchema,
]);

const updateOmittedFields = {
  createdAt: true,
  updatedAt: true,
  deliveryId: true,
} as const;

export const UpdatePickupStopSchema = PickupDeliveryStopSchema.omit(
  updateOmittedFields,
).extend({
  orderId: z.string().optional(),
});

export const UpdateDropOffStopSchema = DropoffDeliveryStopSchema.omit(
  updateOmittedFields,
).extend({
  orderId: z.string().optional(),
  contacts: z
    .array(DeliveryStopContactSchema)
    .min(1, { message: 'At least one contact is required' })
    .max(5, { message: 'Maximum of five contacts allowed' })
    .refine((contacts) => contacts.some((contact) => contact.isPrimary), {
      message: 'At least one contact must be primary',
    }),
});

export const UpdateDeliveryStopSchema = z.discriminatedUnion('type', [
  UpdatePickupStopSchema,
  UpdateDropOffStopSchema,
]);

export type CreateDeliveryStop = z.infer<typeof CreateDeliveryStopSchema>;
export type UpdateDeliveryStop = z.infer<typeof UpdateDeliveryStopSchema>;
export type DeliveryStopType = z.infer<typeof DeliveryStopTypeSchema>;
export type PickupDeliveryStop = z.infer<typeof PickupDeliveryStopSchema>;
export type DropoffDeliveryStop = z.infer<typeof DropoffDeliveryStopSchema>;
export type DeliveryStop = z.infer<typeof DeliveryStopSchema>;
export type DeliveryStopContact = z.infer<typeof DeliveryStopContactSchema>;
export type CreatePickupStop = z.infer<typeof CreatePickupStopSchema>;
export type CreateDropOffStop = z.infer<typeof CreateDropOffStopSchema>;
