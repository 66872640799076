import { Box, Center, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import {
  calculateTotalWeightKg,
  formatDate,
  formatTimeRange,
  generateColorFromUUID,
  getAllItemDimensionsString,
  secondsToDurationText,
} from '@tradeaze/shared/utils';
import { useGrabCursor } from '@tradeaze/frontend/hooks';
import { AiFillStar } from 'react-icons/ai';
import { brandPrimaryColor } from '../../theme/colors';
import { LuClock } from 'react-icons/lu';
import { StopActions } from './StopActions';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';

export const DeliveryStopCard: React.FC<{
  stop: HydratedDeliveryStop;
  isStopToAssign?: boolean;
  eta?: string;
  waitTime?: number;
  grabCursor?: boolean;
  currentScheduleRiderId: string;
}> = ({
  stop,
  isStopToAssign,
  eta,
  waitTime,
  grabCursor,
  currentScheduleRiderId,
}) => {
  const orderColor = isStopToAssign
    ? brandPrimaryColor
    : generateColorFromUUID(stop.orderId);

  const stopTypeColor = stop.type === 'PICK_UP' ? 'blue.600' : 'green.500';

  const cursor = useGrabCursor();

  const isComplete = Boolean(stop.completedAt);

  const isEtaPastWindow =
    eta && stop.windowEnd ? new Date(eta) > new Date(stop.windowEnd) : false;

  return (
    <Box mt={3}>
      <Box
        borderColor={stopTypeColor}
        borderWidth={2}
        borderRadius="lg"
        overflow={'hidden'}
        cursor={grabCursor ? cursor : undefined}
        position={'relative'}
        fontSize={'xs'}
      >
        {isComplete && (
          <Center
            bg={'blackAlpha.800'}
            position={'absolute'}
            top={0}
            left={0}
            h={'100%'}
            w={'100%'}
          >
            <Box textAlign={'center'}>
              <Text color={'white'}>Complete</Text>
              {stop.completedAt ? (
                <Text color={'white'}>
                  {formatDate(new Date(stop.completedAt))}
                </Text>
              ) : null}
            </Box>
          </Center>
        )}
        <Flex justifyContent="space-between">
          <HStack
            h={4}
            spacing={4}
            paddingX={3}
            backgroundColor={stopTypeColor}
            display="flex"
            alignItems={'center'}
            borderBottomRightRadius="md"
            color="white"
            fontSize={'xx-small'}
          >
            <Box>
              <Text>{stop.type === 'PICK_UP' ? 'Pick Up' : 'Drop Off'}</Text>
            </Box>
          </HStack>
          <Box
            backgroundColor={orderColor}
            px={3}
            borderBottomLeftRadius="md"
            width="fit-content"
            borderTopRightRadius={'md'}
            fontSize={'xx-small'}
          >
            {isStopToAssign ? (
              <HStack>
                <AiFillStar />
                <Text fontWeight={'bold'}>Assigning</Text>
              </HStack>
            ) : (
              <Text color={'white'}>
                Order {stop.orderId.substring(0, 5)}..
              </Text>
            )}
          </Box>
        </Flex>
        <HStack py={2} px={3} justifyContent={'space-between'}>
          <HStack>
            <Text>{stop.postCode}</Text>
          </HStack>
          <Text>·</Text>
          <HStack>
            <Text>
              {stop.deliveryItems?.[0]
                ? `${calculateTotalWeightKg(stop.deliveryItems)}kg`
                : 'Unknown Weight'}
            </Text>
          </HStack>
          <Text>·</Text>
          <Text>
            {stop.deliveryItems?.[0]
              ? getAllItemDimensionsString(stop.deliveryItems)
              : 'Unknown Dimensions'}
          </Text>
          <Text>·</Text>
          {stop.windowStart && stop.windowEnd ? (
            <Text>
              {formatTimeRange({
                from: new Date(stop.windowStart).toISOString(),
                to: new Date(stop.windowEnd).toISOString(),
              })}
            </Text>
          ) : null}
        </HStack>
        <Divider />
        <Box p={2}>
          <StopActions
            stop={stop}
            currentScheduleRiderId={currentScheduleRiderId}
          />
        </Box>
      </Box>
      {eta ? (
        <HStack
          fontSize={'xs'}
          justifyContent={'space-between'}
          px={2}
          pt={0.5}
        >
          <HStack spacing={1} color={isEtaPastWindow ? 'red.500' : 'green.500'}>
            <LuClock fontSize={12} />
            <Text> {new Date(eta).toLocaleTimeString()}</Text>
          </HStack>
          {waitTime ? (
            <Text>{secondsToDurationText(waitTime)} wait</Text>
          ) : null}
        </HStack>
      ) : (
        <Box h={4} />
      )}
    </Box>
  );
};
