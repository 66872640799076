import { useCompleteStop } from './useCompleteStop';
import { useUndoCompleteStop } from './useUndoCompleteStop';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';

export const useDeliveryStopCompletionToggle = (
  deliveryStop: HydratedDeliveryStop
) => {
  const completeStopMutation = useCompleteStop({
    orderId: deliveryStop.orderId,
    riderId: deliveryStop.delivery?.rider?.riderId || null,
  });

  const undoCompleteStopMutation = useUndoCompleteStop({
    orderId: deliveryStop.orderId,
    riderId: deliveryStop.delivery?.rider?.riderId || null,
  });

  const isCompleted = Boolean(deliveryStop.completedAt);
  const isLoading = completeStopMutation.isLoading || undoCompleteStopMutation.isLoading;

  const toggleCompletion = () => {
    if (isCompleted) {
      undoCompleteStopMutation.mutate(deliveryStop.deliveryStopId);
    } else {
      completeStopMutation.mutate(deliveryStop.deliveryStopId);
    }
  };

  return {
    isCompleted,
    isLoading,
    toggleCompletion
  };
}; 