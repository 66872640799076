import { z } from 'zod';
import { HydratedOrderSchema } from '../../../models';
import { processStringBoolean } from '../../../utils';

export const GetOrderByIdQuerySchema = z.object({
  includeProofMedia: z.preprocess(processStringBoolean, z.boolean()).optional(),
  withLastUsedVehicle: z.preprocess(processStringBoolean, z.boolean()).optional(),
});

export const GetOrderByIdRepositoryQuerySchema = GetOrderByIdQuerySchema.omit({
  includeProofMedia: true,
}).extend({
  apiClientId: z.string().optional(),
});

export const GetOrderByIdResponseSchema = HydratedOrderSchema;

export type GetOrderByIdResponse = z.infer<typeof GetOrderByIdResponseSchema>;
export type GetOrderByIdQuery = z.infer<typeof GetOrderByIdQuerySchema>;
export type GetOrderByIdRepositoryQuery = z.infer<
  typeof GetOrderByIdRepositoryQuerySchema
>;