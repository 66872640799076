import { RiderIdentity } from '@tradeaze-packages/schemas';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { RiderModal } from './RiderModal';

type RiderModalContextType = {
  openModalId: string | null;
  openModal: (
    rider?: RiderIdentity & { onDuty?: boolean | null },
    onClose?: () => void,
  ) => void;
  closeModal: () => void;
};

const RiderModalContext = createContext<RiderModalContextType | undefined>(
  undefined,
);

export const RiderModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [rider, setRider] = useState<
    (RiderIdentity & { onDuty?: boolean | null }) | null
  >(null);

  const [onCloseCallback, setOnCloseCallback] = useState<
    (() => void) | undefined
  >(undefined);

  const openModal = useCallback(
    (
      rider?: RiderIdentity & { onDuty?: boolean | null },
      onClose?: () => void,
    ) => {
      setRider(rider || null);
      setOnCloseCallback(onClose);
    },
    [setRider, setOnCloseCallback],
  );

  const closeModal = useCallback(() => {
    setRider(null);
    onCloseCallback?.();
    setOnCloseCallback(undefined);
  }, [onCloseCallback]);

  const value = useMemo(
    () => ({
      openModalId: rider?.riderId || null,
      openModal,
      closeModal,
    }),
    [rider?.riderId, openModal, closeModal],
  );

  return (
    <RiderModalContext.Provider value={value}>
      {children}
      {rider && (
        <RiderModal isOpen={!!rider} onClose={closeModal} rider={rider} />
      )}
    </RiderModalContext.Provider>
  );
};

export const useRiderModal = (riderId?: string) => {
  const context = useContext(RiderModalContext);
  if (context === undefined) {
    throw new Error('useRiderModal must be used within a RiderModalProvider');
  }

  return {
    isModalOpen: context.openModalId === riderId,
    openModal: context.openModal,
    closeModal: context.closeModal,
  };
};
