import { Box, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { SearchDropdown } from '../shared/SearchDropdown';
import { useFindAddresses } from './useFindAddresses';
import { useGetLookupAddress } from './useGetAddressById';
import { postcodeValidator } from '@tradeaze-packages/schemas';
import { GetLookupAddressResponse } from '@tradeaze-packages/schemas';

export const FindAddressesInput: React.FC<{
  onAddressSelected: (address: GetLookupAddressResponse) => void;
  isError?: boolean;
  cypressTestId?: string;
}> = ({ onAddressSelected, isError, cypressTestId }) => {
  const [query, setQuery] = useState('');
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const isValidPostcode = useMemo(() => {
    return postcodeValidator.isValid(query);
  }, [query]);

  const findAddressesQuery = useFindAddresses(query, {
    enabled: isValidPostcode,
  });

  useGetLookupAddress(selectedId ?? '', {
    enabled: Boolean(selectedId),
    onSuccess: (address) => {
      if (address) {
        onAddressSelected(address);
      }
    },
  });

  const noResults =
    isValidPostcode && !findAddressesQuery.isFetching && !findAddressesQuery.data?.suggestions.length;

  const handleSearchChange = (newValue: string) => {
    setQuery(newValue ?? '');
    setSelectedId(null);
  };

  const handleSelect = (id: string) => {
    setSelectedId(id);
  };

  const addressResults = useMemo(
    () =>
      findAddressesQuery.data?.suggestions.map((suggestion: { address: string; id: string }) => ({
        label: suggestion.address,
        value: suggestion.id,
      })),
    [findAddressesQuery.data],
  );

  return (
    <Box>
      <SearchDropdown
        value={query}
        inputPlaceholder="Search a postcode"
        isLoading={findAddressesQuery.isFetching}
        onChange={handleSearchChange}
        onSelect={handleSelect}
        results={addressResults}
        disableSearch={!isValidPostcode}
        isError={isError}
        data-cy={`${cypressTestId}-postcode-dropdown-input`}
      />
      {noResults ? (
        <Text fontSize={14} mt={1} color={'red.500'}>
          No results found
        </Text>
      ) : null}
      {!isValidPostcode && query.length > 0 ? (
        <Text data-cy={`${cypressTestId}-invalid-postcode-error`} fontSize={14} mt={1} color={'red.500'}>
          {postcodeValidator.errorMessages.invalid}
        </Text>
      ) : null}
      {isError ? (
        <Text
          fontSize={14}
          mt={1}
          color={'red.500'}
          data-cy={`${cypressTestId}-postcode-error`}
        >
          {postcodeValidator.errorMessages.required}
        </Text>
      ) : null}
    </Box>
  );
};
