import { Button, Icon } from '@chakra-ui/react';
import { ButtonWithConfirmation } from '../shared';
import { FaTrash } from 'react-icons/fa';

interface DeleteButtonProps {
  title: string;
  description: string;
  onConfirm: () => void;
  isLoading?: boolean;
  dataCy: string;
  buttonProps?: React.ComponentProps<typeof Button>;
}

export const DeleteButton = ({ title, description, onConfirm, isLoading, dataCy, buttonProps }: DeleteButtonProps) => {
  return (
    <ButtonWithConfirmation
      alertTitle={title}
      alertDescription={description}
      onConfirm={onConfirm}
      colorScheme="gray"
      confirmButtonText="Delete"
      confirmButtonColorScheme="red"
      size="sm"
      variant="ghost"
      isLoading={isLoading}
      data-cy={dataCy}
      padding={2}
      color="gray.400"
      bg="gray.50"
      _hover={{ color: 'red.500', bg: 'gray.100' }}
      {...buttonProps}
    >
      <Icon as={FaTrash} boxSize={3.5} />
    </ButtonWithConfirmation>
  );
}; 