import { Flex, Heading, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { brandPrimaryColor } from '../theme';
import { BaseCard } from './BaseCard';

interface FeatureCardProps {
  title: string;
  subtitle: string;
  price: number;
  description: string;
  icon: ReactNode;
  selected?: boolean;
  onClick?: () => void;
  error?: boolean;
}

export function FeatureCard({
  title,
  subtitle,
  price,
  description,
  icon,
  selected,
  onClick,
  error,
}: FeatureCardProps) {
  return (
    <BaseCard
      selected={selected}
      onClick={onClick}
      primaryColor={brandPrimaryColor}
      error={error}
      animation={true}
      p={4}
    >
      <Flex direction="column" gap={6}>
        <Flex
          justify="center"
          color={selected ? brandPrimaryColor : 'gray.500'}
          fontSize="2xl"
          transition="color 0.2s"
        >
          {icon}
        </Flex>
        <Flex direction="column" align="center" gap={3}>
          <Heading size="md" color="gray.800" textAlign="center" fontWeight="600">
            {title}
          </Heading>
          {subtitle && <Text fontSize="sm" color="gray.500">{subtitle}</Text>}
          <Text
            fontSize="xl"
            fontWeight="600"
            color={selected ? brandPrimaryColor : 'gray.600'}
            transition="color 0.2s"
          >
            £{price}/day
          </Text>
          <Text color="gray.500" fontSize="sm" textAlign="center" maxW="90%">
            {description}
          </Text>
        </Flex>
      </Flex>
    </BaseCard>
  );
}
