import { SelectDropdown } from '../../../shared';
import { Address } from '@tradeaze-packages/schemas';
import { DeleteButton } from '../../../merchant/DeleteButton';

type SavedAddressSectionProps = {
  handleSelectExistingAddress: (address: Address) => void;
  handleDeleteAddress: (addressId: string) => void;
  isLoadingDelete?: boolean;
  savedAddressPlaceholder: string;
  isLoadingSavedAddresses: boolean;
  savedAddresses:
    | {
        address: Address;
        displayName: string;
      }[]
    | undefined;
  disabled?: boolean;
};

export const SavedAddressSection = ({
  savedAddresses,
  savedAddressPlaceholder,
  isLoadingSavedAddresses,
  handleSelectExistingAddress,
  handleDeleteAddress,
  isLoadingDelete,
  disabled,
}: SavedAddressSectionProps) => {
  return (
    <SelectDropdown
      isLoading={isLoadingSavedAddresses}
      onSelect={handleSelectExistingAddress}
      results={savedAddresses?.map((a) => ({
        label: a.displayName,
        value: a.address,
        button: (
          <DeleteButton
            title="Delete Address"
            description="Are you sure you want to delete this address?"
            onConfirm={() => handleDeleteAddress(a.address.addressId)}
            isLoading={isLoadingDelete}
            dataCy="delete-address"
          />
        ),
      }))}
      placeholder={savedAddressPlaceholder}
      showClear
      isMultiSelect={false}
      label={'Saved Address'}
      disabled={disabled}
    />
  );
};
