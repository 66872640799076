import { Box, Button } from '@chakra-ui/react';
import { ItemDetailsSection, PickupSection } from '../../order-form-sections';
import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { UseFormReturn } from 'react-hook-form';
import { getMerchantConfigKey } from '../../../merchant/config/merchantConfig';

type MultiCollectionAddPickupProps = {
  merchantAccount?: MerchantAccount;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  isPickupComplete: boolean;
  isItemDetailsComplete: boolean;
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  handleAddPickup: () => void;
  buttonLabel: string;
  pickupCount: number;
  showExpanded: boolean;
  restrictedEditing: boolean;
};

export const MultiCollectionAddPickup = ({
  merchantAccount,
  addPickupMarker,
  removePickupMarker,
  isPickupComplete,
  isItemDetailsComplete,
  pickupForm,
  itemForm,
  handleAddPickup,
  buttonLabel,
  pickupCount,
  showExpanded,
  restrictedEditing,
}: MultiCollectionAddPickupProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={8}>
      <PickupSection
        sectionName={`Pick Up ${pickupCount}`}
        defaultPickupAddress={merchantAccount?.address}
        defaultPickupPostcode={merchantAccount?.postCode}
        defaultPickupPosition={merchantAccount?.position}
        defaultPickupCity={merchantAccount?.city}
        merchantAccount={merchantAccount}
        showExpanded={showExpanded}
        form={pickupForm}
        isSectionComplete={isPickupComplete}
        addMarker={addPickupMarker}
        removeMarker={removePickupMarker}
        isSecondPickup={pickupCount > 1}
        restrictedEditing={restrictedEditing}
        cypressTestId={'pickup-section'}
        stopId={pickupCount}
        merchantConfigKey={merchantAccount && getMerchantConfigKey(merchantAccount)}
      />
      <ItemDetailsSection
        sectionName={'Item Details - Pick Up ' + pickupCount}
        isSectionComplete={isItemDetailsComplete}
        form={itemForm}
        loadLabel={'Load Estimate for Pick Up ' + pickupCount}
        hideSection={restrictedEditing}
      />
      <Button onClick={handleAddPickup} data-cy={'add-pickup-button'}>
        {buttonLabel}
      </Button>
    </Box>
  );
};
