import { z } from 'zod';
import { ManVanRequestBodySchema } from '@tradeaze-packages/schemas';

export const ManVanTermsSchema = z.enum([
  'WITHIN_M25',
  'OUTSIDE_M25',
], {
  errorMap: () => ({
    message: 'You must agree to the terms to continue',
  }),
});

export type ManVanTerms = z.infer<typeof ManVanTermsSchema>;

export const ManVanFormSchema = ManVanRequestBodySchema
  .omit({ merchantName: true })
  .extend({
    termsAccepted: ManVanTermsSchema,
  });

export type ManVanFormData = z.infer<typeof ManVanFormSchema>; 