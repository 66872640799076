import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  SiteContact,
  MerchantDefinedSiteContact,
  MerchantDefinedSiteContactSchema,
} from '@tradeaze-packages/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSection } from '../FormSection';
import { useCreateSiteContact } from '@tradeaze/frontend/hooks';
import { toast } from 'react-hot-toast';
import { PhoneIcon } from '@chakra-ui/icons';
import parsePhoneNumberFromString from 'libphonenumber-js';

export interface SiteContactFormProps {
  siteContact?: SiteContact;
  companyId: string;
  onSubmit: () => void;
}

export const SiteContactForm = ({
  siteContact,
  companyId,
  onSubmit,
}: SiteContactFormProps) => {
  const methods = useForm<MerchantDefinedSiteContact>({
    defaultValues: {
      companyId,
      ...siteContact,
    },
    resolver: zodResolver(MerchantDefinedSiteContactSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = methods;

  const { mutate: createMerchantSiteContact, isLoading } = useCreateSiteContact(
    {
      invalidateQueryKeys: [['getSiteContacts', companyId]],
      onSuccess: () => {
        onSubmit();
      },
      onError: () => {
        toast.error('Error creating site contact');
      },
    }
  );

  const handleFormSubmit = handleSubmit(
    (data) => {
      createMerchantSiteContact(data);
    },
    () => {
      toast.error('Invalid form');
    }
  );

  const onChangePhoneNumber = (value: string) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(value, 'GB');

    if (!parsedPhoneNumber?.isValid()) {
      return;
    }

    const contactNumber = parsedPhoneNumber.formatInternational();
    
    setValue('contactNumber', contactNumber);
    trigger('contactNumber');
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <Stack spacing={4}>
        <FormSection name="Site Contact" icon={<PhoneIcon />}>
          <Stack spacing={4}>
            <FormControl isInvalid={Boolean(errors.contactName)}>
              <Input
                id="contactName"
                placeholder="Name"
                {...register('contactName')}
              />
              <FormErrorMessage data-cy="contact-name-error">
                {errors.contactName?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.contactNumber)}>
              <Input
                id="contactNumber"
                type={'tel'}
                placeholder="Phone"
                {...register('contactNumber', {
                  onChange(event) {
                    onChangePhoneNumber(event.target.value);
                  },
                })}
              />
              <FormErrorMessage data-cy="contact-number-error">
                {errors.contactNumber?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </FormSection>
        <Button
          isLoading={isLoading}
          isDisabled={!isValid}
          onClick={handleFormSubmit}
          data-cy="save-site-contact-button"
        >
          Save
        </Button>
      </Stack>
    </form>
  );
};