import { z } from 'zod';
import { RiderBankDetailsSchema } from '../../../models';

export const BankDetailsFieldValidationStatusSchema = z.enum([
  'MISSING',
  'INVALID',
]);
export type BankDetailsFieldValidationStatus = z.infer<
  typeof BankDetailsFieldValidationStatusSchema
>;

export const BankDetailsFieldValidationSchema = z.object({
  nameOnAccount: BankDetailsFieldValidationStatusSchema.optional(),
  accountNumber: BankDetailsFieldValidationStatusSchema.optional(),
  sortCode: BankDetailsFieldValidationStatusSchema.optional(),
  vatNumber: BankDetailsFieldValidationStatusSchema.optional(),
  nino: BankDetailsFieldValidationStatusSchema.optional(),
});

export type BankDetailsFieldValidation = z.infer<
  typeof BankDetailsFieldValidationSchema
>;

// @deprecated This schema is no longer used and will be removed in a future release
export const GetRiderBankDetailsResponseSchema = RiderBankDetailsSchema.omit({
  id: true,
})
  .extend({
    validation: BankDetailsFieldValidationSchema.optional(),
  })
  .nullable();

// @deprecated This type is no longer used and will be removed in a future release
export type GetRiderBankDetailsResponse = z.infer<
  typeof GetRiderBankDetailsResponseSchema
>;

export const GetRiderBankDetailsV2ResponseSchema = z.object({
  bankDetails: RiderBankDetailsSchema.omit({
    id: true,
  })
    .extend({
      validation: BankDetailsFieldValidationSchema.optional(),
    })
    .nullable(),
});

export type GetRiderBankDetailsV2Response = z.infer<
  typeof GetRiderBankDetailsV2ResponseSchema
>;
