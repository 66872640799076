import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MERCHANT_APP_VERSION } from '@tradeaze/frontend/versioning';
import { SentryErrorFallback } from '@tradeaze/frontend/ui-web';
import { createSentryConfig } from '@tradeaze/frontend/utils';
import { Environment } from '@tradeaze-packages/schemas';

posthog.init(import.meta.env['VITE_MERCHANT_POSTHOG_API_KEY'], {
  api_host: import.meta.env['VITE_POSTHOG_HOST'],
  mask_all_text: false,
});

Sentry.init(
  createSentryConfig({
    appName: 'merchant-app',
    version: MERCHANT_APP_VERSION,
    posthog,
    environment: import.meta.env['VITE_ENV_NAME'] as Environment,
    dsn: import.meta.env['VITE_MERCHANT_SENTRY_DSN'],
    sentryOrganization: import.meta.env['VITE_SENTRY_ORGANIZATION'],
    sentryProjectId: import.meta.env['VITE_MERCHANT_SENTRY_PROJECT_ID'],
  }),
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Sentry.ErrorBoundary fallback={<SentryErrorFallback />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  </React.StrictMode>,
);
