import { z } from 'zod';
import { PublicDeliverySchema } from '../models/public-access-schema';
import { WebhookEventTypeSchema } from './event-types';

/**
 * Webhook Data
 * @description
 * This is the data that is sent to the webhook url
 */
const OrderActionedWebhookDataSchema = z.object({
  apiClientId: z.string(),
  publicDelivery: PublicDeliverySchema,
});

const DeliveryStopActionedWebhookDataSchema = z.object({
  apiClientId: z.string(),
  publicDelivery: PublicDeliverySchema,
});

const OrderActionedBodyDataSchema = PublicDeliverySchema;

const DeliveryStopActionedBodyDataSchema = PublicDeliverySchema;

export const OrderCreatedWebhookPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionedBodyDataSchema,
});

export const OrderConfirmedWebhookPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionedBodyDataSchema,
});

export const OrderCancelledPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionedBodyDataSchema,
});

export const OrderRejectedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionedBodyDataSchema,
});

export const OrderUpdatedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionedBodyDataSchema,
});

export const PickupEnroutePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_ENROUTE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const PickupArrivedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_ARRIVED),
  data: DeliveryStopActionedBodyDataSchema,
});

export const PickupCompletePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_COMPLETE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffEnroutePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_ENROUTE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffArrivedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_ARRIVED),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffCompletePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_COMPLETE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const WebhookPostBodySchema = z.discriminatedUnion('type', [
  OrderCreatedWebhookPostBodySchema,
  OrderConfirmedWebhookPostBodySchema,
  OrderCancelledPostBodySchema,
  OrderRejectedPostBodySchema,
  OrderUpdatedPostBodySchema,
  PickupEnroutePostBodySchema,
  PickupArrivedPostBodySchema,
  PickupCompletePostBodySchema,
  DropoffEnroutePostBodySchema,
  DropoffArrivedPostBodySchema,
  DropoffCompletePostBodySchema,
]);

// Export webhook types
export type OrderActionedWebhookData = z.infer<typeof OrderActionedWebhookDataSchema>;
export type DeliveryStopActionedWebhookData = z.infer<typeof DeliveryStopActionedWebhookDataSchema>;
export type WebhookPostBody = z.infer<typeof WebhookPostBodySchema>; 