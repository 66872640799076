import { z } from 'zod';
import { ValidationResult, Validator } from './Validator';
import _ from 'lodash';
import { InputMode, removeDisallowedChars, removeSeparators } from './stringUtils';

/**
 * String Validation Utility
 *
 * A factory for creating string validators with consistent patterns for:
 * - Regex-based validation
 * - Error message handling
 * - String formatting
 * - Zod schema creation
 */

//-----------------------------------------------------------------------------
// Types
//-----------------------------------------------------------------------------

type ErrorMessages = {
  invalid?: string;
  required?: string;
  invalidType?: string;
};

export type StringValidator = Validator<string, ErrorMessages>;

type TransformType = 'uppercase' | 'lowercase';

type FormatOptions = {
  removeSeparators?: boolean;
  transform?: TransformType;
  postFormat?: (value: string) => string;
};

type InputOptions = {
  inputMode?: InputMode;
  maxLength?: number;
  separator?: string;
};

type StringValidatorOptions = {
  utilityName: string;
  regex: RegExp;
  errorMessages?: ErrorMessages;
  inputOptions?: InputOptions;
  formatOptions?: FormatOptions;
};

//-----------------------------------------------------------------------------
// Helper Functions
//-----------------------------------------------------------------------------

const makeErrorMessages = (
  utilityName: string,
  customErrorMessages: ErrorMessages = {},
): Required<ErrorMessages> => ({
  invalid: `Invalid ${utilityName}`,
  required: `${utilityName} is required`,
  invalidType: `${utilityName} must be a string`,
  ...customErrorMessages,
});

//-----------------------------------------------------------------------------
// Main Factory Function
//-----------------------------------------------------------------------------

/**
 * Creates a string validation utility with consistent validation patterns
 *
 * @param options - Configuration options
 * @returns Object implementing the Validator interface
 */
export function createStringValidator(
  options: StringValidatorOptions,
): StringValidator {
  const { utilityName, regex, formatOptions, inputOptions } = options;
  const errorMessages = makeErrorMessages(utilityName, options.errorMessages);

  const isValid = (value: string): boolean => regex.test(value);

  const validate = (value: string): string | null => {
    if (!value) return errorMessages.required;
    if (!isValid(value)) return errorMessages.invalid;
    return null;
  };

  const makeSchema = (customErrors?: ErrorMessages) => {
    const schemaErrors = { ...errorMessages, ...customErrors };

    return z
      .string({
        required_error: schemaErrors.required,
        invalid_type_error: schemaErrors.invalidType,
      })
      .min(1, schemaErrors.required)
      .refine(isValid, {
        message: schemaErrors.invalid,
      });
  };

  const normalise = (value: string): string => {
    let result = value;

    if (inputOptions) {
      result = removeDisallowedChars(result, {
        inputMode: inputOptions.inputMode,
        maxLength: inputOptions.maxLength,
      });
    }

    if (formatOptions?.removeSeparators) {
      result = removeSeparators(result, inputOptions?.separator);
    }

    if (formatOptions?.transform === 'uppercase') {
      result = result.toUpperCase();
    } else if (formatOptions?.transform === 'lowercase') {
      result = result.toLowerCase();
    }

    if (formatOptions?.postFormat) {
      result = formatOptions.postFormat(result);
    }

    return result;
  };

  const validateAndNormalise = (value: string): ValidationResult<string> => {
    const errorMessage = validate(value);
    if (errorMessage) {
      return {
        success: false,
        error: errorMessage,
      };
    }
    return { success: true, value: normalise(value) };
  };

  const normaliseChange = (value: string, prevValue: string): string => {
    if (value.length > prevValue.length) {
      // Only normalise if chars have been added (ie, don't normalise on backspace)
      return normalise(value);
    } else {
      // On remove chars (ie, backspace), trim separators from the start & end of the string
      return _.trim(value, inputOptions?.separator);
    }
  };

  const compress = (value: string): string => {
    const result = normalise(value);
    return removeSeparators(result, inputOptions?.separator);
  };

  return {
    isValid,
    validate,
    makeSchema,
    validateAndNormalise,
    normalise,
    normaliseChange,
    compress,
    errorMessages,
    formatOptions: {
      maxLength: inputOptions?.maxLength,
      transform: formatOptions?.transform,
      inputMode: inputOptions?.inputMode,
    },
  };
}
