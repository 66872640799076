import { createStringValidator } from './validationFactory';

const POSTCODE_FORMAT_REGEX = /^\s*[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}\s*$/i;
const POSTCODE_SPACING_REGEX = /^(.+?)(\d[A-Z]{2})$/;

/**
 * Validator for UK postcodes
 *
 * @see [UK Postcode Format](https://ideal-postcodes.co.uk/guides/uk-postcode-format)
 */
export const postcodeValidator = createStringValidator({
  utilityName: 'Postcode',
  regex: POSTCODE_FORMAT_REGEX,
  inputOptions: {
    inputMode: 'alphanumeric',
    maxLength: 8,
  },
  formatOptions: {
    removeSeparators: true,
    transform: 'uppercase',
    postFormat: (value: string) =>
      value.replace(POSTCODE_SPACING_REGEX, '$1 $2'),
  },
});
