import {
  ClientDeliveryOption,
  DeliveryOptionId,
  DeliveryOptionTypeIdSchema,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';

const getOptionType = (
  vehicleId: DeliveryVehicleId,
  optionId: DeliveryOptionId,
) => optionId.replace(vehicleId, '').replace(/^_/, '');

const findOptionByType = (
  options: ClientDeliveryOption[],
  optionType: string,
) => {
  return (
    options.find(
      (option) =>
        getOptionType(option.deliveryVehicleId, option.deliveryOptionId) ===
        optionType,
    )?.deliveryOptionId ?? null
  );
};

export function getNextOptionOnVehicleChange({
  currentVehicle,
  currentOption,
  newVehicle,
  availableDeliveryOptions: availableOptions,
}: {
  currentVehicle: DeliveryVehicleId;
  currentOption: DeliveryOptionId | null;
  newVehicle: DeliveryVehicleId;
  availableDeliveryOptions: ClientDeliveryOption[];
}): DeliveryOptionId | null {
  const newVehicleOptions = availableOptions.filter(
    (option) => option.deliveryVehicleId === newVehicle,
  );

  // First try to find an option of the same type
  const sameOption =
    currentOption &&
    findOptionByType(
      newVehicleOptions,
      getOptionType(currentVehicle, currentOption),
    );

  if (sameOption) {
    return sameOption;
  }

  // Next, default to DAY option if available
  const dayOption = findOptionByType(
    newVehicleOptions,
    DeliveryOptionTypeIdSchema.enum.DAY,
  );

  if (dayOption) {
    return dayOption;
  }

  // Otherwise, default to first option for the vehicle
  const firstOption = newVehicleOptions[0]?.deliveryOptionId;

  if (firstOption) {
    return firstOption;
  }

  // Otherwise, return null
  return null;
}
