import { SmallCloseIcon } from '@chakra-ui/icons';
import { Tag, TagLabel, ThemingProps } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { TradeazeLogoNoText } from '../brand';

export const RiderDutyTag: React.FC<{
  onDuty?: boolean | null;
  size?: ThemingProps<'Tag'>['size'];
}> = ({ onDuty = false, size }) => {
  const { colorScheme, bg, name, icon } = useMemo(
    () =>
      onDuty
        ? {
            colorScheme: 'yellow',
            bg: 'yellow.400',
            name: 'On Duty',
            icon: <TradeazeLogoNoText height={12} />,
          }
        : {
            colorScheme: 'red',
            name: 'Off Duty',
            icon: <SmallCloseIcon />,
          },
    [onDuty],
  );

  return (
    <Tag
      size={size}
      colorScheme={colorScheme}
      bg={bg}
      alignItems="center"
      width={'fit-content'}
    >
      <TagLabel>{name}</TagLabel>
    </Tag>
  );
};
