import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { RiderIdentity } from '@tradeaze-packages/schemas';
import { RiderAvatar, RiderDutyTag } from '../../../rider';
import { PhoneNumber, WorkTypeTag } from '../../../shared';

const getClockedInTime = (lastClockedInAt: string, onDuty?: boolean | null) => {
  if (!lastClockedInAt) return null;

  if (onDuty) {
    return `Clocked-in at: ${new Date(lastClockedInAt).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  return `Last clocked in: ${new Date(lastClockedInAt).toLocaleDateString([], {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })}`;
};

export const DeliveryScheduleHeader = ({
  rider,
  extraButtons,
}: {
  rider: RiderIdentity & {
    onDuty?: boolean | null;
    lastClockedInAt?: string | null;
  };
  extraButtons?: React.ReactNode;
}) => {
  return (
    <HStack bg="gray.50" p={4} spacing={4}>
      <RiderAvatar
        firstName={rider.firstName}
        lastName={rider.lastName}
        profilePicKey={rider?.avatarUrl}
        size="md"
        showProfilePic={true}
      />
      <VStack align="start" spacing={1}>
        <Heading size="md">
          {rider.firstName} {rider.lastName}
        </Heading>
        <HStack mb={1} spacing={4} wrap="wrap" rowGap={1}>
          <HStack spacing={2}>
            <WorkTypeTag workType={rider.workType} />
            <RiderDutyTag onDuty={rider.onDuty} size="sm" />
          </HStack>
          {rider.contactNumber && <PhoneNumber phone={rider.contactNumber} />}
          {rider.lastClockedInAt && (
            <Text fontSize="sm" color="gray.600" mb={0}>
              {getClockedInTime(rider.lastClockedInAt, rider.onDuty)}
            </Text>
          )}
        </HStack>
        {extraButtons}
      </VStack>
    </HStack>
  );
};
