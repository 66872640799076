import { Box, BoxProps, Icon, ThemeTypings } from '@chakra-ui/react';
import {
  ExternalCourier,
  RiderIdentity,
  WorkType,
} from '@tradeaze-packages/schemas';
import { differenceInMinutes } from 'date-fns';
import React from 'react';
import { FaHourglassHalf } from 'react-icons/fa';
import { DeliveryVehicleIcon } from '../delivery/delivery-vehicle/DeliveryVehicleIcon';
import { RiderAvatar } from '../rider/RiderAvatar';
import { MarkerContainer } from './MarkerContainer';
import { useRiderModal } from './RiderModalProvider';
import { RiderPopupContent } from './RiderPopupContent';

const useRiderMarkerColors = () => {
  const badgeColors: Record<WorkType, ThemeTypings['colors']> = {
    SHIFT: 'yellow.400',
    GIG: 'cyan.500',
    EXTERNAL: 'purple.500',
    UNASSIGNED: 'gray.400',
  };

  return {
    idleColors: {
      short: 'blackAlpha.500',
      medium: 'orange.300',
      long: 'red.400',
    },
    badgeColors,
  };
};

const useInactiveTime = (updatedAt?: string) => {
  const colors = useRiderMarkerColors();

  const inactiveTime = updatedAt
    ? differenceInMinutes(new Date(), new Date(updatedAt))
    : 0;

  const isInactive = inactiveTime > 15;

  let inactiveColor;

  if (inactiveTime < 5) {
    inactiveColor = colors.idleColors.short;
  } else if (inactiveTime < 15) {
    inactiveColor = colors.idleColors.medium;
  } else {
    inactiveColor = colors.idleColors.long;
  }

  return { inactiveColor, isInactive };
};

export type RiderMarkerMode = 'highlighted' | 'normal' | 'faded';

type RiderMarkerProps = (
  | {
      rider: RiderIdentity & { onDuty?: boolean | null };
      externalCourier?: never;
    }
  | {
      rider?: never;
      externalCourier: ExternalCourier;
    }
) & {
  updatedAt?: string;
  alwaysShowDetails?: boolean;
  showProfilePic?: boolean;
  isAdmin: boolean;
  isPopupOpen?: boolean;
  onOpenPopup?: () => void;
  onClosePopup?: () => void;
  mode?: RiderMarkerMode;
  isHovered?: boolean;
};

const MiniWorkTypeTag: React.FC<{ workType: WorkType } & BoxProps> = ({
  workType,
  ...props
}) => {
  const colors = useRiderMarkerColors();

  const workTypeLetter = workType.charAt(0).toUpperCase();

  return (
    <Box
      boxSize={3}
      borderRadius="full"
      boxShadow="0 0 4px 2px rgba(0,0,0,0.30)"
      bg={colors.badgeColors[workType]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize="9px"
      {...props}
    >
      {workTypeLetter}
    </Box>
  );
};

export const RiderMarker: React.FC<RiderMarkerProps> = ({
  rider,
  externalCourier,
  updatedAt,
  alwaysShowDetails = false,
  showProfilePic = false,
  isAdmin,
  isPopupOpen,
  onOpenPopup,
  onClosePopup,
  isHovered,
  mode = 'normal',
}) => {
  const { isModalOpen, openModal } = useRiderModal(rider?.riderId);

  const { inactiveColor, isInactive } = useInactiveTime(updatedAt);

  const firstName = rider?.firstName || externalCourier?.firstName;
  const lastName = rider?.lastName || externalCourier?.lastName;
  const workType = rider?.workType || 'EXTERNAL';

  const showPopup = isPopupOpen || alwaysShowDetails;
  const allowModal = isAdmin && !alwaysShowDetails && showPopup;

  const handleOpenModal = () => {
    if (allowModal) {
      openModal(rider, onClosePopup);
    }
  };

  const fadedStyle = {
    color: 'gray.900',
    bgColor: 'gray.400',
  };

  const highlightedStyle = {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
    transform: 'scale(1.1)',
  };

  const showFadedStyle = mode === 'faded' && !isHovered;

  return (
    <MarkerContainer
      mode={mode}
      onHover={onOpenPopup}
      onHoverLeave={() => !isModalOpen && onClosePopup?.()}
      isHovered={isHovered}
      onClick={handleOpenModal}
      popupContent={
        <RiderPopupContent
          rider={rider}
          externalCourier={externalCourier}
          updatedAt={updatedAt}
          isAdmin={isAdmin}
        />
      }
      showPopup={showPopup}
      tooltipIcon={
        rider?.deliveryVehicle && (
          <DeliveryVehicleIcon
            fontSize={14}
            deliveryVehicle={rider.deliveryVehicle}
          />
        )
      }
      tooltipText="Driver"
      showTooltip={mode === 'highlighted'}
    >
      <Box position="relative">
        <RiderAvatar
          firstName={firstName}
          lastName={lastName}
          size={'xs'}
          profilePicKey={rider?.avatarUrl}
          showProfilePic={showProfilePic}
          {...(showFadedStyle && fadedStyle)}
          {...(mode === 'highlighted' && highlightedStyle)}
        />
        {isAdmin && (
          <MiniWorkTypeTag
            workType={workType}
            position="absolute"
            bottom={-1}
            {...(showFadedStyle && fadedStyle)}
            right={-1.5}
          />
        )}
        {isAdmin && isInactive && (
          <Icon
            as={FaHourglassHalf}
            position={'absolute'}
            top={-2}
            right={-2}
            fontSize={12}
            color={showFadedStyle ? fadedStyle.bgColor : inactiveColor}
          />
        )}
      </Box>
    </MarkerContainer>
  );
};
