import { Tooltip } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { FaPhone } from 'react-icons/fa';
import { IconText } from './IconText';

export const PhoneNumber = ({ phone }: { phone: string }) => {
  const handleNumberClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(phone);
    toast.success('Copied to clipboard');
  };

  return (
    <Tooltip label="Click to copy" placement="top">
      <IconText
        icon={FaPhone}
        text={phone}
        fontSize="xs"
        onClick={handleNumberClick}
        textDecoration="underline"
        textDecorationStyle="dotted"
      />
    </Tooltip>
  );
};
