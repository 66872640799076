import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  DeliveryScheduleWithStops,
  FleetVehicle,
  HydratedDelivery,
  RiderIdentity,
} from '@tradeaze-packages/schemas';
import { DeliveryScheduleControls } from './DeliveryScheduleControls';
import { DeliveryScheduleHeader } from './DeliveryScheduleHeader';
import { DeliveryScheduleInfo } from './DeliveryScheduleInfo';
import { DeliveryScheduleList } from './DeliveryScheduleList';
import { useDeliverySchedule } from './useDeliverySchedule';

type DeliveryScheduleProps = {
  rider: RiderIdentity & {
    onDuty?: boolean | null;
    lastClockedInAt?: string | null;
  };
  fleetVehicle?: FleetVehicle;
  schedule?: DeliveryScheduleWithStops;
  isLoadingSave: boolean;
  isLoadingSchedule: boolean;
  deliveryToAssign?: HydratedDelivery | null;
  suggestedStopOrder?: string[];
  isSuggestedRider?: boolean;
  handleSaveStopOrder?: (stopIds: string[]) => void;
  handleGoBack?: () => void;
  handleChangeMarkerIndices: (stopIds: string[]) => void;
  handleScheduleAssign?: (riderId: string, stopsOrder: string[]) => void;
  extraButtons?: React.ReactNode;
};

export const DeliverySchedule = ({
  rider,
  fleetVehicle,
  schedule,
  isLoadingSchedule,
  isLoadingSave,
  deliveryToAssign,
  suggestedStopOrder,
  isSuggestedRider,
  handleSaveStopOrder,
  handleGoBack,
  handleChangeMarkerIndices,
  handleScheduleAssign,
  extraButtons,
}: DeliveryScheduleProps) => {
  const {
    hasStops,
    totalTimeQuery,
    orderedStops,
    handleOptimiseSchedule,
    optimiseMutation,
    canSave,
    handleSave,
    handleCancelChanges,
    isDropOffBeforePickup,
    handleReorder,
    stopsToAssign,
  } = useDeliverySchedule({
    riderId: rider.riderId,
    schedule,
    deliveryToAssign,
    suggestedStopOrder,
    isSuggestedRider,
    handleSaveStopOrder,
    handleChangeMarkerIndices,
    handleScheduleAssign,
  });

  return (
    <VStack spacing={2} alignItems={'stretch'}>
      {handleGoBack && (
        <Box>
          <Button
            size="sm"
            variant="ghost"
            leftIcon={<ArrowBackIcon />}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Box>
      )}
      <Box borderRadius="lg" borderWidth="1px" overflow="hidden">
        <DeliveryScheduleHeader rider={rider} extraButtons={extraButtons} />
        <VStack spacing={4} align="stretch" py={4}>
          {fleetVehicle && (
            <>
              <Box px={4}>
                <DeliveryScheduleInfo
                  fleetVehicle={fleetVehicle}
                  totalTimeQuery={totalTimeQuery}
                  orderedStops={orderedStops}
                  isLoadingSchedule={isLoadingSchedule}
                />
              </Box>
              <Divider />
            </>
          )}
          {!isLoadingSchedule ? (
            <VStack px={4} spacing={4} align="stretch">
              {!hasStops && <Text>No scheduled stops</Text>}
              {hasStops && (
                <DeliveryScheduleControls
                  handleOptimiseSchedule={handleOptimiseSchedule}
                  optimiseMutation={optimiseMutation}
                  canSave={canSave}
                  handleSave={handleSave}
                  handleCancelChanges={handleCancelChanges}
                  isLoadingSave={isLoadingSave}
                />
              )}
              {isDropOffBeforePickup && (
                <Alert status="error" borderRadius="lg">
                  <AlertIcon />
                  Pick ups must be before drop offs for the same delivery
                </Alert>
              )}
              <DeliveryScheduleList
                riderId={rider.riderId}
                hasStops={hasStops}
                totalTimeQuery={totalTimeQuery}
                orderedStops={orderedStops}
                handleReorder={handleReorder}
                stopsToAssign={stopsToAssign}
              />
            </VStack>
          ) : (
            <Center minH="300px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          )}
        </VStack>
      </Box>
    </VStack>
  );
};
