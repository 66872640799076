import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import type { ComponentDefaultProps } from '@chakra-ui/theme';
import { Position } from '@tradeaze-packages/schemas';
import React from 'react';
import { FaRoute } from 'react-icons/fa';

export const MapPointSelectMenu: React.FC<{
  coordinates?: Position[];
  enabled?: boolean;
  handleCancel: () => void;
  handleToggleEnabled: () => void;
  handleGoToGoogleMaps: () => void;
  size?: ComponentDefaultProps['size'];
}> = ({
  coordinates,
  enabled,
  handleCancel,
  handleToggleEnabled,
  handleGoToGoogleMaps,
  size = 'md',
}) => {
  if (!enabled) {
    return (
      <Button onClick={handleToggleEnabled} size={size}>
        <HStack>
          <FaRoute />
          <Text>Select Points</Text>
        </HStack>
      </Button>
    );
  }

  return (
    <Menu>
      <MenuButton as={Button} colorScheme={'green'} size={size}>
        {coordinates?.length || 0} points selected
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleGoToGoogleMaps}>Google Route</MenuItem>
        <MenuItem onClick={handleCancel}>Cancel</MenuItem>
      </MenuList>
    </Menu>
  );
};
