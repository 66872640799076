import { z } from 'zod';
import { WebhookConfigSchema } from '../../../notifications';

export const UpdateWebhookConfigRequestSchema = WebhookConfigSchema.pick({
  isActive: true,
  webhookUrl: true,
})
  .partial()
  .strict();

export const UpdateWebhookConfigResponseSchema = WebhookConfigSchema.pick({
  eventId: true,
  isActive: true,
  webhookUrl: true,
});

export type UpdateWebhookConfigRequest = z.infer<
  typeof UpdateWebhookConfigRequestSchema
>;

export type UpdateWebhookConfigResponse = z.infer<
  typeof UpdateWebhookConfigResponseSchema
>;
