import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  HStack,
  Text,
} from '@chakra-ui/react';
import { HydratedDeliveryStop, OrderType } from '@tradeaze-packages/schemas';
import { formatAddressDisplayName } from '@tradeaze/shared/utils';
import React from 'react';
import { DeliveryStopSummary } from './DeliveryStopSummary';

export const DeliveryStopAccordionItem: React.FunctionComponent<{
  deliveryStop: HydratedDeliveryStop;
  type: OrderType;
  proofOfDeliveryBucketname: string | null;
  isAdmin: boolean;
  testId: string;
}> = ({ type, deliveryStop, proofOfDeliveryBucketname, isAdmin, testId }) => {
  const title = formatAddressDisplayName({
    companyName: deliveryStop.companyName,
    addressLine1: deliveryStop.addressLine1,
    addressLine2: deliveryStop.addressLine2,
    addressLine3: deliveryStop.addressLine3,
    postCode: deliveryStop.postCode,
    city: deliveryStop.city,
  });

  return (
    <AccordionItem borderColor="transparent">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            py={0}
            _hover={{ bg: 'transparent' }}
            justifyContent="space-between"
          >
            <Text size="sm">{title}</Text>
            <HStack spacing={2}>
              <Text size="sm">{isExpanded ? 'Hide' : 'View'} Details</Text>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel py={3}>
            <Divider mt={3} mb={6} />
            <DeliveryStopSummary
              deliveryStop={deliveryStop}
              proofOfDeliveryBucketname={proofOfDeliveryBucketname}
              isAdmin={isAdmin}
              testId={testId}
              orderType={type}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
