import { Box, Icon } from '@chakra-ui/react';
import {
  DeliveryItem,
  DeliveryStopType,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import React, { useMemo } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { DeliveryStopMarker } from '../delivery/DeliveryStopMarker';
import { RiderAvatar } from '../rider/RiderAvatar';
import { DeliveryPopupContent } from './DeliveryPopupContent';
import { MarkerContainer } from './MarkerContainer';

export type DeliveryMarkerMode = 'highlighted' | 'normal' | 'faded';

type DeliveryMarkerProps = {
  deliveryId: string;
  deliveryWindowEnd: string;
  deliveryPostcode: string;
  pickUpPostcode: string;
  deliveryVehicle: DeliveryVehicleId;
  isAdmin?: boolean;
  rider?: {
    firstName: string;
    lastName?: string | null;
  };
  stopType?: DeliveryStopType;
  deliveryItems?: DeliveryItem[];
  onHover?: (deliveryId: string) => void;
  onHoverLeave?: (deliveryId: string) => void;
  onClick: () => void;
  isHovered?: boolean;
  isSelected?: boolean;
  mode?: DeliveryMarkerMode;
  stopNumber?: number;
  completedAt?: string;
  orderId?: string;
};

const shouldRerender = (
  prevProps: DeliveryMarkerProps,
  nextProps: DeliveryMarkerProps,
) => {
  return (
    prevProps.isHovered === nextProps.isHovered &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.rider?.firstName === nextProps.rider?.firstName &&
    prevProps.rider?.lastName === nextProps.rider?.lastName &&
    prevProps.mode === nextProps.mode &&
    prevProps.stopNumber === nextProps.stopNumber &&
    prevProps.orderId === nextProps.orderId &&
    prevProps.completedAt === nextProps.completedAt
  );
};

export const DeliveryMarker = React.memo<DeliveryMarkerProps>(
  ({
    deliveryWindowEnd,
    deliveryId,
    deliveryPostcode,
    deliveryVehicle,
    pickUpPostcode: pickupPostcode,
    isAdmin,
    rider,
    stopType,
    deliveryItems,
    onHover,
    onHoverLeave,
    onClick,
    isHovered,
    isSelected,
    mode = 'normal',
    stopNumber,
    orderId,
    completedAt,
  }) => {
    const handleHover = () => {
      onHover?.(deliveryId);
    };

    const handleLeaveHover = () => {
      onHoverLeave?.(deliveryId);
    };

    const markerColor = useMemo(() => {
      if (mode === 'faded' && !isHovered) {
        return 'gray.400';
      }

      return stopType === 'PICK_UP' ? 'blue.600' : 'green.500';
    }, [stopType, mode, isHovered]);

    if (
      mode === 'highlighted' &&
      typeof stopNumber !== 'undefined' &&
      orderId
    ) {
      return (
        <DeliveryStopMarker
          stop={{
            orderId,
            type: stopType ?? 'DROP_OFF',
            completedAt,
          }}
          index={stopNumber}
        />
      );
    }

    return (
      <MarkerContainer
        mode={mode}
        onHover={handleHover}
        onHoverLeave={handleLeaveHover}
        isHovered={isHovered}
        onClick={onClick}
        popupContent={
          <DeliveryPopupContent
            rider={rider}
            deliveryVehicle={deliveryVehicle}
            pickupPostcode={pickupPostcode}
            deliveryPostcode={deliveryPostcode}
            deliveryWindowEnd={deliveryWindowEnd}
            deliveryItems={deliveryItems}
            isAdmin={isAdmin}
            stopType={stopType}
          />
        }
        showPopup={isHovered || isSelected}
      >
        <Box position="relative">
          {rider && (
            <Box position="absolute" left="7.5px" top="4px">
              <RiderAvatar
                height={'15px'}
                width={'15px'}
                size={'xs'}
                firstName={rider.firstName}
                lastName={rider.lastName}
              />
            </Box>
          )}
          <Icon as={FaMapMarkerAlt} fontSize={30} color={markerColor} />
        </Box>
      </MarkerContainer>
    );
  },
  shouldRerender,
);

DeliveryMarker.displayName = 'DeliveryMarker';
