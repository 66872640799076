import { z } from 'zod';

export const GetLookupAddressParamsSchema = z.object({
  id: z.string().min(1),
});

export const GetLookupAddressResponseSchema = z.object({
  formatted_address: z.array(z.string()),
  thoroughfare: z.string(),
  building_name: z.string().optional(),
  sub_building_name: z.string().optional(),
  sub_building_number: z.string().optional(),
  building_number: z.string(),
  line_1: z.string(),
  line_2: z.string().optional(),
  line_3: z.string().optional(),
  line_4: z.string().optional(),
  locality: z.string(),
  town_or_city: z.string(),
  county: z.string(),
  district: z.string(),
  country: z.string(),
  postcode: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  residential: z.boolean(),
});

export type GetLookupAddressParams = z.infer<typeof GetLookupAddressParamsSchema>;
export type GetLookupAddressResponse = z.infer<typeof GetLookupAddressResponseSchema>;
