const WHITESPACE_REGEX = /\s+/g;

export type InputMode = 'numeric' | 'alpha' | 'alphanumeric' | 'default';

export const removeDisallowedChars = (
    value: string,
    options: {
        inputMode?: InputMode,
        maxLength?: number,
    },
  ): string => {
    let result = value;
  
    switch (options.inputMode) {
      case 'numeric':
        result = result.replace(/[^0-9]/g, '');
        break;
      case 'alpha':
        result = result.replace(/[^a-zA-Z]/g, '');
        break;
      case 'alphanumeric':
        result = result.replace(/[^a-zA-Z0-9]/g, '');
        break;
      case 'default':
      default:
        // No changes to the value
        break;
    }
  
    if (typeof options.maxLength === 'number') {
      result = result.slice(0, Math.max(0, options.maxLength));
    }
  
    return result;
  };
  
  export const removeSeparators = (value: string, separator?: string): string => {
    return value.replace(
      separator ? new RegExp(separator, 'g') : WHITESPACE_REGEX,
      '',
    );
  };