import { Box, HStack, Show } from '@chakra-ui/react';
import {
  HydratedDelivery,
  HydratedOrder,
  MerchantAccount,
  RiderAccount,
  RiderIdentity,
} from '@tradeaze-packages/schemas';
import {
  useGetOrder,
  useSubscribeToExternalCourierLocations,
  useSubscribeToRiderLocations,
} from '@tradeaze/frontend/hooks';
import { Z_INDEX } from '../constants';
import { Layout, TwoColumnLayout } from '../layout';
import {
  Map,
  MapPointSelectMenu,
  MobileMapWithToggle,
  useMarkerHover
} from '../map';
import { OrderQueryPage, useDashboardMapMarkers } from '../order';
import { OrderDeliveriesHeading } from './OrderDeliveriesHeading';

export const OrderDeliveriesContainer = ({
  selectedOrder: initialSelectedOrder,
  merchants,
  ridersToShow,
  isAdmin,
  onPressGoTo,
  onSelectDelivery,
  selectedDelivery,
  onSelectOrder,
  onClickDelivery,
}: {
  initialDate: Date;
  selectedOrder: HydratedOrder;
  merchants?: MerchantAccount[];
  ridersToShow?: (RiderIdentity &
    Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  isAdmin: boolean;
  onPressGoTo: (orderId: string) => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onSelectOrder?: () => void;
  selectedDelivery?: HydratedDelivery;
  onClickDelivery: (order: HydratedOrder) => void;
}) => {
  const showSelectPoints = isAdmin;

  const markerHover = useMarkerHover();

  const orderQuery = useGetOrder(
    initialSelectedOrder.orderId,
    {
      includeProofMedia: false,
    },
    {
      initialData: initialSelectedOrder,
    },
  );

  const order = orderQuery.data ?? initialSelectedOrder;

  const deliveries = order.deliveries.map((delivery) => ({
    ...delivery,
    order,
  }));

  const externalCouriers = deliveries
    .map((delivery) => delivery.externalCourier)
    .filter((courier) => courier !== undefined && courier !== null);

  const externalCourierLocations = useSubscribeToExternalCourierLocations(
    externalCouriers.map((courier) => courier.id),
  );

  const riderLocations = useSubscribeToRiderLocations(
    ridersToShow?.map((rider) => rider.riderId) || [],
  );

  const pickups = order.deliveries.map((delivery) => delivery.pickup);

  const dropOffs = order.deliveries.map((delivery) => delivery.dropOff);

  const { mapMarkers, mapPoints } = useDashboardMapMarkers({
    deliveries,
    merchants,
    riders: ridersToShow,
    externalCouriers,
    externalCourierLocations,
    riderLocations,
    isAdmin,
    selectedDelivery,
    onClickDelivery: onClickDelivery,
    initialFilters: {
      MERCHANT: false,
      DROP_OFF: true,
      PICK_UP: true,
      RIDER: true,
      ADDRESS: true,
      DELIVERY: true,
    },
    ...markerHover,
  });

  const enableGridHover = !isAdmin;

  const DeliveryGrid = (
    <Box>
      <OrderDeliveriesHeading
        order={order}
        pickups={pickups}
        dropOffs={dropOffs}
        onSelectOrder={onSelectOrder}
        onPressGoTo={onPressGoTo}
      />
      <OrderQueryPage
        onPressGoTo={onPressGoTo}
        fetchNextPage={() => void 0}
        hasNextPage={false}
        isFiltered={false}
        isAdmin={isAdmin}
        isError={orderQuery.isError}
        isFetchingNextPage={false}
        isLoading={orderQuery.isLoading}
        orders={[order]}
        deliveries={deliveries}
        columns={[1, 1, 1, 1, 2]}
        selectedDelivery={selectedDelivery}
        onSelectDelivery={onSelectDelivery}
        groupByOrder={false}
        {...(enableGridHover ? markerHover : {})}
      />
    </Box>
  );

  return (
    <Box
      position={'fixed'}
      left={0}
      top={0}
      height={'100%'}
      width={'100%'}
      zIndex={Z_INDEX.ORDER_DELIVERIES}
      overflowY={'auto'}
    >
      <Show above="md">
        <TwoColumnLayout
          left={DeliveryGrid}
          leftContainerProps={{ backgroundColor: 'white' }}
          right={
            <>
              <Map
                markers={mapMarkers}
                cursor={mapPoints.enabled ? 'crosshair' : 'grab'}
                onClick={mapPoints.handleClick}
              />
              {showSelectPoints ? (
                <HStack
                  position={'fixed'}
                  bottom={12}
                  right={3}
                  maxWidth="100vw"
                  flexWrap={'wrap'}
                  justifyContent="flex-end"
                  zIndex={Z_INDEX.ORDER_DELIVERIES_MAP_CONTROLS}
                >
                  <MapPointSelectMenu {...mapPoints} />
                </HStack>
              ) : null}
            </>
          }
        />
      </Show>
      <Show below="md">
        <Layout backgroundColor={'white'}>
          {DeliveryGrid}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
              cursor: mapPoints.enabled ? 'crosshair' : 'grab',
              onClick: mapPoints.handleClick,
            }}
            zIndexButton={Z_INDEX.ASSIGN_SCHEDULE_MAP_CONTROLS}
            zIndexMap={Z_INDEX.ASSIGN_SCHEDULE_MAP}
            controls={<MapPointSelectMenu {...mapPoints} size="sm" />}
          />
        </Layout>
      </Show>
    </Box>
  );
};
