import React from 'react';

export const SentryErrorFallback: React.FC = () => {
  return (
    <div
      style={{
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p>
        Oops! We've run into an error. Please reload the page to get back
        to work.
      </p>
    </div>
  );
};