import { z } from 'zod';
import { EventTypeSchema } from './event-types';
import {
  HydratedOrderSchema,
  HydratedDeliverySchema,
  HydratedBookingApprovalSchema,
} from '../models/hydrated-schema';
import { BookingApprovalSchema } from '../models/booking-approval-schema';
import { BookingUserSchema } from '../models/booking-user-schema';

/**
 * Email Data
 * @description
 * This is the data that is used to generate emails
 */

/**
 * Shared Data Schemas
 */
const OrderActionEmailDataSchema = z.object({
  order: HydratedOrderSchema,
  adminEmail: z.string(),
  merchantEmails: z.array(z.string()).optional(),
});

const DeliveryActionEmailDataSchema = z.object({
  order: HydratedOrderSchema,
  delivery: HydratedDeliverySchema,
  adminEmail: z.string(),
  merchantEmails: z.array(z.string()).optional(),
});

const BookingApprovalRequiredEmailDataSchema = z.object({
  order: HydratedOrderSchema,
  latestBookingApproval: BookingApprovalSchema,
  bookingApprovers: z.array(BookingUserSchema),
});

const BookingApprovalActionedEmailDataSchema = z.object({
  order: HydratedOrderSchema,
  merchantEmails: z.array(z.string()),
  adminEmail: z.string(),
  bookingApproval: HydratedBookingApprovalSchema,
});

/**
 * Email Type Schemas
 */
const OrderCreatedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionEmailDataSchema,
});

const OrderConfirmedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionEmailDataSchema,
});

const OrderCancelledEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionEmailDataSchema,
});

const DeliveryCancelledExternallyEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.EXTERNAL_JOB_CANCELLED_EXTERNALLY),
  data: DeliveryActionEmailDataSchema,
});

const OrderRejectedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionEmailDataSchema,
});

const OrderUpdatedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionEmailDataSchema,
});

const DeliveryConfirmedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CONFIRMED),
  data: DeliveryActionEmailDataSchema,
});

const DeliveryCancelledEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
  data: DeliveryActionEmailDataSchema,
});

const DeliveryRejectedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
  data: DeliveryActionEmailDataSchema,
});

const BookingApprovalRequiredEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.BOOKING_APPROVAL_REQUIRED),
  data: BookingApprovalRequiredEmailDataSchema,
});

const BookingApprovedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.BOOKING_APPROVED),
  data: BookingApprovalActionedEmailDataSchema,
});

const BookingRejectedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.BOOKING_REJECTED),
  data: BookingApprovalActionedEmailDataSchema,
});

/**
 * Without this type defined explicity, TypeScript will complain about:
 * "The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed."
 */
type EmailInputSchemaType = z.ZodDiscriminatedUnion<
  'type',
  [
    typeof OrderCreatedEmailSchema,
    typeof OrderConfirmedEmailSchema,
    typeof OrderCancelledEmailSchema,
    typeof DeliveryCancelledExternallyEmailSchema,
    typeof OrderRejectedEmailSchema,
    typeof OrderUpdatedEmailSchema,
    typeof DeliveryConfirmedEmailSchema,
    typeof DeliveryCancelledEmailSchema,
    typeof DeliveryRejectedEmailSchema,
    typeof BookingApprovalRequiredEmailSchema,
    typeof BookingApprovedEmailSchema,
    typeof BookingRejectedEmailSchema,
  ]
>;

export const EmailInputSchema: EmailInputSchemaType = z.discriminatedUnion(
  'type',
  [
    OrderCreatedEmailSchema,
    OrderConfirmedEmailSchema,
    OrderCancelledEmailSchema,
    DeliveryCancelledExternallyEmailSchema,
    OrderRejectedEmailSchema,
    OrderUpdatedEmailSchema,
    DeliveryConfirmedEmailSchema,
    DeliveryCancelledEmailSchema,
    DeliveryRejectedEmailSchema,
    BookingApprovalRequiredEmailSchema,
    BookingApprovedEmailSchema,
    BookingRejectedEmailSchema,
  ],
);

// Export email types
export type OrderActionedEmailData = z.infer<typeof OrderActionEmailDataSchema>;
export type DeliveryActionedEmailData = z.infer<typeof DeliveryActionEmailDataSchema>;
export type BookingApprovalRequiredEmailData = z.infer<typeof BookingApprovalRequiredEmailDataSchema>;
export type BookingApprovalActionedEmailData = z.infer<typeof BookingApprovalActionedEmailDataSchema>;
export type BookingApprovedEmailData = z.infer<typeof BookingApprovalActionedEmailDataSchema>;
export type BookingRejectedEmailData = z.infer<typeof BookingApprovalActionedEmailDataSchema>;
export type EmailInput = z.infer<typeof EmailInputSchema>; 