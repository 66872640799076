import {
  HStack,
  Icon,
  Text,
  TextDecorationProps
} from '@chakra-ui/react';
import React from 'react';

export const IconText: React.FC<{
  icon: React.ElementType;
  text: string;
  color?: string;
  fontSize?: string | number;
  onClick?: (e: React.MouseEvent) => void;
  textDecoration?: TextDecorationProps['textDecoration'];
  textDecorationStyle?: TextDecorationProps['textDecorationStyle'];
}> = ({
  icon,
  text,
  color,
  fontSize = 'md',
  onClick,
  textDecoration,
  textDecorationStyle,
}) => (
  <HStack
    onClick={onClick}
    cursor={onClick ? 'pointer' : undefined}
    _hover={onClick ? { color: 'blue.500' } : {}}
  >
    <Icon as={icon} color={color} fontSize={fontSize} />
    <Text
      fontSize={fontSize}
      color={color}
      textDecoration={textDecoration}
      textDecorationStyle={textDecorationStyle}
    >
      {text}
    </Text>
  </HStack>
);
