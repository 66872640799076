import { z } from 'zod';
import { BankAccountTypeSchema, RiderBankDetailsSchema } from '../../../models';

export const CreateRiderBankDetailsBodySchema = RiderBankDetailsSchema.omit({
  id: true,
  riderId: true,
  createdAt: true,
  updatedAt: true,
}).superRefine((data, ctx) => {
  if (data.accountType === BankAccountTypeSchema.Enum.PERSONAL && !data.nino) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'National Insurance Number is required for personal accounts',
      path: ['nino'],
    });
  } else if (
    data.accountType === BankAccountTypeSchema.Enum.BUSINESS &&
    data.nino
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'National Insurance Number is not allowed for business accounts',
      path: ['nino'],
    });
  }
});

export const CreateRiderBankDetailsResponseSchema = RiderBankDetailsSchema.pick(
  {
    riderId: true,
    createdAt: true,
    updatedAt: true,
  },
);

export type CreateRiderBankDetailsBody = z.infer<
  typeof CreateRiderBankDetailsBodySchema
>;
export type CreateRiderBankDetailsResponse = z.infer<
  typeof CreateRiderBankDetailsResponseSchema
>;
