import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  ArrivedAtDeliveryStopResponseSchema,
  CompleteDeliveryStopResponseSchema,
  DisableDeliveryOptionBodySchema,
  DisableDeliveryOptionResponseSchema,
  EnableDeliveryOptionBodySchema,
  EnableDeliveryOptionResponseSchema,
  GetDeliveryOptionsQuerySchema,
  GetDeliveryOptionsResponseSchema,
  GetDeliveryPriceResponseSchema,
  GetDeliverySchedulesForDateQuerySchema,
  GetDeliverySchedulesForDateResponseSchema,
  GetRiderDeliveryScheduleQuerySchema,
  GetRiderDeliveryScheduleResponseSchema,
  GetTotalTimeEstimateBodySchema,
  GetTotalTimeEstimateResponseSchema,
  OptimiseRoutesBodySchema,
  OptimiseRoutesResponseSchema,
  OptimiseScheduleBodySchema,
  OptimiseScheduleResponseSchema,
  SetDeliveryStopsSequenceBodySchema,
  SetDeliveryStopsSequenceResponseSchema,
  UndoCompleteDeliveryStopResponseSchema,
  UpdateProofNameBodySchema,
  UpdateProofNameResponseSchema,
  GetThresholdDistancesResponseSchema,
  GetDeliveryEtaQuerySchema,
  GetDeliveryEtaResponseSchema,
  LeftDeliveryStopBodySchema,
  LeftDeliveryStopResponseSchema,
  ArrivedAtDeliveryStopBodySchema,
  GetAdminDeliveriesQuerySchema,
  GetAdminDeliveriesResponseSchema,
  GetDeliveryByIdResponseSchema,
  GetDeliveriesByEndUserIdQuerySchema,
  GetDeliveriesByEndUserIdResponseSchema,
  GetDeliveriesByMerchantIdQuerySchema,
  GetDeliveriesByMerchantIdResponseSchema,
  GetDeliveriesByRiderIdQuerySchema,
  GetDeliveriesByRiderIdResponseSchema,
  AssignRiderBodySchema,
  AssignRiderResponseSchema,
  CompleteDeliveryResponseSchema,
  CancelDeliveryResponseSchema,
  ConfirmDeliveryResponseSchema,
  RejectDeliveryResponseSchema,
  GetTrackingDeliveryResponseSchema,
  GetDeliveryPriceStringQuerySchema,
  GetDeliveryPriceArrayQuerySchema,
  GetThresholdDistancesArrayQuerySchema,
  CancelDeliveryRequestSchema,
  DownloadMerchantDeliveriesCsvQuerySchema,
  DownloadMerchantDeliveriesCsvResponseSchema,
  DownloadAdminDeliveriesCsvQuerySchema,
  DownloadAdminDeliveriesCsvResponseSchema,
  SelectQuoteBodySchema,
  ExternalJobResponseSchema,
  GetQuotesResponseSchema,
  UnassignRiderBodySchema,
  UnassignRiderResponseSchema,
} from '../../delivery-service';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/delivery';

/**
 * POST /arrivedAtDeliveryStop
 */
registry.registerPath({
  operationId: 'arrivedAtDeliveryStop',
  path: `${basePath}/arrivedAtDeliveryStop`,
  description: 'Arrived at delivery stop.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: ArrivedAtDeliveryStopBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Arrived at delivery stop.',
      content: {
        'application/json': {
          schema: ArrivedAtDeliveryStopResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /leftDeliveryStop
 */
registry.registerPath({
  operationId: 'leftDeliveryStop',
  path: `${basePath}/leftDeliveryStop`,
  description: 'Left delivery stop.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: LeftDeliveryStopBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Left delivery stop.',
      content: {
        'application/json': {
          schema: LeftDeliveryStopResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /completeDeliveryStop/{deliveryStopId}
 */
registry.registerPath({
  operationId: 'completeDeliveryStop',
  path: `${basePath}/completeDeliveryStop/{deliveryStopId}`,
  description: 'Complete delivery stop.',
  method: 'post',
  request: {
    params: z.object({
      deliveryStopId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Completed delivery stop.',
      content: {
        'application/json': {
          schema: CompleteDeliveryStopResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /disableDeliveryOption
 */
registry.registerPath({
  operationId: 'disableDeliveryOption',
  path: `${basePath}/disableDeliveryOption`,
  description: 'Disable delivery option.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: DisableDeliveryOptionBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Disabled delivery option.',
      content: {
        'application/json': {
          schema: DisableDeliveryOptionResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /enableDeliveryOption
 */
registry.registerPath({
  operationId: 'enableDeliveryOption',
  path: `${basePath}/enableDeliveryOption`,
  description: 'Enable delivery option.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: EnableDeliveryOptionBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Enabled delivery option.',
      content: {
        'application/json': {
          schema: EnableDeliveryOptionResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveryOptions
 */
registry.registerPath({
  operationId: 'getDeliveryOptions',
  path: `${basePath}/getDeliveryOptions`,
  description: 'Get delivery options.',
  method: 'get',
  request: {
    query: GetDeliveryOptionsQuerySchema,
  },
  responses: {
    200: {
      description: 'Delivery options.',
      content: {
        'application/json': {
          schema: GetDeliveryOptionsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveryPrice
 */
registry.registerPath({
  operationId: 'getDeliveryPrice',
  path: `${basePath}/getDeliveryPrice`,
  description: 'Get delivery price.',
  method: 'get',
  request: {
    query: z.object({
      ...GetDeliveryPriceStringQuerySchema.shape,
      ...GetDeliveryPriceArrayQuerySchema.shape,
    }),
  },
  responses: {
    200: {
      description: 'Delivery price.',
      content: {
        'application/json': {
          schema: GetDeliveryPriceResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliverySchedulesForDate
 */
registry.registerPath({
  operationId: 'getDeliverySchedulesForDate',
  path: `${basePath}/getDeliverySchedulesForDate`,
  description: 'Get delivery schedules for date.',
  method: 'get',
  request: {
    query: GetDeliverySchedulesForDateQuerySchema,
  },
  responses: {
    200: {
      description: 'Get delivery schedules.',
      content: {
        'application/json': {
          schema: GetDeliverySchedulesForDateResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiderDeliverySchedule/{riderId}
 */
registry.registerPath({
  operationId: 'getRiderDeliverySchedule',
  path: `${basePath}/getRiderDeliverySchedule/{riderId}`,
  description: 'Get rider delivery schedule.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetRiderDeliveryScheduleQuerySchema,
  },
  responses: {
    200: {
      description: 'Rider delivery schedule.',
      content: {
        'application/json': {
          schema: GetRiderDeliveryScheduleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /getTotalTimeEstimate
 */
registry.registerPath({
  operationId: 'getTotalTimeEstimate',
  path: `${basePath}/getTotalTimeEstimate`,
  description: 'Get total time estimate.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: GetTotalTimeEstimateBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Total time estimate.',
      content: {
        'application/json': {
          schema: GetTotalTimeEstimateResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /optimiseRoutes
 */
registry.registerPath({
  operationId: 'optimiseRoutes',
  path: `${basePath}/optimiseRoutes`,
  description: 'Optimise routes.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: OptimiseRoutesBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Optimise routes.',
      content: {
        'application/json': {
          schema: OptimiseRoutesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /optimiseSchedule
 */
registry.registerPath({
  operationId: 'optimiseSchedule',
  path: `${basePath}/optimiseSchedule`,
  description: 'Optimise schedule.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: OptimiseScheduleBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Optimise schedule.',
      content: {
        'application/json': {
          schema: OptimiseScheduleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /setDeliveryStopsSequence
 */
registry.registerPath({
  operationId: 'setDeliveryStopsSequence',
  path: `${basePath}/setDeliveryStopsSequence`,
  description: 'Set delivery stops sequence.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: SetDeliveryStopsSequenceBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Set delivery stops sequence.',
      content: {
        'application/json': {
          schema: SetDeliveryStopsSequenceResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /undoCompleteDeliveryStop/{deliveryStopId}
 */
registry.registerPath({
  operationId: 'undoCompleteDeliveryStop',
  path: `${basePath}/undoCompleteDeliveryStop/{deliveryStopId}`,
  description: 'Undo complete delivery stop.',
  method: 'post',
  request: {
    params: z.object({
      deliveryStopId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Undo complete delivery stop.',
      content: {
        'application/json': {
          schema: UndoCompleteDeliveryStopResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateProofName/{deliveryStopId}
 */
registry.registerPath({
  operationId: 'updateProofName',
  path: `${basePath}/updateProofName/{deliveryStopId}`,
  description: 'Update proof name.',
  method: 'post',
  request: {
    params: z.object({
      deliveryStopId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateProofNameBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Update proof name.',
      content: {
        'application/json': {
          schema: UpdateProofNameResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getThresholdDistance
 */
registry.registerPath({
  operationId: 'getThresholdDistances',
  path: `${basePath}/getThresholdDistances`,
  description: 'Get threshold distances.',
  method: 'get',
  request: {
    query: z.object({
      ...GetThresholdDistancesArrayQuerySchema.shape,
    }),
  },
  responses: {
    200: {
      description: 'Threshold distances.',
      content: {
        'application/json': {
          schema: GetThresholdDistancesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveryEta
 */
registry.registerPath({
  operationId: 'getDeliveryEta',
  path: `${basePath}/getDeliveryEta`,
  description: 'Get status and ETA of delivery stops.',
  method: 'get',
  request: {
    query: GetDeliveryEtaQuerySchema,
  },
  responses: {
    200: {
      description: 'Return the delivery status and ETA of delivery stops.',
      content: {
        'application/json': {
          schema: GetDeliveryEtaResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getAdminDeliveries
 */
registry.registerPath({
  operationId: 'getAdminDeliveries',
  path: `${basePath}/getAdminDeliveries`,
  description: 'Get admin deliveries.',
  method: 'get',
  request: {
    query: GetAdminDeliveriesQuerySchema,
  },
  responses: {
    200: {
      description: 'Deliveries fetched successfully.',
      content: {
        'application/json': {
          schema: GetAdminDeliveriesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveryById/{deliveryId}
 */
registry.registerPath({
  operationId: 'getDeliveryById',
  path: `${basePath}/getDeliveryById/{deliveryId}`,
  description: 'Get delivery by ID.',
  method: 'get',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Delivery fetched successfully.',
      content: {
        'application/json': {
          schema: GetDeliveryByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveriesByEndUserId/{endUserId}
 */
registry.registerPath({
  operationId: 'getDeliveriesByEndUserId',
  path: `${basePath}/getDeliveriesByEndUserId/{endUserId}`,
  description: 'Get deliveries by end user ID.',
  method: 'get',
  request: {
    params: z.object({
      endUserId: z.string(),
    }),
    query: GetDeliveriesByEndUserIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Deliveries fetched successfully.',
      content: {
        'application/json': {
          schema: GetDeliveriesByEndUserIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveriesByMerchantId/{merchantId}
 */
registry.registerPath({
  operationId: 'getDeliveriesByMerchantId',
  path: `${basePath}/getDeliveriesByMerchantId/{merchantId}`,
  description: 'Get deliveries by merchant ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: GetDeliveriesByMerchantIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Deliveries fetched successfully.',
      content: {
        'application/json': {
          schema: GetDeliveriesByMerchantIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getDeliveriesByRiderId/{riderId}
 */
registry.registerPath({
  operationId: 'getDeliveriesByRiderId',
  path: `${basePath}/getDeliveriesByRiderId/{riderId}`,
  description: 'Get orders by rider ID.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetDeliveriesByRiderIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Deliveries fetched successfully.',
      content: {
        'application/json': {
          schema: GetDeliveriesByRiderIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /assignRider/{deliveryId}/{riderId}
 */
registry.registerPath({
  operationId: 'assignRider',
  path: `${basePath}/assignRider/{deliveryId}/{riderId}`,
  description: 'Assign a rider to a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: AssignRiderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Rider assigned successfully.',
      content: {
        'application/json': {
          schema: AssignRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /unassignRider/{deliveryId}/{riderId}
 */
registry.registerPath({
  operationId: 'unassignRider',
  path: `${basePath}/unassignRider/{deliveryId}/{riderId}`,
  description: 'Unassign a rider from a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UnassignRiderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Rider unassigned successfully.',
      content: {
        'application/json': {
          schema: UnassignRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /cancelDelivery/{deliveryId}
 */
registry.registerPath({
  operationId: 'cancelDelivery',
  path: `${basePath}/cancelDelivery/{deliveryId}`,
  description: 'Cancel a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: CancelDeliveryRequestSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Delivery cancelled successfully.',
      content: {
        'application/json': {
          schema: CancelDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /completeDelivery/{deliveryId}
 */
registry.registerPath({
  operationId: 'completeDelivery',
  path: `${basePath}/completeDelivery/{deliveryId}`,
  description: 'Complete a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order completed successfully.',
      content: {
        'application/json': {
          schema: CompleteDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /confirmDelivery/{deliveryId}
 */
registry.registerPath({
  operationId: 'confirmDelivery',
  path: `${basePath}/confirmDelivery/{deliveryId}`,
  description: 'Confirm a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Delivery confirmed successfully.',
      content: {
        'application/json': {
          schema: ConfirmDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /rejectDelivery/{deliveryId}
 */
registry.registerPath({
  operationId: 'rejectDelivery',
  path: `${basePath}/rejectDelivery/{deliveryId}`,
  description: 'Reject a delivery.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Delivery rejected successfully.',
      content: {
        'application/json': {
          schema: RejectDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getTrackingDelivery/{deliveryId}
 */
registry.registerPath({
  operationId: 'getTrackingDelivery',
  path: `${basePath}/getTrackingDelivery/{deliveryId}`,
  description: 'Get tracking delivery by ID.',
  method: 'get',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Delivery fetched successfully.',
      content: {
        'application/json': {
          schema: GetTrackingDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /csv/merchant/{merchantId}
 */
registry.registerPath({
  operationId: 'downloadMerchantDeliveriesCsv',
  path: `${basePath}/csv/merchant/{merchantId}`,
  description: 'Download merchant deliveries CSV.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: DownloadMerchantDeliveriesCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Merchant deliveries CSV downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadMerchantDeliveriesCsvResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /csv/admin
 */
registry.registerPath({
  operationId: 'downloadAdminDeliveriesCsv',
  path: `${basePath}/csv/admin`,
  description: 'Download admin deliveries CSV.',
  method: 'get',
  request: {
    query: DownloadAdminDeliveriesCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Admin deliveries CSV downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadAdminDeliveriesCsvResponseSchema,
        },
      },
    },
  },
});

/**
 * GET /csv/merchant/{merchantId}
 */
registry.registerPath({
  operationId: 'downloadMerchantDeliveriesCsv',
  path: `${basePath}/csv/merchant/{merchantId}`,
  description: 'Download merchant deliveries CSV.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: DownloadMerchantDeliveriesCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Merchant deliveries CSV downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadMerchantDeliveriesCsvResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /csv/admin
 */
registry.registerPath({
  operationId: 'downloadAdminDeliveriesCsv',
  path: `${basePath}/csv/admin`,
  description: 'Download admin deliveries CSV.',
  method: 'get',
  request: {
    query: DownloadAdminDeliveriesCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Admin deliveries CSV downloaded successfully.',
      content: {
        'application/json': {
          schema: DownloadAdminDeliveriesCsvResponseSchema,
        },
      },
    },
  },
});

/**
 * GET /{deliveryId}/external-quotes
 */
registry.registerPath({
  operationId: 'externalQuotes',
  path: `${basePath}/{deliveryId}/external-quotes`,
  description: 'Get external quotes.',
  method: 'get',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Quotes fetched successfully.',
      content: {
        'application/json': {
          schema: GetQuotesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /{deliveryId}/select-external-quote
 */
registry.registerPath({
  operationId: 'selectExternalQuote',
  path: `${basePath}/{deliveryId}/select-external-quote`,
  description: 'Select quote to create external job.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: SelectQuoteBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Quote selected successfully.',
      content: {
        'application/json': {
          schema: ExternalJobResponseSchema,
        },
      }
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /cancelExternalJob/{deliveryId}
 */
registry.registerPath({
  operationId: 'cancelExternalJob',
  path: `${basePath}/cancelExternalJob/{deliveryId}`,
  description: 'Cancel external job.',
  method: 'post',
  request: {
    params: z.object({
      deliveryId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Cancelled job successfully.',
    },
    ...commonErrorResponses,
  },
});

export const deliveryApiRegistry = registry;
