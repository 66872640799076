import { Text } from '@chakra-ui/react';

/**
 * Normalises the registration number to a standard format
 * @param regNumber - The registration number to normalise
 * @returns The normalised registration number
 * 
 * New format (since 2001): https://www.gov.uk/displaying-number-plates
 */
const normaliseRegNumber = (regNumber: string) => {
  // Remove all spaces and convert to uppercase
  const cleaned = regNumber.replace(/ /g, '').toUpperCase();
  
  // If the length is 7 characters (standard UK format)
  if (cleaned.length === 7) {
    // Insert space after first 4 characters for new style plates (e.g., "AB12 CDE")
    // or after first 2 characters for old style plates (e.g., "AB 12CDE")
    const isNewStyle = /^[A-Z]{2}\d{2}[A-Z]{3}$/.test(cleaned);
    return isNewStyle 
      ? `${cleaned.slice(0, 4)} ${cleaned.slice(4)}`
      : `${cleaned.slice(0, 2)} ${cleaned.slice(2)}`;
  }
  
  return cleaned; // Return as-is if not standard length
};

export const RegPlate = ({
  regNumber,
  fontSize,
}: {
  regNumber: string;
  fontSize?: string;
}) => {
  return (
    <Text
      color="black"
      textAlign="center"
      fontSize={fontSize}
      fontFamily="robotoMono"
      fontWeight="bold"
      letterSpacing="1px"      
      bg="#FFD307"
      minWidth="fit-content"
      width="fit-content"
      height="fit-content"
      px={3}
      py={1}
      borderRadius="0.25em"
      display="inline-block"
      textTransform="uppercase"
    >
      {normaliseRegNumber(regNumber)}
    </Text>
  );
};
