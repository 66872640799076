import React from 'react';
import { FormAttributeConfig, FormConfigContext } from './FormConfigContext';

type FormConfigProviderProps = {
  getFormAttributeConfig?: (fieldName: string) => FormAttributeConfig | null;
  children: React.ReactNode;
};

export const FormConfigProvider: React.FC<FormConfigProviderProps> = ({
  getFormAttributeConfig,
  children,
}) => {
  return (
    <FormConfigContext.Provider value={{ getFormAttributeConfig }}>
      {children}
    </FormConfigContext.Provider>
  );
};
