import { createStringValidator } from './validationFactory';

const SORT_CODE_REGEX = /^\s*\d{2}-?\d{2}-?\d{2}\s*$/;
const SORT_CODE_SPACING_REGEX = /(\d{2})(?=.)/g;

/**
 * Validator for UK bank sort codes
 * Format: XX-XX-XX (6 digits, usually displayed in pairs)
 */
export const sortcodeValidator = createStringValidator({
  utilityName: 'Sort code',
  regex: SORT_CODE_REGEX,
  inputOptions: {
    inputMode: 'numeric',
    maxLength: 8,
    separator: '-',
  },
  formatOptions: {
    removeSeparators: true,
    postFormat: (value) => {
      // Format as XX-XX-XX
      return value.replace(SORT_CODE_SPACING_REGEX, '$1-');
    },
  },
});
