import { HStack, Text } from '@chakra-ui/react';

export type MapMarkerToolTipProps = {
  icon?: React.ReactNode;
  text?: string;
};

export const MapMarkerTooltip = ({ icon, text }: MapMarkerToolTipProps) => {
  return (
    <HStack
      bg="white"
      borderRadius={10}
      paddingX={2}
      fontSize={14}
      spacing={1}
      minWidth="max-content"
    >
      {icon}
      {text && <Text>{text}</Text>}
    </HStack>
  );
};
