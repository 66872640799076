import React from 'react';
import {
  ChakraProvider,
  ComponentStyleConfig,
  extendTheme,
  StatNumber,
} from '@chakra-ui/react';
import '@fontsource/be-vietnam-pro';
import '@fontsource/roboto-mono';

interface Props {
  children: React.ReactNode;
}

const theme = extendTheme({
  breakpoints: {
    base: '0em',
    xs: '24em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  fonts: {
    beVietnamPro: 'Be Vietnam Pro, sans-serif',
    robotoMono: 'Roboto Mono, monospace',
  },
  styles: {
    global: {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      '*': {
        WebkitTapHighlightColor: 'transparent',
      },
    },
  },
  colors: {
    yellow: {
      '50': '#FFFAE6',
      '100': '#FFF0B8',
      '200': '#FFE68A',
      '300': '#FEDD5C',
      '400': '#FED32F',
      '500': '#FEC901',
      '600': '#CBA101',
      '700': '#987901',
      '800': '#665000',
      '900': '#332800',
    },
    border: '#A7A7A8',
    'text-secondary': '#666668',
    brandSecondary: {
      50: '#e5e5e5',
      100: '#bfbfbf',
      200: '#999999',
      300: '#737373',
      400: '#595959',
      500: '#3f3f41',
      600: '#363636',
      700: '#2c2c2c',
      800: '#232323',
      900: '#191919',
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'brandSecondary',
      },
    } as ComponentStyleConfig,
    Stat: {
      sizes: {
        sm: {
          label: {
            fontSize: 'xs',
            fontWeight: 'normal',
            opacity: 0.8,
          },
          number: {
            fontSize: 'md',
            fontWeight: 'semibold',
          },
          helpText: {
            fontSize: 'xs',
            opacity: 0.7,
            mb: 0,
          },
        },
        md: {
          label: {
            fontSize: 'sm',
            fontWeight: 'medium',
          },
          number: {
            fontSize: 'lg',
          },
          helpText: {
            fontSize: 'sm',
            opacity: 0.8,
            mb: 0,
          },
        },
        lg: {
          label: {
            fontSize: 'sm',
            fontWeight: 'medium',
          },
          number: {
            fontSize: '2xl',
          },
          helpText: {
            fontSize: 'sm',
            opacity: 0.8,
            mb: 0,
          },
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'yellow',
      },
    },
    Modal: {
      baseStyle: {
        dialogContainer: {
          maxWidth: 'none',
        },
      },
      variants: {
        customVariant: {
          dialogContainer: {
            width: '48vw',
            right: 0,
            left: 'unset',
            alignItems: 'center',
          },
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 'md',
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: '#877027',
            borderColor: '#877027',
            borderBottom: '2px solid',
            fontWeight: '600',
          },
        },
      },
    },
  },
});

export const ChakraStyleProvider = ({ children }: Props) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
