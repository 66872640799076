export const getStopTitle = (
  stopPostcodes: string[],
  index: number,
  isMultiDrop: boolean,
) => {
  const isAtoB = stopPostcodes.length === 2;
  const isFirstStop = index === 0;
  const isLastStop = index === stopPostcodes.length - 1;

  if (isFirstStop && (isMultiDrop || isAtoB)) {
    return 'Collection';
  }
  if (isLastStop && !isMultiDrop) {
    return 'Delivery';
  }
  
  return isMultiDrop ? `Delivery ${index}` : `Collection ${index + 1}`;
};
