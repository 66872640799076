import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateManVanRequest,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import {
  HireInformation,
  Layout,
  ManVanBookingForm,
  ManVanFormData,
  ManVanFormSchema,
  VehicleSelector,
  vehicleOptions,
} from '@tradeaze/frontend/ui-web';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useAuthContext } from '../components/auth/AuthProvider';
import { handleError } from '@tradeaze/frontend/utils';

type VehicleType = (typeof vehicleOptions)[number]['type'];

/**
 * This page and form are in MVP state.
 */
export const ManVanHirePage = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleType | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<ManVanFormData>({
    resolver: zodResolver(ManVanFormSchema),
    defaultValues: {
      date: format(new Date(), 'yyyy-MM-dd'),
    },
    mode: 'onChange',
  });

  const auth = useAuthContext();
  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);

  useEffect(() => {
    if (merchantQuery.data?.contactNumber) {
      setValue('contactNumber', merchantQuery.data.contactNumber);
    }
  }, [merchantQuery.data?.contactNumber, setValue]);

  const createManVanMutation = useCreateManVanRequest();

  const onSubmit = async (data: ManVanFormData) => {
    try {
      const { termsAccepted, ...apiData } = data;
      await createManVanMutation.mutateAsync({
        ...apiData,
        merchantName: merchantQuery.data?.merchantName || '',
      });

      toast.success('ManVan Hire Enquiry Submitted!', { duration: 5000 });

      setSelectedVehicle(null);
      setValue('vehicle', null as any);
      setValue('date', format(new Date(), 'yyyy-MM-dd'));
      setValue('bookedBy', '');
      setValue('termsAccepted', null as any);
      // Don't reset contact number as it's prefilled from merchant account
    } catch (error) {
      toast.error(
        'There was an error submitting your enquiry. Contact support if the issue persists.',
      );
    }
  };

  const onInvalid: Parameters<typeof handleSubmit>[1] = () => {
    handleError(`Invalid form`, {
      sendToSentry: false,
      showNotification: true,
      notificationMessage: `Invalid form`,
    });
  };

  const handleVehicleSelect = (vehicleType: VehicleType) => {
    setSelectedVehicle(vehicleType);
    setValue('vehicle', vehicleType);
    trigger('vehicle');
  };

  const handleBookingSubmit = handleSubmit((data) => onSubmit(data), onInvalid);

  return (
    <Layout isAuthScreen={false}>
      <Box p={{ base: 4, md: 8 }} maxW="container.lg" mx="auto">
        <VStack spacing={8} align="stretch" mx="auto">
          <Box mb={3}>
            <Heading size="lg" mb={4}>
              ManVan Hire Enquiry
            </Heading>
            <Text>
              Hire a professional driver and van on a daily rate basis for your
              transportation needs. You will manage delivery operations of the
              vehicle.
            </Text>
          </Box>

          <Alert
            status="info"
            variant="left-accent"
            borderRadius="lg"
            flexDirection="row"
            py={3}
            bg="blue.50"
            color="blue.600"
          >
            <AlertIcon color="blue.500" />
            <Text>
              This feature is in preview and you will be contacted outside of
              the app.
              <br />
              Please note that we cannot guarantee vehicle availability.
            </Text>
          </Alert>

          <HireInformation />

          <Card variant="outline" borderColor={'gray.200'}>
            <CardBody>
              <form>
                <Box mb={6}>
                  <Heading size="md" mb={4}>
                    Select Vehicle Type
                  </Heading>
                  <VehicleSelector
                    selectedVehicle={selectedVehicle}
                    onVehicleSelect={handleVehicleSelect}
                    error={errors.vehicle?.message}
                  />
                </Box>

                <ManVanBookingForm
                  control={control}
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isSuccess={createManVanMutation.isSuccess}
                  minDate={format(new Date(), 'yyyy-MM-dd')}
                  handleSubmit={handleBookingSubmit}
                />
              </form>
            </CardBody>
          </Card>
        </VStack>
      </Box>
    </Layout>
  );
};
