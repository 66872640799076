import { CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import { ButtonProps } from '@chakra-ui/react';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';
import { useDeliveryStopCompletionToggle } from '@tradeaze/frontend/hooks';
import React from 'react';
import { ButtonWithConfirmation } from '../shared';

type DeliveryStopCompletionButtonProps = {
  deliveryStop: HydratedDeliveryStop;
  size?: ButtonProps['size'];
};

export const DeliveryStopCompletionButton: React.FC<DeliveryStopCompletionButtonProps> = ({
  deliveryStop,
  size = 'sm',
}) => {
  const { isCompleted, isLoading, toggleCompletion } = useDeliveryStopCompletionToggle(
    deliveryStop
  );

  return (
    <ButtonWithConfirmation
      onClick={toggleCompletion}
      isLoading={isLoading}
      colorScheme={isCompleted ? 'red' : 'green'}
      variant='outline'
      leftIcon={isCompleted ? <RepeatIcon /> : <CheckIcon />}
      size={size}
      data-testid={`completion-toggle-${deliveryStop.deliveryStopId}`}
      onConfirm={toggleCompletion}
      alertTitle={isCompleted ? 'Undo Complete' : 'Complete Stop'}
      alertDescription={isCompleted ? 'Are you sure you want to mark this stop as incomplete?' : 'Are you sure you want to mark this stop as complete?'}
    >
      {isCompleted ? 'Mark as Incomplete' : 'Mark as Complete'}
    </ButtonWithConfirmation>
  );
}; 