import { useCallback, useMemo, useState } from 'react';
import { useGetAddresses } from '@tradeaze/frontend/hooks';
import { formatAddressDisplayName } from '@tradeaze/shared/utils';
import {
  Address,
  CreateDeliveryStop,
  MerchantAccount,
  GetLookupAddressResponse,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { resetAddressFields } from '../utils';

type UseDeliveryStop = {
  form: UseFormReturn<CreateDeliveryStop>;
  merchantAccount?: MerchantAccount;
  addressType: 'DROP_OFF' | 'PICK_UP';
  companyId?: string | null;
};

export const useDeliveryStop = ({
  form,
  merchantAccount,
  addressType,
  companyId,
}: UseDeliveryStop) => {
  const { setValue, trigger, watch } = form;

  const [showFullAddress, setShowFullAddress] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [instructionsVisibility, setInstructionsVisibility] = useState(false);

  const companyName = watch('companyName');

  const { data: paginatedSavedAddresses, isLoading: isLoadingSavedAddresses } =
    useGetAddresses({
      companyId: companyId ?? undefined,
      merchantId: merchantAccount?.merchantId,
      addressType: addressType,
    });

  const savedAddresses = useMemo(
    () =>
      paginatedSavedAddresses?.pages[0].addresses
        .map((address) => ({
          address,
          displayName: formatAddressDisplayName(address),
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [paginatedSavedAddresses],
  );

  const updateAddressFields = useCallback(
    (fields: Partial<CreateDeliveryStop>) => {
      Object.entries(fields).forEach(([key, value]) =>
        setValue(key as keyof CreateDeliveryStop, value)
      );

      trigger([
        'companyName',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'postCode',
        'city',
      ]);

      setIsManualAddress(false);
      setShowFullAddress(true);
    },
    [setValue, trigger]
  );

  const handleSelectExistingAddressCallback = useCallback(
    (address: Address) => {
      if (!address) {
        const emptyFields = {
          deliveryNotes: '',
          position: undefined,
          addressId: null,
          addressLine1: '',
          postCode: '',
          city: '',
        };
        updateAddressFields(emptyFields);
        setShowFullAddress(false);
        return;
      }

      const fields = {
        addressId: address.addressId,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2 || undefined,
        addressLine3: address.addressLine3 || undefined,
        postCode: address.postCode,
        position: address.position,
        city: address.city || undefined,
        ...(addressType === 'PICK_UP' && { companyName: address.companyName || undefined }),
      };

      updateAddressFields(fields);
      setInstructionsVisibility(!!address.instructions);
    },
    [updateAddressFields, addressType]
  );

  const handleSelectLocationCallback = useCallback(
    (addressResult: GetLookupAddressResponse) => {
      const fields = {
        position: {
          latitude: addressResult.latitude,
          longitude: addressResult.longitude,
        },
        addressId: null,
        postCode: addressResult.postcode || '',
        addressLine1: [addressResult.line_1, addressResult.line_2]
          .filter(Boolean)
          .join(', ') || '',
        city: addressResult.town_or_city || '',
      };

      updateAddressFields(fields);
    },
    [updateAddressFields]
  );

  const handleManualAddress = useCallback((value = true) => {
    setIsManualAddress(value);
    setShowFullAddress(value);
  }, []);

  const handleResetAddressFields = useCallback(() => {
    resetAddressFields(setValue);
  }, [setValue]);

  const savedAddressPlaceholder = useMemo(() => {
    if (isLoadingSavedAddresses) {
      return 'Loading addresses';
    }
    if (savedAddresses?.length) {
      if (companyName && addressType === 'DROP_OFF') {
        return `Select saved address for '${companyName}'`;
      } else {
        return 'Select saved address';
      }
    }
    if (companyName && addressType === 'DROP_OFF') {
      return `No saved addresses for '${companyName}'`;
    }
    return 'No saved addresses';
  }, [savedAddresses, isLoadingSavedAddresses, companyName, addressType]);

  return {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    isLoadingSavedAddresses,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress: handleSelectExistingAddressCallback,
    handleSelectLocation: handleSelectLocationCallback,
    handleManualAddress,
    handleResetAddressFields,
  };
};
