import { z } from 'zod';
import { PositionSchema } from '../../models';
import { postcodeValidator } from '../../utils';

export const GeocodeAddressBodySchema = z.object({
  postCode: postcodeValidator.makeSchema(),
  // Future fields to be added:
  // addressLine1: z.string().optional(),
  // addressLine2: z.string().optional(),
  // city: z.string().optional(),
});

export const GeocodeAddressResponseSchema = z.object({
  isTerminatedPostcode: z.boolean(),
  formattedAddress: z.string().optional(),
  position: PositionSchema,
});

export type GeocodeAddressBody = z.infer<typeof GeocodeAddressBodySchema>;
export type GeocodeAddressResponse = z.infer<typeof GeocodeAddressResponseSchema>; 