import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Show,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import {
  MobileNavbarButton,
  NAVBAR_BACKGROUND_COLOR,
  DesktopNavbarButton,
  NavbarCommon,
  brandPrimaryColor,
} from '@tradeaze/frontend/ui-web';
import { captureEvent } from '@tradeaze/frontend/utils';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { BsCalculatorFill } from 'react-icons/bs';
import { HiHome } from 'react-icons/hi';
import { MdManageAccounts, MdRecentActors, MdDashboard } from 'react-icons/md';
import { Location, useLocation } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthProvider';
import { TbSquareRoundedPlusFilled } from 'react-icons/tb';
import { usePricingCalculator } from '../delivery-pricing/PricingCalculatorProvider';

type NavItem = {
  label: string;
  path?: string;
  icon: React.ReactNode;
  onClick?: () => void;
  iconSize?: number;
  notificationCount?: boolean;
  isPrimaryAction?: boolean;
  dataCy: string;
};

const NAVBAR_ICON_SIZE = 24;

const getNavItems = ({
  onCreateOrder,
  onOpenDeliveryPricingModal,
}: {
  onCreateOrder?: () => void;
  onOpenDeliveryPricingModal?: () => void;
} = {}): NavItem[] => {
  return [
    {
      label: 'Create Order',
      path: '/create-order',
      icon: <TbSquareRoundedPlusFilled fontSize={NAVBAR_ICON_SIZE * 1.1} />,
      onClick: onCreateOrder,
      isPrimaryAction: true,
      dataCy: 'navbar-create-order-button',
    },
    {
      label: 'Home',
      path: '/',
      icon: <HiHome fontSize={NAVBAR_ICON_SIZE} />,
      dataCy: 'navbar-home-button',
    },
    {
      label: 'Orders',
      path: '/orders',
      icon: <MdDashboard fontSize={NAVBAR_ICON_SIZE * 1.1} />,
      dataCy: 'navbar-order-dashboard-button',
    },
    {
      label: 'Address Book',
      path: '/address-book',
      icon: <MdRecentActors fontSize={NAVBAR_ICON_SIZE * 1.1} />,
      dataCy: 'navbar-address-book-button',
    },
    {
      label: 'Delivery Price',
      icon: <BsCalculatorFill fontSize={NAVBAR_ICON_SIZE * 1} />,
      onClick: onOpenDeliveryPricingModal,
      dataCy: 'navbar-delivery-price-button',
    },
    {
      label: 'Account',
      path: '/account',
      icon: <MdManageAccounts fontSize={NAVBAR_ICON_SIZE * 1.2} />,
      dataCy: 'navbar-account-button',
    },
  ];
};

const MerchantNavbarDesktopContent: React.FunctionComponent<{
  onCreateOrder: () => void;
  onOpenDeliveryPricingModal: () => void;
  showLabel: boolean;
  handleCloseExpanded: () => void;
}> = ({
  onCreateOrder,
  onOpenDeliveryPricingModal,
  showLabel,
  handleCloseExpanded,
}) => {
  const location = useLocation();
  const navItems = getNavItems({ onCreateOrder, onOpenDeliveryPricingModal });

  const nonPrimaryActions = navItems.filter((item) => !item.isPrimaryAction);

  const primaryAction = navItems.find((item) => item.isPrimaryAction);

  return (
    <VStack w="100%" alignItems="center">
      {primaryAction && (
        <DesktopNavbarButton
          key={primaryAction.label}
          label={primaryAction.label}
          to={primaryAction.path}
          onClick={() => {
            primaryAction.onClick?.();
            handleCloseExpanded();
          }}
          color={brandPrimaryColor}
          icon={primaryAction.icon}
          buttonProps={{ mb: 6 }}
          showLabel={showLabel}
          isActive={
            primaryAction.path
              ? getIsActive(primaryAction.path, location)
              : false
          }
          dataCy={primaryAction.dataCy}
        />
      )}
      {nonPrimaryActions.map((item) => (
        <DesktopNavbarButton
          key={item.label}
          label={item.label}
          to={item.path}
          onClick={() => {
            item.onClick?.();
            handleCloseExpanded();
          }}
          icon={item.icon}
          isActive={
            item.path === '/'
              ? location.pathname === item.path
              : item.path
              ? location.pathname.startsWith(item.path)
              : false
          }
          notificationCount={item.notificationCount}
          showLabel={showLabel}
          dataCy={item.dataCy}
        />
      ))}
    </VStack>
  );
};

const getIsActive = (path: string, location: Location) => {
  return path === '/'
    ? location.pathname === path
    : path
    ? location.pathname.startsWith(path)
    : false;
};

const MerchantNavbarMobileContent: React.FunctionComponent<{
  onOpenDeliveryPricingModal: () => void;
  onCreateOrder: () => void;
  onClose: () => void;
  isOpen: boolean;
}> = ({
  onOpenDeliveryPricingModal,
  onCreateOrder,
  onClose,
  isOpen,
}) => {
  const navItems = getNavItems({ onCreateOrder, onOpenDeliveryPricingModal });
  const location = useLocation();

  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg={NAVBAR_BACKGROUND_COLOR}>
        <DrawerHeader color={'white'}>Menu</DrawerHeader>
        <DrawerBody
          h="100%"
          py={8}
          justifyContent={'center'}
          flexDirection="column"
        >
          <Stack spacing={8}>
            {navItems.map((item) => (
              <MobileNavbarButton
                key={item.label}
                label={item.label}
                icon={item.icon}
                isActive={item.path ? getIsActive(item.path, location) : false}
                notificationCount={item.notificationCount}
                to={item.path}
                onClick={() => {
                  onClose();
                  item.onClick?.();
                }}
                dataCy={item.dataCy}
              />
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const MobileNavbarMenuButton: React.FunctionComponent<{
  onClick: () => void;
}> = ({ onClick }) => (
  <DesktopNavbarButton
    label="Menu"
    onClick={onClick}
    icon={<HamburgerIcon fontSize={'3xl'} />}
    color={'white'}
    disableTooltip
    dataCy="navbar-menu-button"
  />
);

export const MerchantNavbar: React.FunctionComponent = () => {
  const posthog = usePostHog();
  const location = useLocation();

  const { cognitoUserId } = useAuthContext();

  const { data: merchantAccount } = useGetMerchantAccount(cognitoUserId);

  const disableFullNavbarPathnames = [
    '/signin',
    '/signup',
    '/confirm-signup',
    '/create-account',
  ];

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpanded = () => setIsExpanded(!isExpanded);

  const handleCloseExpanded = () => setIsExpanded(false);

  const hideFullNavbar =
    disableFullNavbarPathnames.includes(location.pathname) || !merchantAccount;

  const priceCalculatorContext = usePricingCalculator();

  const mobileMenuDisclosure = useDisclosure();

  const onOpenDeliveryPricingModal = () => {
    priceCalculatorContext.openDeliveryPricingModal();
    mobileMenuDisclosure.onClose();
  };

  const onCreateOrder = () => {
    captureEvent(posthog)('start_create_order');
  };

  return (
    <NavbarCommon
      hideFullNavbar={hideFullNavbar}
      isExpanded={isExpanded}
      handleToggleExpanded={handleToggleExpanded}
    >
      <Show above="md">
        <MerchantNavbarDesktopContent
          onCreateOrder={onCreateOrder}
          onOpenDeliveryPricingModal={onOpenDeliveryPricingModal}
          showLabel={isExpanded}
          handleCloseExpanded={handleCloseExpanded}
        />
      </Show>
      {/* ON MOBILE */}
      <Show below="md">
        <MobileNavbarMenuButton onClick={mobileMenuDisclosure.onOpen} />
        <MerchantNavbarMobileContent
          onCreateOrder={onCreateOrder}
          onOpenDeliveryPricingModal={onOpenDeliveryPricingModal}
          isOpen={mobileMenuDisclosure.isOpen}
          onClose={mobileMenuDisclosure.onClose}
        />
      </Show>
    </NavbarCommon>
  );
};
