import {
  DeliveryScheduleWithStops,
  HydratedDelivery,
  HydratedDeliveryStop,
} from '@tradeaze-packages/schemas';
import {
  useGetTotalTimeEstimate,
  useOptimiseSchedule,
} from '@tradeaze/frontend/hooks';
import {
  getApiErrorMessage,
  getAssignStartDateTime,
  getIsDropOffBeforePickup,
} from '@tradeaze/shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDebouncedStops } from '../../hooks/useDebouncedStops';
import { sortStopsToSuggested } from '../utils/sortStopsToSuggested';

export const useDeliverySchedule = ({
  riderId,
  schedule,
  deliveryToAssign,
  suggestedStopOrder,
  isSuggestedRider,
  handleSaveStopOrder,
  handleChangeMarkerIndices,
  handleScheduleAssign,
}: {
  riderId: string;
  schedule?: DeliveryScheduleWithStops;
  deliveryToAssign?: HydratedDelivery | null;
  suggestedStopOrder?: string[];
  isSuggestedRider?: boolean;
  handleSaveStopOrder?: (stopIds: string[]) => void;
  handleChangeMarkerIndices: (stopIds: string[]) => void;
  handleScheduleAssign?: (riderId: string, stopsOrder: string[]) => void;
}) => {
  const stops = useMemo(
    () => schedule?.deliveryStops || [],
    [schedule?.deliveryStops],
  );

  // todo - actually send back a hydrated delivery stop from the backend
  const stopsToAssign: HydratedDeliveryStop[] | undefined = deliveryToAssign
    ? [deliveryToAssign.pickup, deliveryToAssign.dropOff].map((s) => ({
        ...s,
        status: deliveryToAssign.status,
        deliveryItems: deliveryToAssign.deliveryItems,
        deliveryOptionId: deliveryToAssign.deliveryOptionId,
        deliveryVehicleId: deliveryToAssign.deliveryVehicleId,
        delivery: deliveryToAssign,
      }))
    : undefined;

  const defaultStops: HydratedDeliveryStop[] = useMemo(() => {
    const stopsToAssignWithoutExistingStops =
      stopsToAssign?.filter(
        (sta) => !stops.some((s) => s.deliveryStopId === sta.deliveryStopId),
      ) || [];

    const allStops = [...stops, ...stopsToAssignWithoutExistingStops];

    if (!isSuggestedRider || !suggestedStopOrder) {
      return allStops;
    }

    const suggestedStops = sortStopsToSuggested(allStops, suggestedStopOrder);

    return suggestedStops;
  }, [stops, stopsToAssign, suggestedStopOrder, isSuggestedRider]);

  const assignedRiderId = deliveryToAssign?.riderId;

  const riderIsAlreadyAssigned = assignedRiderId && assignedRiderId === riderId;

  const [orderedStops, setOrderedStops] = useState(defaultStops);

  useEffect(() => {
    // If the number of stops has changed, refresh the state
    if (defaultStops.length !== orderedStops.length) {
      setOrderedStops(defaultStops);
      return;
    }

    // Otherwise, update existing stops in place
    setOrderedStops((currentOrderedStops) =>
      currentOrderedStops.map((orderedStop) => {
        const updatedStop = defaultStops.find(
          (s) => s.deliveryStopId === orderedStop.deliveryStopId,
        );
        return updatedStop || orderedStop;
      }),
    );
  // stringify to compare values not references, avoiding infinite loops
  }, [JSON.stringify(defaultStops), JSON.stringify(orderedStops)]);

  const optimiseMutation = useOptimiseSchedule({
    onError: (error) => toast.error(getApiErrorMessage(error)),
    onSuccess: () => toast.success('Optimised'),
  });

  const handleOptimiseSchedule = async () => {
    const { deliveryStopIds } = await optimiseMutation.mutateAsync({
      startTime: getAssignStartDateTime(orderedStops).toISOString(),
      riderId,
      deliveryId: deliveryToAssign?.deliveryId,
    });
    setOrderedStops(sortStopsToSuggested(orderedStops, deliveryStopIds));
  };

  const handleCancelChanges = () => {
    setOrderedStops(defaultStops);
  };

  const handleReorder = (orderedStops: HydratedDeliveryStop[]) => {
    setOrderedStops(orderedStops);
  };

  const handleSave = () => {
    if (!deliveryToAssign || riderIsAlreadyAssigned) {
      handleSaveStopOrder?.(orderedStops.map((stop) => stop.deliveryStopId));
      return;
    }

    handleScheduleAssign?.(
      riderId,
      orderedStops.map((stop) => stop.deliveryStopId),
    );
  };

  useEffect(() => {
    handleChangeMarkerIndices(orderedStops.map((stop) => stop.deliveryStopId));
  }, [orderedStops, handleChangeMarkerIndices]);

  const isDropOffBeforePickup = useMemo(
    () => getIsDropOffBeforePickup(orderedStops),
    [orderedStops],
  );

  const isOrderChanged = useMemo(() => {
    return !orderedStops.every(
      (stop, index) =>
        stop.deliveryStopId === defaultStops[index]?.deliveryStopId,
    );
  }, [defaultStops, orderedStops]);

  const canSave =
    !isDropOffBeforePickup &&
    ((stopsToAssign && assignedRiderId !== riderId) || isOrderChanged);

  const debouncedOrderedStops = useDebouncedStops(orderedStops);

  const totalTimeQuery = useGetTotalTimeEstimate(
    {
      type: 'STOPS',
      stopIds: debouncedOrderedStops.map((stop) => stop.deliveryStopId),
      riderId,
      trafficAware: true,
    },
    {
      enabled: Boolean(debouncedOrderedStops.length) && !isDropOffBeforePickup,
    },
  );

  const hasStops = orderedStops.length > 0;

  return {
    hasStops,
    totalTimeQuery,
    orderedStops,
    handleOptimiseSchedule,
    optimiseMutation,
    canSave,
    handleSave,
    handleCancelChanges,
    isDropOffBeforePickup,
    handleReorder,
    stopsToAssign,
  };
};
