import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { ZodError } from 'zod';
import { ApiError } from '@tradeaze-packages/schemas';
import { AxiosError } from 'axios';

/**
 * Type guard for API errors
 */
export const isApiError = (error: unknown): error is ApiError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    'status' in error &&
    typeof (error as ApiError).error?.message === 'string'
  );
};

/**
 * Type guard for Axios errors
 */
export const isAxiosError = (error: unknown): error is AxiosError => {
  return Boolean(error && typeof error === 'object' && 'isAxiosError' in error && error.isAxiosError === true);
};

/**
 * Formats Zod validation errors into a user-friendly message
 */
export const formatZodError = (error: ZodError): string => {
  const { formErrors, fieldErrors } = error.flatten();
  
  // Handle form-level errors
  if (formErrors.length > 0) {
    return formErrors.join('; ');
  }
  
  // Handle field-specific errors
  const errorMessages = Object.entries(fieldErrors)
    .map(([field, errors]) => {
      if (!errors || errors.length === 0) return null;
      return `${field}: ${errors.join(', ')}`;
    })
    .filter(Boolean);
  
  return errorMessages.length > 0 
    ? errorMessages.join('; ')
    : 'Validation failed';
};

/**
 * Extracts a user-friendly error message from various error types
 */
export const extractErrorMessage = (error: unknown): string => {
  // Handle Zod validation errors
  if (error instanceof ZodError) {
    return formatZodError(error);
  }

  // Handle API errors
  if (isApiError(error)) {
    return `${error.error.message} (${error.status})`;
  }

  // Handle Axios errors
  if (isAxiosError(error)) {
    const response = error.response;
    if (response) {
      const message = (response.data as { error?: { message: string } })?.error?.message || response.statusText;
      return `${message} (${response.status})`;
    }
    return error.message || 'Network error occurred';
  }

  // Handle standard Error objects
  if (error instanceof Error) {
    return error.message;
  }

  // Handle object-like errors
  if (error && typeof error === 'object') {
    if ('message' in error && typeof error.message === 'string') {
      return error.message;
    }
    return 'Unknown error';
  }

  return 'Unknown error';
};

/**
 * Extracts structured error data for logging and debugging
 */
export const extractErrorData = (
  error: unknown,
  metadata?: Record<string, unknown>
): Record<string, unknown> => {
  const baseData = {
    timestamp: new Date().toISOString(),
    ...metadata,
  };

  // Handle Zod validation errors
  if (error instanceof ZodError) {
    return {
      ...baseData,
      type: 'ZodError',
      issues: error.issues,
      formattedErrors: error.flatten(),
    };
  }

  // Handle API errors
  if (isApiError(error)) {
    return {
      ...baseData,
      type: 'ApiError',
      status: error.status,
      errorData: error.error.data,
    };
  }

  // Handle Axios errors
  if (isAxiosError(error)) {
    // Remove sensitive headers
    const safeHeaders = { ...error.config?.headers };
    delete safeHeaders['Authorization'];
    delete safeHeaders['Cookie'];
    
    return {
      ...baseData,
      type: 'AxiosError',
      status: error.response?.status,
      data: error.response?.data,
      url: error.config?.url,
      method: error.config?.method,
      headers: safeHeaders,
    };
  }

  // Handle standard Error objects
  if (error instanceof Error) {
    return {
      ...baseData,
      type: error.constructor.name,
      message: error.message,
      stack: error.stack,
    };
  }

  // Handle unknown errors
  return {
    ...baseData,
    type: 'Unknown',
    error,
  };
};

/**
 * Options for error handling
 */
export interface ErrorHandlerOptions {
  sendToSentry?: boolean;
  consoleLog?: boolean;
  showNotification?: boolean;
  notificationMessage?: string;
  sentryContext?: Record<string, unknown>;
}

/**
 * Main error handler that processes errors and executes appropriate actions
 */
export const handleError = (
  error: unknown,
  options: ErrorHandlerOptions = {}
): void => {
  const {
    sendToSentry = true,
    consoleLog = true,
    showNotification = true,
    notificationMessage,
    sentryContext,
  } = options;

  const errorMessage = extractErrorMessage(error);
  const errorData = extractErrorData(error, sentryContext);

  // Handle Sentry reporting
  if (sendToSentry) {
    const sentryError = error instanceof Error ? error : new Error(errorMessage);
    Sentry.captureException(sentryError, {
      extra: errorData,
    });
  }

  // Handle console logging
  if (consoleLog) {
    if (error instanceof ZodError) {
      console.error('Validation Error:', error.flatten());
    } else {
      console.error('Error:', error);
      console.error('Error Data:', errorData);
    }
  }

  // Handle notification
  if (showNotification) {
    toast.error(notificationMessage || errorMessage);
  }
};