import { z } from 'zod';
import { EventTypeSchema } from './event-types';
import { DeliveryVehicleIdSchema } from '../models/delivery-vehicle-schema';
import { DropoffDeliveryStopSchema } from '../models/delivery-stop-schema';

/**
 * SNS Message
 * @description
 * This is the data that is sent to the SNS topics
 */

/**
 * Determines which subscriptions are triggered
 */
const CommonSnsSchema = z.object({
  email: z.boolean().optional(),
  webhook: z.boolean().optional(),
  push: z.boolean().optional(),
  create: z.boolean().optional(),
  sms: z.boolean().optional(),
  eta: z.boolean().optional(),
  slack: z.boolean().optional(),
});

const OrderActionedSnsSchema = z.object({
  orderId: z.string(),
});

const BookingApprovalRequiredSnsSchema = z.object({
  orderId: z.string(),
});

const BookingApprovalActionedSnsSchema = z.object({
  bookingApprovalId: z.string(),
});

const GigJobCreatedActionedSnsSchema = z.object({
  gigJobId: z.string(),
  deliveryIds: z.string().array(),
  deliveryVehicleIds: DeliveryVehicleIdSchema.array(),
});

const DeliveryStopActionedSnsSchema = z.object({
  deliveryStopId: z.string(),
});

const DeliveryAssignedSnsDataSchema = z.object({
  deliveryId: z.string(),
  riderId: z.string(),
});

const DeliveryUnassignedSnsDataSchema = DeliveryAssignedSnsDataSchema;

const DeliveryActionedSnsSchema = z.object({
  deliveryId: z.string(),
});

const ScheduleUpdatedSnsDataSchema = z.object({
  deliveryScheduleId: z.string(),
});

const FleetVehicleActionedSnsDataSchema = z.object({
  ownerId: z.string(),
  regNumber: z.string(),
});

const RiderActionedSnsDataSchema = z.object({
  riderId: z.string(),
});

const DeliveryAssignedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_ASSIGNED),
  data: DeliveryAssignedSnsDataSchema,
});

const DeliveryUnassignedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_UNASSIGNED),
  data: DeliveryUnassignedSnsDataSchema,
});

const DeliveryCancelledSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryConfirmedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CONFIRMED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_COMPLETE),
  data: DeliveryActionedSnsSchema,
});

const OrderCreatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionedSnsSchema,
});

const OrderConfirmedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionedSnsSchema,
});

const OrderCancelledSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionedSnsSchema,
});
const DeliveryCancelledExternallySnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.EXTERNAL_JOB_CANCELLED_EXTERNALLY),
  data: DeliveryActionedSnsSchema,
});

const OrderRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionedSnsSchema,
});

const OrderUpdatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionedSnsSchema,
});

const OrderCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_COMPLETE),
  data: OrderActionedSnsSchema,
});

const PickupEnrouteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_ENROUTE),
  data: DeliveryStopActionedSnsSchema,
});

const PickupArrivedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_ARRIVED),
  data: DeliveryStopActionedSnsSchema,
});

const PickupCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_COMPLETE),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffEnrouteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_ENROUTE),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffArrivedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_ARRIVED),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_COMPLETE),
  data: DeliveryStopActionedSnsSchema,
});

const ScheduleUpdatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.SCHEDULE_UPDATED),
  data: ScheduleUpdatedSnsDataSchema,
});

const FleetVehicleRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_REJECTED),
  data: FleetVehicleActionedSnsDataSchema,
});

const FleetVehicleApprovedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_APPROVED),
  data: FleetVehicleActionedSnsDataSchema,
});

const RiderRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.RIDER_REJECTED),
  data: RiderActionedSnsDataSchema,
});

const RiderApprovedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.RIDER_APPROVED),
  data: RiderActionedSnsDataSchema,
});

const GigJobCreatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.GIG_JOB_CREATED),
  data: GigJobCreatedActionedSnsSchema,
});

const BookingApprovalRequiredSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.BOOKING_APPROVAL_REQUIRED),
  data: BookingApprovalRequiredSnsSchema,
});

const BookingApprovedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.BOOKING_APPROVED),
  data: BookingApprovalActionedSnsSchema,
});

const BookingRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.BOOKING_REJECTED),
  data: BookingApprovalActionedSnsSchema,
});

export const SnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema,
  ScheduleUpdatedSnsMessageSchema,
  OrderConfirmedSnsMessageSchema,
  OrderCancelledSnsMessageSchema,
  OrderRejectedSnsMessageSchema,
  OrderUpdatedSnsMessageSchema,
  OrderCompleteSnsMessageSchema,
  PickupEnrouteSnsMessageSchema,
  PickupArrivedSnsMessageSchema,
  PickupCompleteSnsMessageSchema,
  DropoffEnrouteSnsMessageSchema,
  DropoffArrivedSnsMessageSchema,
  DropoffCompleteSnsMessageSchema,
  DeliveryAssignedSnsMessageSchema,
  DeliveryUnassignedSnsMessageSchema,
  DeliveryCancelledSnsMessageSchema,
  DeliveryConfirmedSnsMessageSchema,
  DeliveryRejectedSnsMessageSchema,
  DeliveryCompleteSnsMessageSchema,
  FleetVehicleApprovedSnsMessageSchema,
  FleetVehicleRejectedSnsMessageSchema,
  RiderApprovedSnsMessageSchema,
  RiderRejectedSnsMessageSchema,
  GigJobCreatedSnsMessageSchema,
  DeliveryCancelledExternallySnsMessageSchema,
  BookingApprovalRequiredSnsMessageSchema,
  BookingApprovedSnsMessageSchema,
  BookingRejectedSnsMessageSchema,
]);

export const WebhookSnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderConfirmedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderCancelledSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderRejectedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderUpdatedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupEnrouteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupArrivedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupCompleteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffEnrouteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffArrivedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffCompleteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
]);

export const EtaSnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  PickupArrivedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  PickupCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DropoffArrivedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DropoffCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  ScheduleUpdatedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryAssignedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryUnassignedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCancelledSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryRejectedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderCancelledSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderRejectedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCancelledExternallySnsMessageSchema.extend({
    eta: z.literal(true),
  }),
]);

export const SlackSnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema.extend({
    slack: z.literal(true),
  })
]);

// Export SNS base schemas
export type OrderActionedSns = z.infer<typeof OrderActionedSnsSchema>;
export type GigJobCreatedActionedSns = z.infer<typeof GigJobCreatedActionedSnsSchema>;
export type DeliveryActionedSns = z.infer<typeof DeliveryActionedSnsSchema>;
export type DeliveryAssignedSns = z.infer<typeof DeliveryAssignedSnsDataSchema>;
export type BookingApprovalRequiredSns = z.infer<typeof BookingApprovalRequiredSnsSchema>;
export type BookingApprovalActionedSns = z.infer<typeof BookingApprovalActionedSnsSchema>;
export type ScheduleUpdatedSns = z.infer<typeof ScheduleUpdatedSnsDataSchema>;
export type SnsEventMessage = z.infer<typeof SnsEventMessageSchema>;
export type WebhookSnsEventMessage = z.infer<typeof WebhookSnsEventMessageSchema>;
export type EtaSnsEventMessage = z.infer<typeof EtaSnsEventMessageSchema>;
export type SlackSnsEventMessage = z.infer<typeof SlackSnsEventMessageSchema>;
export type FleetVehicleActionedSns = z.infer<typeof FleetVehicleActionedSnsDataSchema>;
export type RiderActionedSns = z.infer<typeof RiderActionedSnsDataSchema>;
export type DeliveryStopActionedSns = z.infer<typeof DeliveryStopActionedSnsSchema>; 