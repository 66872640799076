import { create } from 'zustand';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

type User = {
  username: string;
  attributes?: {
    sub: string;
    email?: string;
  };
};

type AuthStoreType = {
  user: User | null;
  loading: boolean;
  error: unknown | null;
  hasSessionExpired: boolean;
  signIn: (
    username: string,
    password: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<User>;
  checkAuthSession: (
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<User>;
  signOut: (
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  sessionExpired: (
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  signUp: (
    username: string,
    email: string,
    password: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  confirmSignUp: (
    username: string,
    code: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  resendConfirmationCode: (
    username: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  forgotPassword: (
    username: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string,
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) => Promise<void>;
};

const handleError = (error: Error) => {
  Sentry.captureException(error);
  console.error(error);
};

// Unlike react hooks, this can be used outside of react components if accessed via useAuthStore.getState()
export const useAuthStore = create<AuthStoreType>()((set) => ({
  loading: true,
  user: null,
  error: null,
  hasSessionExpired: false,
  signIn: async (username, password, onSuccess, onError) => {
    try {
      set({ loading: true });
      const user = await Auth.signIn(username, password);
      set({ user, hasSessionExpired: false });
      onSuccess?.();
      return user;
    } catch (error) {
      set({ user: null, error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  checkAuthSession: async (onSuccess, onError) => {
    try {
      set({ loading: true });
      const user = await Auth.currentAuthenticatedUser();
      set({ user, hasSessionExpired: false });
      onSuccess?.();
      return user;
    } catch (error) {
      set({ user: null, error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  signOut: async (onSuccess, onError) => {
    try {
      set({ loading: true });
      await Auth.signOut();
      set({ user: null });
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  sessionExpired: async (onSuccess, onError) => {
    try {
      set({ loading: true, hasSessionExpired: true });
      await Auth.signOut();
      set({ user: null });
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  signUp: async (username, email, password, onSuccess, onError) => {
    try {
      set({ loading: true });
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  confirmSignUp: async (username, code, onSuccess, onError) => {
    try {
      set({ loading: true });
      await Auth.confirmSignUp(username, code);
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  resendConfirmationCode: async (username, onSuccess, onError) => {
    try {
      set({ loading: true });
      await Auth.resendSignUp(username);
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  forgotPassword: async (username, onSuccess, onError) => {
    try {
      set({ loading: true });
      await Auth.forgotPassword(username);
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
  forgotPasswordSubmit: async (
    username,
    code,
    password,
    onSuccess,
    onError
  ) => {
    try {
      set({ loading: true });
      await Auth.forgotPasswordSubmit(username, code, password);
      onSuccess?.();
    } catch (error) {
      set({ error });
      handleError(error);
      onError?.(error);
    } finally {
      set({ loading: false });
    }
  },
}));
