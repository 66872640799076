import React, { useContext } from "react";

export type FormAttributeAlertConfig = {
  message: string;
  logo?: { src: string; alt?: string };
};

export type FormAttributeConfig = {
  alert?: FormAttributeAlertConfig;
};

export const FormConfigContext = React.createContext<{
  getFormAttributeConfig?: (fieldName: string) => FormAttributeConfig | null;
}>({});

export const useFormConfig = (fieldName: string): FormAttributeConfig | null => {
  const context = useContext(FormConfigContext);
  return context.getFormAttributeConfig?.(fieldName) ?? null;
};