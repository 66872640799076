import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { VscDebugStart } from 'react-icons/vsc';
import { MarkerContainer } from './MarkerContainer';

export const PickupMarker: React.FC<{
  stopSequence?: number;
  isCompleted?: boolean;
}> = ({ stopSequence, isCompleted }) => {
  return (
    <MarkerContainer
      tooltipText={`Pick Up${
        stopSequence !== undefined ? ` - ${stopSequence + 1}` : ''
      }`}
      tooltipIcon={<VscDebugStart size={20} color={'#2B6CB0'} />}
      showTooltip={true}
      mode={isCompleted ? 'faded' : 'normal'}
    >
      <FaMapMarkerAlt size={34} color={'#2B6CB0'} />
    </MarkerContainer>
  );
};
