import { z } from 'zod';
import { DeliveriesQueryParamsSchema } from '../../../models';

export const DownloadMerchantDeliveriesCsvQuerySchema =
  DeliveriesQueryParamsSchema.omit({
    startIndex: true,
    limit: true,
    merchantId: true,
    merchantIds: true,
  })

export type DownloadMerchantDeliveriesCsvQuery = z.infer<
  typeof DownloadMerchantDeliveriesCsvQuerySchema
>;

export const DownloadMerchantDeliveriesCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadMerchantDeliveriesCsvResponse = z.infer<
  typeof DownloadMerchantDeliveriesCsvResponseSchema
>;

export type MerchantDeliveriesCsvColumns = {
    'Merchant Name': string
    'Delivery ID': string
    'Order ID': string
    'Purchase Order Reference': string
    'Order Delivery Price': number
    'Order Service Charge': number
    'Order Total Price': number
    'Order Cancellation Fee': number
    'Order Status': string
    'Order Type': string
    'Delivery Status': string
    'Delivery Vehicle': string
    'Delivery Option': string
    'Delivery Window Start': string
    'Delivery Window End': string
    'Items Value': number
    'Pickup Company Name': string
    'Collection Reference': string
    'Pickup Address': string
    'Pickup Postcode': string
    'Dropoff Company Name': string
    'Dropoff Address': string
    'Dropoff Postcode': string
    'Dropoff Contact Name': string
    'Delivery Contact Number': string
    'Created At': string
    'Completed At': string
    'Cancelled At': string
    'Cancellation Reason': string
  }
  