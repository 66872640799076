import { Box, Center, HStack, Text } from '@chakra-ui/react';
import { QueryObserverResult } from '@tanstack/react-query';
import {
  GetTotalTimeEstimateResponse,
  HydratedDeliveryStop,
} from '@tradeaze-packages/schemas';
import { secondsToDurationText } from '@tradeaze/shared/utils';
import { Reorder } from 'framer-motion';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { DeliveryStopCard } from '../DeliveryStopCard';

export const DeliveryScheduleList: React.FC<{
  hasStops: boolean;
  totalTimeQuery: QueryObserverResult<GetTotalTimeEstimateResponse>;
  orderedStops: HydratedDeliveryStop[];
  handleReorder: (orderedStops: HydratedDeliveryStop[]) => void;
  stopsToAssign?: HydratedDeliveryStop[];
  riderId: string;
}> = ({
  hasStops,
  totalTimeQuery,
  orderedStops,
  handleReorder,
  stopsToAssign,
  riderId,
}) => {
  if (!hasStops) return null;

  return (
    <Box mt={-3}>
      {/* Negative margin to offset the top margin of the DeliveryStopCard */}
      <Reorder.Group
        style={{
          listStyle: 'none',
          touchAction: 'none',
        }}
        axis="y"
        values={orderedStops}
        onReorder={handleReorder}
      >
        {orderedStops.map((stop, i) => {
          const thisStopEtas =
            totalTimeQuery.data?.stopEtas?.[stop.deliveryStopId];

          const nextStopEtas =
            totalTimeQuery.data?.stopEtas?.[
              orderedStops[i + 1]?.deliveryStopId
            ];

          const stopEtaStart = thisStopEtas?.etaStart;
          const stopWaitTime = thisStopEtas?.waitTime;
          const durationToNextStop = nextStopEtas?.durationFromPreviousStop;

          return (
            <Reorder.Item key={stop.deliveryStopId} value={stop}>
              <Box>
                <HStack>
                  <Box w={5}>
                    <Text fontWeight={'bold'}>{i + 1}</Text>
                  </Box>
                  <Box flexGrow={1}>
                    <DeliveryStopCard
                      stop={stop}
                      eta={stopEtaStart}
                      waitTime={stopWaitTime}
                      isStopToAssign={
                        stopsToAssign?.some(
                          (s) => s.deliveryStopId === stop.deliveryStopId,
                        ) ?? false
                      }
                      grabCursor={true}
                      currentScheduleRiderId={riderId}
                    />
                  </Box>
                </HStack>
                {i < orderedStops.length - 1 ? (
                  <Center>
                    <AiOutlineArrowDown />
                    {durationToNextStop ? (
                      <Text fontSize={'sm'} ml={2}>
                        {secondsToDurationText(durationToNextStop)}
                      </Text>
                    ) : null}
                  </Center>
                ) : null}
              </Box>
            </Reorder.Item>
          );
        })}
      </Reorder.Group>
    </Box>
  );
};
