import { format } from 'date-fns';
import { z } from 'zod';
import { validateUkPhoneNumber, postcodeValidator } from '../../../utils';

export const HiabRequestBodySchema = z.object({
  pickupPostcode: postcodeValidator.makeSchema({
    required: 'Pickup postcode is required',
  }),
  dropoffPostcode: postcodeValidator.makeSchema({
    required: 'Delivery postcode is required',
  }),
  weight: z
    .string()
    .min(1, 'Weight is required')
    .regex(/^\d+$/, 'Please enter a valid number')
    .refine((val) => parseInt(val) > 0, 'Weight must be greater than 0'),
  date: z
    .string()
    .min(1, 'Date is required')
    .refine(
      (val) => new Date(val) >= new Date(format(new Date(), 'yyyy-MM-dd')),
      'Date must be today or in the future',
    ),
  bookedBy: z
    .string()
    .min(1, 'Name is required')
    .max(50, 'Name must be less than 50 characters'),
  merchantName: z.string().min(1, 'Merchant name is required'),
  contactNumber: z.string().refine((value) => validateUkPhoneNumber(value), {
    message: 'Invalid UK phone number',
  }),
});

export const HiabRequestResponseSchema = HiabRequestBodySchema;

export type HiabRequestBody = z.infer<typeof HiabRequestBodySchema>;
export type HiabRequestResponse = z.infer<typeof HiabRequestResponseSchema>;