import { GrLocation } from 'react-icons/gr';
import { Box, Divider, Flex, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import {
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import {
  DriverInstructionSection,
  ManualAddressSection,
  PodSection,
  PostCodeSection,
  SaveAddressButton,
  SavedAddressSection,
} from '../common-sections';
import { Attribute, OrDivider, TextValue } from '../../../shared';
import { MerchantConfigKey } from "../../../merchant/config/merchantConfig";
import { usePickupSection } from '../../hooks';
import { UseFormReturn } from 'react-hook-form';
import { FormAttribute } from '../../../form';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { OrderSection } from '../../order-section';
import { MerchantFormConfigProvider } from '../../../form/MerchantFormConfigProvider';

type PickupSectionProps = {
  sectionName: string;
  defaultPickupAddress?: string;
  defaultPickupPostcode?: string;
  defaultPickupCity?: string;
  defaultPickupPosition?: { latitude: number; longitude: number } | null;
  merchantAccount?: MerchantAccount;
  showExpanded?: boolean;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  isSectionComplete: boolean;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
  isSecondPickup?: boolean;
  restrictedEditing?: boolean;
  cypressTestId: string;
  stopId: number;
  isMultiDrop?: boolean;
  merchantConfigKey?: MerchantConfigKey | null;
};

export const PickupSection = ({
  sectionName,
  defaultPickupAddress,
  defaultPickupPostcode,
  defaultPickupPosition,
  defaultPickupCity,
  merchantAccount,
  showExpanded,
  stopSequence,
  form,
  isSectionComplete,
  addMarker,
  removeMarker,
  isSecondPickup,
  restrictedEditing,
  cypressTestId,
  stopId,
  isMultiDrop = false,
  merchantConfigKey,
}: PickupSectionProps) => {
  const {
    shouldUseDefaultPickup,
    instructionsVisibility,
    setInstructionsVisibility,
    handleToggleDefaultPickup,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    showFullAddress,
    isManualAddress,
    isLoadingSavedAddresses,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress,
    showPickupReference,
    handleTogglePickupReference,
    handleDeleteAddress,
    geocodeData,
    geocodeError,
  } = usePickupSection({
    merchantAccount,
    showExpanded,
    defaultPickupPosition,
    defaultPickupAddress,
    defaultPickupPostcode,
    defaultPickupCity,
    stopSequence,
    form,
    addMarker,
    removeMarker,
    isSecondPickup,
    stopId,
    isMultiDrop,
  });
  return (
    <MerchantFormConfigProvider formName="createPickupStop" merchantConfigKey={merchantConfigKey}>
      <OrderSection
        name={sectionName}
        icon={<GrLocation />}
        isComplete={isSectionComplete}
      >
        {restrictedEditing ? (
          <Box>
            {form.getValues('companyName') ? (
              <Attribute name={'Company Name'}>
                <TextValue text={form.getValues('companyName')} />
              </Attribute>
            ) : null}
            <Attribute name={'Address'}>
              <TextValue text={form.getValues('addressLine1')} />
            </Attribute>
            <Attribute name={'Postcode'}>
              <TextValue text={form.getValues('postCode')} />
            </Attribute>
            <Attribute name={'City'}>
              <TextValue text={form.getValues('city')} />
            </Attribute>
          </Box>
        ) : null}

        {!restrictedEditing && defaultPickupAddress && defaultPickupPostcode ? (
          <Flex align={'center'} gap={4}>
            <Switch
              ml={4}
              defaultChecked={shouldUseDefaultPickup}
              onChange={handleToggleDefaultPickup}
              data-cy={'default-pickup'}
            />
            <Text>
              Use default pick up address - {defaultPickupAddress},{' '}
              {defaultPickupPostcode}
            </Text>
          </Flex>
        ) : null}
        <Box my={4}>
          {!restrictedEditing && !shouldUseDefaultPickup ? (
            <>
              {merchantAccount ? (
                <>
                  <Box my={6}>
                    <SavedAddressSection
                      savedAddressPlaceholder={savedAddressPlaceholder}
                      savedAddresses={savedAddresses}
                      handleSelectExistingAddress={handleSelectExistingAddress}
                      isLoadingSavedAddresses={isLoadingSavedAddresses}
                      handleDeleteAddress={handleDeleteAddress}
                    />
                  </Box>
                  <OrDivider my={6} />
                </>
              ) : null}
              <Box my={6}>
                <PostCodeSection
                  handleSelectLocation={handleSelectLocation}
                  form={form}
                  cypressTestId={cypressTestId}
                  manualAddressFieldsVisible={showExpanded || showFullAddress}
                />
                <ManualAddressSection
                  handleManualAddress={handleManualAddress}
                  showFullAddress={showExpanded || showFullAddress}
                  isManualAddress={isManualAddress}
                  form={form}
                  cypressTestId={cypressTestId}
                  geocodeData={geocodeData ?? null}
                  geocodeError={geocodeError ?? null}
                />
              </Box>
              <SaveAddressButton
                canSaveAddress={canSaveAddress}
                isLoadingCreateAddress={isLoadingCreateAddress}
                handleSaveAddress={handleSaveAddress}
                canUpdateAddress={canUpdateAddress}
                handleUpdateAddress={handleUpdateAddress}
                isLoadingUpdateAddress={isLoadingUpdateAddress}
              />
            </>
          ) : null}
          <Box my={6}>
            <DriverInstructionSection
              instructionsVisibility={instructionsVisibility}
              setInstructionsVisibility={setInstructionsVisibility}
              form={form}
              cypressTestId={cypressTestId}
            />
          </Box>
          <Box my={6}>
            <Divider my={6} />
            {isMultiDrop && !showPickupReference ? (
              <Text fontWeight={500}>Pickup Reference</Text>
            ) : null}
            {showPickupReference ? (
              <FormAttribute
                id={'collectionReference'}
                label="Pickup Reference"
                isRequired
                isInvalid={Boolean(form.formState.errors.collectionReference)}
                placeholder={'e.g. Ticket #123456'}
                mt={'6'}
                error={form.formState.errors.collectionReference}
                description={'Someone to find or a code to reference on arrival'}
                data-cy={'pickup-reference'}
                inputCypressId={'pickup-reference-input'}
                {...form.register('collectionReference')}
              />
            ) : null}
            {isMultiDrop ? (
              <Flex align={'center'} gap={4} my={4}>
                <Text>Set pick up reference for all deliveries</Text>
                <Switch
                  ml={4}
                  defaultChecked={showPickupReference}
                  onChange={handleTogglePickupReference}
                  data-cy={'pickup-reference-toggle'}
                />
              </Flex>
            ) : null}
            <Divider my={6} />
          </Box>
          <PodSection form={form} disableImageToggle />
        </Box>
      </OrderSection>
    </MerchantFormConfigProvider>
  );
};
