import {
  CreateDeliveryStop,
  MerchantAccount,
  MerchantDefinedCompanyAddress,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { useDeliveryStop } from './useDeliveryStop';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { resetAddressFields } from '../utils';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import {
  getAddressesQueryKey,
  useCreateMerchantAddress,
  useDeleteMerchantCompanyAddress,
  useGeocodeAddress,
  useUpdateAddress,
} from '@tradeaze/frontend/hooks';
import { getHasAddressChanged } from '../utils/getHasAddressChanged';

type UsePickupSection = {
  merchantAccount?: MerchantAccount;
  showExpanded?: boolean;
  defaultPickupAddress?: string;
  defaultPickupPostcode?: string;
  defaultPickupCity?: string;
  defaultPickupPosition?: { latitude: number; longitude: number } | null;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
  isSecondPickup?: boolean;
  stopId: number;
  isMultiDrop: boolean;
};

export const usePickupSection = ({
  merchantAccount,
  showExpanded,
  defaultPickupAddress,
  defaultPickupPostcode,
  defaultPickupPosition,
  defaultPickupCity,
  stopSequence,
  form,
  addMarker,
  removeMarker,
  isSecondPickup,
  stopId,
  isMultiDrop,
}: UsePickupSection) => {
  const { setValue, watch } = form;

  const addressLine1 = watch('addressLine1');
  const postCode = watch('postCode');
  const addressId = watch('addressId');
  const deliveryNotes = watch('deliveryNotes');
  const contacts = watch('contacts');
  const position = form.watch('position');
  const companyName = watch('companyName');
  const city = watch('city');

  const {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    isLoadingSavedAddresses,
    savedAddresses,
    savedAddressPlaceholder,
    handleResetAddressFields,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
  } = useDeliveryStop({
    form,
    merchantAccount,
    addressType: 'PICK_UP',
    companyId: undefined,
  });

  const [shouldUseDefaultPickup, setShouldUseDefaultPickup] = useState<boolean>(
    !showExpanded && !!merchantAccount && !isSecondPickup,
  );

  const [showPickupReference, setShowPickupReference] = useState<boolean>(
    !isMultiDrop || Boolean(form.getValues('collectionReference')),
  );

  const handleToggleDefaultPickupCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        e.target.checked &&
        defaultPickupAddress &&
        defaultPickupPostcode &&
        defaultPickupCity
      ) {
        setShouldUseDefaultPickup(true);
        handleManualAddress(false);
        setValue('companyName', merchantAccount?.merchantName || '');
        setValue('addressLine1', defaultPickupAddress);
        setValue('postCode', defaultPickupPostcode);
        setValue('position', defaultPickupPosition);
        setValue('city', defaultPickupCity);
      } else {
        setValue('companyName', '');
        setShouldUseDefaultPickup(false);
        handleResetAddressFields();
      }
    },
    [
      defaultPickupAddress,
      defaultPickupPostcode,
      defaultPickupPosition,
      defaultPickupCity,
      merchantAccount,
      setValue,
      handleResetAddressFields,
      handleManualAddress,
    ],
  );

  const handleTogglePickupReference = () => {
    if (isMultiDrop) {
      setValue('collectionReference', null);
    }
    setShowPickupReference(!showPickupReference);
  };

  const geocodeQuery = useGeocodeAddress(
    { postcode: postCode },
    {
      enabled: isManualAddress,
    },
  );

  useEffect(() => {
    if (!isManualAddress) return;

    if (geocodeQuery.data) {
      setValue('position', geocodeQuery.data.position);
    } else {
      setValue('position', null);
    }
  }, [geocodeQuery.data, isManualAddress, setValue]);

  useEffect(() => {
    if (position) {
      addMarker?.({
        id: stopId.toString(),
        position,
        stopSequence,
      });
    } else {
      removeMarker?.(stopId.toString());
    }
  }, [position]);

  useEffect(() => {
    if (!showExpanded && !shouldUseDefaultPickup) {
      resetAddressFields(setValue);
    }
  }, [shouldUseDefaultPickup, setValue, showExpanded]);

  const { mutate: createAddress, isLoading: isLoadingCreateAddress } =
    useCreateMerchantAddress({
      invalidateQueryKeys: [getAddressesQueryKey()],
      onSuccess: ({ addressId }) => {
        setValue('addressId', addressId);
      },
    });

  const { mutate: deleteMerchantCompanyAddress, isLoading: isDeletingAddress } =
    useDeleteMerchantCompanyAddress({
      invalidateQueryKeys: [getAddressesQueryKey()],
    });

  const updateAddressMutation = useUpdateAddress();

  const canSaveAddress =
    !!merchantAccount &&
    !!addressLine1 &&
    !!postCode &&
    !!city &&
    !!position &&
    !shouldUseDefaultPickup &&
    !addressId;

  const canUpdateAddress = useMemo<boolean>(() => {
    if (!addressId || !savedAddresses) {
      return false;
    }
    const savedAddress = savedAddresses.find(
      (el) => el.address.addressId === addressId,
    );
    if (!savedAddress) {
      return false;
    }

    return getHasAddressChanged(savedAddress.address, {
      addressLine1,
      postCode,
      companyName,
      deliveryNotes,
      city,
    });
  }, [
    addressId,
    savedAddresses,
    addressLine1,
    postCode,
    deliveryNotes,
    companyName,
    city,
  ]);

  const handleSaveAddress = async () => {
    if (!merchantAccount || !addressLine1 || !postCode || !city || !position) {
      return;
    }

    const newAddress: MerchantDefinedCompanyAddress = {
      companyId: '',
      companyName: '',
      instructions: deliveryNotes,
      merchantId: merchantAccount.merchantId,
      addressLine1,
      postCode,
      position,
      city,
      addressType: 'PICK_UP',
    };

    createAddress(newAddress);
  };

  const handleUpdateAddress = () => {
    if (!addressId) {
      return;
    }
    updateAddressMutation.mutate({
      addressId,
      body: {
        contactIds: contacts.map((contact) => contact.siteContactId || ''),
        instructions: deliveryNotes,
        companyName: companyName,
        addressLine1,
        position,
        postCode,
        city,
      },
    });
  };

  const handleDeleteAddress = (addressId: string) => {
    deleteMerchantCompanyAddress({ addressId });
  };

  return {
    form,
    shouldUseDefaultPickup,
    showFullAddress,
    isManualAddress,
    geocodeData: geocodeQuery.data,
    geocodeError: geocodeQuery.error,
    instructionsVisibility,
    setInstructionsVisibility,
    handleToggleDefaultPickup: handleToggleDefaultPickupCallback,
    isSectionComplete: false,
    savedAddresses,
    savedAddressPlaceholder,
    isLoadingSavedAddresses,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress: updateAddressMutation.isLoading,
    showPickupReference,
    handleTogglePickupReference,
    handleDeleteAddress,
    isDeletingAddress,
  };
};
