import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Collapse,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { DeliveryOptionId } from '@tradeaze-packages/schemas';
import { useState } from 'react';
import { DeliveryFees } from './DeliveryFee';
import { DeliveryServiceCharge } from './DeliveryServiceCharge';
import { DeliveryTotal } from './DeliveryTotal';

type DeliveryPriceProps = {
  isDeliveryPriceLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  isAdmin: boolean;
  deliveryPrice: number;
  serviceCharge: number;
  totalTradeazeCharges: number;
  deliveryPriceErrorMessage: string | null;
  parentRef?: React.RefObject<HTMLDivElement>;
};

export const DeliveryPrice = ({
  isDeliveryPriceLoading,
  showDeliveryPrice,
  isAdmin,
  deliveryPrice,
  serviceCharge,
  totalTradeazeCharges,
  deliveryPriceErrorMessage,
  parentRef,
}: DeliveryPriceProps) => {
  const [isBreakdownVisible, setIsBreakdownVisible] = useState(false);

  const toggleBreakdown = () => {
    setIsBreakdownVisible(!isBreakdownVisible);
  };

  const scrollParentToBottom = () => {
    if (parentRef?.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  };

  return (
    <Stack spacing={4}>
      <DeliveryTotal
        isDeliveryPriceLoading={isDeliveryPriceLoading}
        showDeliveryPrice={showDeliveryPrice}
        totalTradeazeCharges={totalTradeazeCharges}
        deliveryPriceErrorMessage={deliveryPriceErrorMessage}
      />
      <Tag colorScheme="green" size="lg" variant="subtle">
        <TagLeftIcon as={InfoIcon} />
        <TagLabel>No waiting fees or hidden charges!</TagLabel>
      </Tag>
      <Stack spacing={0}>
        <Button
          variant="link"
          size="sm"
          rightIcon={
            isBreakdownVisible ? <ChevronUpIcon /> : <ChevronDownIcon />
          }
          onClick={toggleBreakdown}
          alignSelf="flex-end"
          data-cy="price-breakdown-toggle"
        >
          {isBreakdownVisible ? 'Hide' : 'View'} Price Breakdown
        </Button>
        <Collapse
          in={isBreakdownVisible}
          // When expanding, scroll to the bottom of the parent element
          onUpdate={() => isBreakdownVisible && scrollParentToBottom()}
        >
          <Stack spacing={6} mt={4}>
            <DeliveryFees
              isDeliveryPriceLoading={isDeliveryPriceLoading}
              showDeliveryPrice={showDeliveryPrice}
              isAdmin={isAdmin}
              deliveryPrice={deliveryPrice}
            />
            <DeliveryServiceCharge
              isDeliveryPriceLoading={isDeliveryPriceLoading}
              showDeliveryPrice={showDeliveryPrice}
              isAdmin={isAdmin}
              serviceCharge={serviceCharge}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};
