import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  GeocodeAddressBodySchema,
  GeocodeAddressResponseSchema,
  LookupAddressesQuerySchema,
  LookupAddressesResponseSchema,
  GetLookupAddressParamsSchema,
  GetLookupAddressResponseSchema,
} from '../../address-service';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/address';

/**
 * POST /geocode
 */
registry.registerPath({
  operationId: 'geocodeAddress',
  path: `${basePath}/geocode`,
  description: 'Get latitude and longitude for a given address.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: GeocodeAddressBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Address geocoded successfully.',
      content: {
        'application/json': {
          schema: GeocodeAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /lookup
 */
registry.registerPath({
  operationId: 'lookupAddresses',
  path: `${basePath}/lookup`,
  description: 'Look up addresses by postcode.',
  method: 'get',
  request: {
    query: LookupAddressesQuerySchema,
  },
  responses: {
    200: {
      description: 'Addresses found successfully.',
      content: {
        'application/json': {
          schema: LookupAddressesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /lookup/{id}
 */
registry.registerPath({
  operationId: 'getLookupAddress',
  path: `${basePath}/lookup/{id}`,
  description: 'Get detailed address information by ID from the lookup service.',
  method: 'get',
  request: {
    params: GetLookupAddressParamsSchema,
  },
  responses: {
    200: {
      description: 'Address details retrieved successfully.',
      content: {
        'application/json': {
          schema: GetLookupAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const addressApiRegistry = registry; 