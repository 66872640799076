import { Box, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const usePopupColors = () => ({
  bg: useColorModeValue('white', 'gray.800'),
  borderColor: useColorModeValue('gray.200', 'gray.700'),
  hoverBg: useColorModeValue('gray.50', 'gray.700'),
});

export type MapPopupProps = {
  onClick?: () => void;
  children: React.ReactNode;
};

export const MapPopup: React.FC<MapPopupProps> = ({ onClick, children }) => {
  const colors = usePopupColors();

  return (
    <Box
      fontSize={11}
      bg={colors.bg}
      p={3}
      borderRadius={12}
      shadow={'xl'}
      minW="120px"
      maxW="230px"
      width="max-content"
      border="1px solid"
      borderColor={colors.borderColor}
      transition="all 0.2s"
      _hover={
        onClick
          ? {
              transform: 'translateY(-2px)',
              shadow: '2xl',
              bg: colors.hoverBg,
            }
          : undefined
      }
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
