import {
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  DeliveryItem,
  DeliveryStopType,
  DeliveryVehicleId,
  RiderIdentity,
} from '@tradeaze-packages/schemas';
import { getDifferenceInTime } from '@tradeaze/shared/utils';
import { useMemo } from 'react';
import {
  FaClock,
  FaMapMarkerAlt,
  FaRuler,
  FaWeightHanging,
} from 'react-icons/fa';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { DeliveryVehicleIcon } from '../delivery';
import { IconText } from '../shared';

const useDeliveryPopupColors = () => ({
  borderColor: useColorModeValue('gray.200', 'gray.700'),
  capacityBg: useColorModeValue('gray.50', 'gray.700'),
  capacityBorder: useColorModeValue('gray.200', 'gray.600'),
  textColor: 'gray.600',
  timestampColor: 'gray.500',
  dimensionIconColor: 'gray.500',
  arrowColor: 'gray.500',
});

const PackageInfoCard = ({
  deliveryItems,
}: {
  deliveryItems?: DeliveryItem[];
}) => {
  const colors = useDeliveryPopupColors();

  const totalWeight = useMemo(() => {
    if (!deliveryItems?.length) return 0;
    return deliveryItems.reduce((sum, item) => sum + (item.weight || 0), 0);
  }, [deliveryItems]);

  const maxDimensions = useMemo(() => {
    if (!deliveryItems?.length) return null;
    return deliveryItems.reduce(
      (max, item) => ({
        length: Math.max(max.length, item.length || 0),
        width: Math.max(max.width, item.width || 0),
        height: Math.max(max.height, item.height || 0),
      }),
      { length: 0, width: 0, height: 0 },
    );
  }, [deliveryItems]);

  if (!deliveryItems?.length) return null;

  return (
    <VStack
      align="stretch"
      spacing={0}
      px={2}
      py={1}
      borderRadius={8}
      border="1px solid"
      bg={colors.capacityBg}
      borderColor={colors.capacityBorder}
    >
      <Text fontSize="xs" fontWeight="medium" color={colors.textColor}>
        Package Details:
      </Text>
      <IconText
        icon={FaRuler}
        text={`${maxDimensions?.length}cm × ${maxDimensions?.width}cm × ${maxDimensions?.height}cm`}
        color={colors.dimensionIconColor}
        fontSize="xs"
      />
      <IconText
        icon={FaWeightHanging}
        text={`${totalWeight}kg`}
        color={colors.dimensionIconColor}
        fontSize="xs"
      />
    </VStack>
  );
};

const PostcodeInfo = ({
  pickupPostcode,
  deliveryPostcode,
  stopType,
}: {
  pickupPostcode: string;
  deliveryPostcode: string;
  stopType?: DeliveryStopType;
}) => {
  const colors = useDeliveryPopupColors();

  return (
    <HStack spacing={2} align="center">
      <HStack spacing={1}>
        <Text
          fontSize="xs"
          color={colors.textColor}
          fontWeight={stopType === 'PICK_UP' ? 'bold' : 'normal'}
        >
          {pickupPostcode}
        </Text>
        {stopType === 'PICK_UP' && (
          <Icon as={FaMapMarkerAlt} color="blue.500" fontSize="xs" />
        )}
      </HStack>
      <Icon as={HiArrowNarrowRight} color={colors.arrowColor} fontSize="sm" />
      <HStack spacing={1}>
        <Text
          fontSize="xs"
          color={colors.textColor}
          fontWeight={stopType === 'DROP_OFF' ? 'bold' : 'normal'}
        >
          {deliveryPostcode}
        </Text>
        {stopType === 'DROP_OFF' && (
          <Icon as={FaMapMarkerAlt} color="green.500" fontSize="xs" />
        )}
      </HStack>
    </HStack>
  );
};

const useTimeRemaining = ({
  deliveryWindowEnd,
  hideOverdueDetails,
}: {
  deliveryWindowEnd: string;
  hideOverdueDetails: boolean;
}) => {
  return useMemo(() => {
    const msRemaining =
      new Date(deliveryWindowEnd).getTime() - new Date().getTime();
    const hoursRemaining = msRemaining / (1000 * 60 * 60);

    const durationRemaining = getDifferenceInTime(
      new Date(),
      new Date(deliveryWindowEnd),
    );

    const isLate = durationRemaining.text.includes('late');
    if (isLate) {
      if (hideOverdueDetails) {
        return { text: 'Due', colour: 'orange' };
      } else {
        return { text: durationRemaining.text, colour: 'red' };
      }
    }

    const isClose = hoursRemaining < 1;
    if (isClose) {
      return { text: durationRemaining.text, colour: 'orange' };
    }

    return { text: durationRemaining.text, colour: 'green' };
  }, [deliveryWindowEnd, hideOverdueDetails]);
};

type DeliveryPopupContentProps = {
  rider?: Pick<RiderIdentity, 'firstName' | 'lastName'>;
  deliveryVehicle: DeliveryVehicleId;
  pickupPostcode: string;
  deliveryPostcode: string;
  deliveryWindowEnd: string;
  deliveryItems?: DeliveryItem[];
  isAdmin?: boolean;
  stopType?: DeliveryStopType;
};

export const DeliveryPopupContent = ({
  rider,
  deliveryVehicle,
  pickupPostcode,
  deliveryPostcode,
  deliveryWindowEnd,
  deliveryItems,
  isAdmin,
  stopType,
}: DeliveryPopupContentProps) => {
  const timeInfo = useTimeRemaining({
    deliveryWindowEnd,
    hideOverdueDetails: !isAdmin,
  });

  return (
    <VStack align="stretch" spacing={1}>
      <HStack justify="space-between" spacing={4}>
        <Text fontWeight="bold" fontSize="sm">
          {rider ? `${rider.firstName} ${rider.lastName}` : 'Unassigned'}
        </Text>
        <DeliveryVehicleIcon fontSize={14} deliveryVehicle={deliveryVehicle} />
      </HStack>
      <PostcodeInfo
        stopType={stopType}
        pickupPostcode={pickupPostcode}
        deliveryPostcode={deliveryPostcode}
      />
      <PackageInfoCard deliveryItems={deliveryItems} />
      <IconText
        icon={FaClock}
        text={timeInfo.text}
        color={timeInfo.colour}
        fontSize="xs"
      />
    </VStack>
  );
};
