import { FormControl, Grid, GridItem, FormErrorMessage } from '@chakra-ui/react';
import { vehicleOptions, VehicleType } from './constants';
import { FeatureCard } from '../shared';

interface VehicleSelectorProps {
  selectedVehicle: VehicleType | null;
  onVehicleSelect: (vehicleType: VehicleType) => void;
  error?: string;
}

export const VehicleSelector = ({
  selectedVehicle,
  onVehicleSelect,
  error
}: VehicleSelectorProps) => {
  return (
    <FormControl isInvalid={!!error}>
      <Grid
        templateColumns={{
          base: '1fr',
          sm: 'repeat(2, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
        gap={{ base: 4, md: 6 }}
      >
        {vehicleOptions.map((vehicle) => (
          <GridItem key={vehicle.type}>
            <FeatureCard
              title={`${vehicle.type}`}
              subtitle={`Max ${vehicle.capacity.length}m, ${vehicle.capacity.weight}kg`}
              description={vehicle.description}
              price={vehicle.price}
              icon={null}
              onClick={() => onVehicleSelect(vehicle.type)}
              selected={selectedVehicle === vehicle.type}
              error={Boolean(error)}
            />
          </GridItem>
        ))}
      </Grid>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}; 