import { useEffect, useRef, useState } from 'react';

interface UseElementHeightReturn {
  /** Reference to the element being measured */
  elementRef: React.RefObject<HTMLDivElement>;
  /** Current height of the element in pixels */
  elementHeight: number;
}

/**
 * A hook that measures and tracks the height of a DOM element using ResizeObserver.
 * 
 * @example
 * ```tsx
 * const { elementRef, elementHeight } = useElementHeight();
 * 
 * return (
 *   <div ref={elementRef}>
 *     Height: {elementHeight}px
 *   </div>
 * );
 * ```
 * 
 * @returns {UseElementHeightReturn} Object containing the element reference and its current height
 */
export const useElementHeight = (): UseElementHeightReturn => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    let observer: ResizeObserver;
    if (elementRef.current) {
      observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setElementHeight(entry.contentRect.height);
        }
      });

      observer.observe(elementRef.current);
    }
    return () => observer?.disconnect();
  }, []);

  return { elementRef, elementHeight };
};
