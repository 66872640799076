import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import {
  DropOffMarker,
  MapMarkerProps,
  PickupMarker,
  deliveriesToMarkers,
} from '../../../map';

type DeliveryMarkersProps = {
  deliveries?: HydratedDelivery[];
  selectedDelivery?: HydratedDelivery;
  hoveredDeliveryId?: string;
  hoveredOrderId?: string;
  hoveredRiderId?: string;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onClickDelivery?: (order: HydratedOrder) => void;
  isAdmin?: boolean;
};

const buildStopMarkersFromDelivery = (
  delivery: HydratedDelivery,
): MapMarkerProps[] => {
  return [
    delivery.pickup.position
      ? {
          id: delivery.deliveryId,
          type: 'PICK_UP' as const,
          position: delivery.pickup.position,
          updateZoom: false,
          children: (
            <PickupMarker
              isCompleted={!!delivery.pickup.completedAt}
              stopSequence={0}
            />
          ),
          data: {
            deliveryId: delivery.deliveryId,
            deliveryTime: new Date(delivery.pickup.windowStart),
          },
        }
      : undefined,
    delivery.dropOff.position
      ? {
          id: delivery.deliveryId,
          type: 'DROP_OFF' as const,
          position: delivery.dropOff.position,
          updateZoom: false,
          children: (
            <DropOffMarker
              isCompleted={!!delivery.dropOff.completedAt}
              stopSequence={1}
            />
          ),
          data: {
            deliveryId: delivery.deliveryId,
            deliveryTime: new Date(delivery.dropOff.windowEnd),
          },
        }
      : undefined,
  ].filter((m): m is NonNullable<typeof m> => Boolean(m));
};

export const useDashboardDeliveryMarkers = ({
  deliveries,
  selectedDelivery,
  hoveredDeliveryId,
  hoveredOrderId,
  hoveredRiderId,
  onHoverDelivery,
  onHoverLeaveDelivery,
  onClickDelivery,
  isAdmin,
}: DeliveryMarkersProps) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!deliveries) {
      return [];
    }

    if (selectedDelivery?.deliveryId) {
      const delivery = deliveries.find(
        ({ deliveryId }) => deliveryId === selectedDelivery?.deliveryId,
      );
      if (!delivery) {
        return [];
      }
      return buildStopMarkersFromDelivery(delivery);
    }

    return deliveriesToMarkers({
      deliveries,
      isAdmin,
      hoveredDeliveryId,
      hoveredOrderId,
      hoveredRiderId,
      onHover: onHoverDelivery,
      onHoverLeave: onHoverLeaveDelivery,
      onClickDelivery,
    });
  }, [
    deliveries,
    selectedDelivery,
    hoveredDeliveryId,
    hoveredOrderId,
    hoveredRiderId,
    onHoverDelivery,
    onHoverLeaveDelivery,
    onClickDelivery,
    isAdmin,
  ]);
};
