import { useQuery } from '@tanstack/react-query';
import { getLookupAddress } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '@tradeaze/frontend/hooks';

export const getLookupAddressQueryKey = (id: string) => [
  'getLookupAddress',
  id,
];

export const useGetLookupAddress = (
  id: string,
  options?: CustomUseQueryOptions<typeof getLookupAddress>,
) =>
  useQuery({
    queryKey: getLookupAddressQueryKey(id),
    queryFn: async () => await getLookupAddress({ id }),
    enabled: Boolean(id),
    ...options,
  });
