import { Box, Button, ButtonProps, Center, HStack, Icon, Text } from '@chakra-ui/react';
import React, { MouseEvent, ReactNode } from 'react';
import { brandPrimaryColor } from '../theme/colors';
import { NotificationIcon } from '../shared';
import { NAVBAR_BUTTON_HOVER_BACKGROUND_COLOR } from './DesktopNavbarButton';
import { Link as ReactRouterLink } from 'react-router-dom';

export const MOBILE_NAVBAR_ICON_COLOR = 'rgba(107, 114, 128, 1)';
export const MOBILE_NAVBAR_ACTIVE_ICON_COLOR = brandPrimaryColor;
export const MOBILE_NAVBAR_BUTTON_LABEL_COLOR = 'rgba(209,213,219,1)';

export const MobileNavbarButton: React.FunctionComponent<
  ButtonProps & {
    label: string;
    notificationCount?: boolean;
    isActive?: boolean;
    color?: string;
    icon: ReactNode;
    dataCy: string;
    to?: string | null;
  }
> = ({onClick, ...props}) => {
  const iconColor = props.isActive
    ? MOBILE_NAVBAR_ACTIVE_ICON_COLOR
    : props.color || MOBILE_NAVBAR_ICON_COLOR;

  const iconWithColor = React.Children.map(props.icon, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        style: { color: iconColor },
      } as React.ComponentProps<typeof Icon>);
    }
    return child;
  });

  const textColor = props.isActive
    ? MOBILE_NAVBAR_ACTIVE_ICON_COLOR
    : props.color || MOBILE_NAVBAR_BUTTON_LABEL_COLOR;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (!props.to) event.preventDefault();
  };

  return (
    <Button
      as={ReactRouterLink}
      to={props.to}
      onClick={handleClick}
      fontSize={'xl'}
      variant="unstyled"
      textAlign={'left'}
      color={textColor}
      _hover={{ bg: NAVBAR_BUTTON_HOVER_BACKGROUND_COLOR }}
      alignItems={'center'}
      justifyContent={'center'}
      w={'100%'}
      position="relative"
      {...props}
      data-cy={props.dataCy}
    >
      <HStack>
        <Box width={8} position="relative">
          {props.notificationCount && (
            <Box position="absolute" right={-2} top={-1} zIndex={1}>
              <NotificationIcon color={'red.600'} />
            </Box>
          )}
          <Center>{iconWithColor}</Center>
        </Box>
        <Text color={textColor}>{props.label}</Text>
      </HStack>
    </Button>
  );
};