import { Box, Flex, HStack, Icon, Tag, Text } from '@chakra-ui/react';
import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { FaUserCircle } from 'react-icons/fa';
import { RiderAvatar } from '../../../../rider';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';
import { WorkTypeTag } from '../../../../shared/WorkTypeTag';

type Driver = {
  firstName: string;
  lastName?: string | null;
  vehicleId?: DeliveryVehicleId | null;
};

interface CardDriverInfoProps {
  drivers: Driver[] | null;
  showVehicle?: boolean;
  isGigJob?: boolean;
  showWorkType: boolean;
  isGigJobBoard: boolean;
  hasShiftRider?: boolean;
  externalProviders?: string[];
  isAdmin?: boolean;
  externalJobCancelledStatuses: boolean[];
}

const SingleProviderTag = ({
  provider,
  isCancelled,
}: {
  provider: string;
  isCancelled: boolean;
}) => (
  <Tag
    colorScheme={isCancelled ? 'red' : 'gray'}
    size="sm"
    fontWeight={'bold'}
    mx={1}
    border="1px solid #D5D5D5"
  >
    {isCancelled && <SmallCloseIcon w={3} h={3} color="currentColor" mr={1} />}
    <Text data-cy={'external-provider-name'}>{provider}</Text>
  </Tag>
);

const MultipleProvidersView = ({ providers }: { providers: string[] }) => (
  <Flex gap={2}>
    <Box display={'flex'} flexDirection={'row-reverse'} position="relative">
      {[...new Set(providers)]?.map((provider, index) => (
        <Box
          key={index}
          position="relative"
          marginRight={index !== 0 ? '-6px' : '0'}
        >
          <RiderAvatar
            size={'xs'}
            firstName={provider[0]}
            lastName={provider[1]}
          />
        </Box>
      ))}
    </Box>
    <Text data-cy={'card-providers-assigned'}>
      {providers.length} providers assigned
    </Text>
  </Flex>
);

const ExternalJobTag = ({
  externalProviders,
  externalJobCancelledStatuses,
}: {
  externalProviders: string[];
  externalJobCancelledStatuses: boolean[];
}) => (
  <HStack alignItems={'center'}>
    {externalProviders?.length === 1 ? (
      <SingleProviderTag
        provider={externalProviders[0]}
        isCancelled={externalJobCancelledStatuses[0]}
      />
    ) : (
      <MultipleProvidersView providers={externalProviders} />
    )}
  </HStack>
);

const JobBoardTag = () => (
  <Tag
    colorScheme="gray"
    size="md"
    fontWeight={'bold'}
    border="1px solid #D5D5D5"
    data-cy="job-board-tag"
  >
    <CheckCircleIcon w={3} h={3} color="#579F6E" mr={1} />
    Job Board
  </Tag>
);

const WorkTypeTags = ({
  hasShiftRider,
  isGigJob,
  isGigJobBoard,
}: {
  hasShiftRider?: boolean;
  isGigJob?: boolean;
  isGigJobBoard?: boolean;
}) => (
  <HStack alignItems={'center'} spacing={2} px={1}>
    {hasShiftRider && <WorkTypeTag workType="SHIFT" />}
    {isGigJob && <WorkTypeTag workType="GIG" />}
    {isGigJobBoard && <JobBoardTag />}
  </HStack>
);

const UnassignedView = ({
  showExternalJobTag,
  showWorkType,
  externalProps,
  workTypeProps,
}: {
  showExternalJobTag: boolean;
  showWorkType: boolean;
  externalProps: Pick<
    CardDriverInfoProps,
    'externalProviders' | 'externalJobCancelledStatuses'
  >;
  workTypeProps: Pick<
    CardDriverInfoProps,
    'hasShiftRider' | 'isGigJob' | 'isGigJobBoard'
  >;
}) => (
  <HStack flexDirection={'row'} alignItems={'center'} flexWrap={'wrap'}>
    <HStack>
      <Icon as={FaUserCircle} fontSize={24} color={'blackAlpha.500'} />
      <Text data-cy={'card-unassigned'}>Unassigned</Text>
    </HStack>
    {showExternalJobTag && (
      <ExternalJobTag
        externalJobCancelledStatuses={
          externalProps.externalJobCancelledStatuses
        }
        externalProviders={externalProps.externalProviders || []}
      />
    )}
    {showWorkType && <WorkTypeTags {...workTypeProps} />}
  </HStack>
);

const SingleDriverInfo = ({
  driver,
  showVehicle,
}: {
  driver: Driver;
  showVehicle?: boolean;
}) => (
  <HStack>
    <Text data-cy={'card-driver-name'}>{driver.firstName.split(' ')[0]}</Text>
    {showVehicle && driver.vehicleId && (
      <Box>
        <DeliveryVehicleIcon
          fontSize={'1em'}
          deliveryVehicle={driver.vehicleId}
        />
      </Box>
    )}
  </HStack>
);

export const CardDriverInfo: React.FC<CardDriverInfoProps> = ({
  drivers,
  showVehicle,
  isGigJob,
  showWorkType,
  isGigJobBoard,
  externalProviders,
  hasShiftRider,
  isAdmin,
  externalJobCancelledStatuses,
}) => {
  const showExternalJobTag = isAdmin && (externalProviders || [])?.length > 0;
  const externalProps = {
    externalProviders: externalProviders || [],
    externalJobCancelledStatuses,
  };
  const workTypeProps = { hasShiftRider, isGigJob, isGigJobBoard };

  if (!drivers?.length) {
    return (
      <UnassignedView
        showExternalJobTag={showExternalJobTag || false}
        showWorkType={showWorkType}
        externalProps={externalProps}
        workTypeProps={workTypeProps}
      />
    );
  }

  return (
    <Flex gap={2} flexWrap="wrap" color={'blackAlpha.800'}>
      <Box display={'flex'} flexDirection={'row-reverse'} position="relative">
        {drivers.map((driver, index) => (
          <Box
            key={index}
            position="relative"
            marginRight={index !== 0 ? '-6px' : 1}
          >
            <RiderAvatar
              size={'xs'}
              firstName={driver?.firstName}
              lastName={driver?.lastName}
            />
          </Box>
        ))}
      </Box>
      <Box>
        {drivers.length > 1 ? (
          <Text data-cy={'card-drivers-assigned'}>
            {drivers.length} drivers assigned
          </Text>
        ) : (
          <SingleDriverInfo driver={drivers[0]} showVehicle={showVehicle} />
        )}
      </Box>
      {showExternalJobTag && <ExternalJobTag {...externalProps} />}
      {showWorkType && <WorkTypeTags {...workTypeProps} />}
    </Flex>
  );
};
