import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';

extendZodWithOpenApi(z);

/**
 * Event Type Schema
 * @description
 * This is the schema for the event types sent to the SNS topic
 */
export const EventTypeSchema = z.enum([
  'ORDER_CREATED',
  'SCHEDULE_UPDATED',
  'ORDER_CONFIRMED',
  'ORDER_CANCELLED',
  'ORDER_REJECTED',
  'ORDER_COMPLETE',
  'ORDER_UPDATED',
  'PICKUP_ENROUTE',
  'PICKUP_ARRIVED',
  'PICKUP_COMPLETE',
  'DROPOFF_ENROUTE',
  'DROPOFF_ARRIVED',
  'DROPOFF_COMPLETE',
  'DELIVERY_ASSIGNED',
  'DELIVERY_UNASSIGNED',
  'DELIVERY_CANCELLED',
  'DELIVERY_CONFIRMED',
  'DELIVERY_REJECTED',
  'DELIVERY_COMPLETE',
  'FLEET_VEHICLE_REJECTED',
  'FLEET_VEHICLE_APPROVED',
  'RIDER_REJECTED',
  'RIDER_APPROVED',
  'GIG_JOB_CREATED',
  'EXTERNAL_JOB_CANCELLED_EXTERNALLY',
  'BOOKING_APPROVAL_REQUIRED',
  'BOOKING_APPROVED',
  'BOOKING_REJECTED',
]);

export const WebhookEventTypeSchema = z
  .enum([
    EventTypeSchema.Values.ORDER_CREATED,
    EventTypeSchema.Values.ORDER_CONFIRMED,
    EventTypeSchema.Values.ORDER_CANCELLED,
    EventTypeSchema.Values.ORDER_REJECTED,
    EventTypeSchema.Values.ORDER_UPDATED,
    EventTypeSchema.Values.PICKUP_ENROUTE,
    EventTypeSchema.Values.PICKUP_ARRIVED,
    EventTypeSchema.Values.PICKUP_COMPLETE,
    EventTypeSchema.Values.DROPOFF_ENROUTE,
    EventTypeSchema.Values.DROPOFF_ARRIVED,
    EventTypeSchema.Values.DROPOFF_COMPLETE,
  ])
  .openapi('EventId');

export const WebhookConfigSchema = z.object({
  eventId: WebhookEventTypeSchema,
  name: z.string(),
  description: z.string().nullish(),
  isActive: z.boolean(),
  webhookUrl: z.string().url(),
});

/**
 * SNS Types
 */
export type EventType = z.infer<typeof EventTypeSchema>;
export type WebhookEventType = z.infer<typeof WebhookEventTypeSchema>;
export type WebhookConfig = z.infer<typeof WebhookConfigSchema>; 