import { CreateOrder, DeliveryOptionId } from '@tradeaze-packages/schemas';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

type DeliveryServiceChargeProps = {
  isDeliveryPriceLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  isAdmin: boolean;
  serviceCharge: number;
};

export const DeliveryServiceCharge = ({
  isDeliveryPriceLoading,
  showDeliveryPrice,
  isAdmin,
  serviceCharge,
}: DeliveryServiceChargeProps) => {
  const form = useFormContext<CreateOrder>();
  return (
    <Stack justify={'space-between'} direction={['column', 'row']}>
      <Text>
        Service Charge{' '}
        <Text fontSize={14} color={'grey'}>
          (exc. VAT)
        </Text>
      </Text>
      {isDeliveryPriceLoading ? (
        <Spinner />
      ) : showDeliveryPrice ? (
        isAdmin && form ? (
          <Box>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children="£"
              />
              <Input
                width={120}
                data-cy={'service-charge'}
                {...form.register('merchantServiceCharge', {
                  valueAsNumber: true,
                  min: 0,
                  onBlur: (e) => {
                    const minZeroValue = Math.max(
                      0,
                      parseFloat(e.target.value),
                    );
                    form.setValue(
                      'merchantServiceCharge',
                      parseFloat(minZeroValue.toFixed(2)),
                    );
                    form.setValue('isPriceEdited', true);
                  },
                })}
              />
            </InputGroup>
          </Box>
        ) : (
          <Text>£{serviceCharge.toFixed(2)}</Text>
        )
      ) : (
        <Text color={'red.500'}>Missing required info</Text>
      )}
    </Stack>
  );
};
