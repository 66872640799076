import {
  ExternalCourier,
  ExternalCourierLocationMap,
  HydratedDelivery,
  HydratedOrder,
  MerchantAccount,
  RiderAccount,
  RiderIdentity,
  RiderLocationMap,
} from '@tradeaze-packages/schemas';
import { useNavigate } from 'react-router-dom';
import { useDashboardDeliveryMarkers } from './useDashboardDeliveryMarkers';
import { useDashboardExternalCourierMarkers } from './useDashboardExternalCourierMarkers';
import { useDashboardMerchantMarkers } from './useDashboardMerchantMarkers';
import { useDashboardRiderMarkers } from './useDashboardRiderMarkers';
import {
  MapMarkerFilters,
  useMarkerFilters,
  useCompositeMarkers,
  useMapPointSelect,
  useMarkerHover,
} from '../../../map';

type DashboardMapMarkersProps = ReturnType<typeof useMarkerHover> & {
  deliveries?: HydratedDelivery[];
  merchants?: MerchantAccount[];
  riders?: (RiderIdentity & Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  riderLocations?: RiderLocationMap;
  externalCouriers?: ExternalCourier[];
  externalCourierLocations?: ExternalCourierLocationMap;
  selectedDelivery?: HydratedDelivery;
  isAdmin?: boolean;
  initialFilters?: MapMarkerFilters;
  onClickDelivery?: (order: HydratedOrder) => void;
};

export const useDashboardMapMarkers = ({
  deliveries,
  merchants,
  riders,
  riderLocations,
  externalCouriers,
  externalCourierLocations,
  selectedDelivery,
  isAdmin,
  initialFilters,
  hoveredDeliveryId,
  hoveredOrderId,
  hoveredRiderId,
  onHoverLeaveDelivery,
  onHoverDelivery,
  onClickDelivery,
  onRiderToggleHover,
}: DashboardMapMarkersProps) => {
  const navigate = useNavigate();

  const mapPoints = useMapPointSelect({
    initialEnabled: false,
    multiPoint: true,
  });

  const { markerFilters, handleMarkerFilterChange } =
    useMarkerFilters(initialFilters);

  const merchantMarkers = useDashboardMerchantMarkers({
    merchants,
    selectedDelivery,
    isAdmin,
    navigate,
  });

  const hoveredDelivery = deliveries?.find(d => d.deliveryId === hoveredDeliveryId);

  const riderMarkers = useDashboardRiderMarkers({
    riders,
    riderLocations,
    isAdmin,
    hoveredRiderId,
    hoveredDelivery,
    onRiderToggleHover,
    selectedDelivery,
  });

  const deliveryMarkers = useDashboardDeliveryMarkers({
    deliveries,
    selectedDelivery,
    hoveredDeliveryId,
    hoveredOrderId,
    hoveredRiderId,
    onHoverDelivery,
    onHoverLeaveDelivery,
    onClickDelivery,
    isAdmin,
  });

  const externalCourierMarkers = useDashboardExternalCourierMarkers({
    externalCouriers,
    externalCourierLocations,
    isAdmin,
  });

  const mapMarkers = useCompositeMarkers({
    markerSets: [
      merchantMarkers,
      riderMarkers,
      deliveryMarkers,
      externalCourierMarkers,
      mapPoints.markers,
    ],
    filter: (marker) => markerFilters[marker.type],
  });

  return {
    mapMarkers,
    mapPoints,
    markerFilters,
    handleMarkerFilterChange,
  };
};
