import { Box, Button, ButtonProps, Center, Icon, Text, Tooltip } from '@chakra-ui/react';
import React, { MouseEvent, ReactNode } from 'react';
import { brandPrimaryColor } from '../theme/colors';
import { NotificationIcon } from '../shared';
import { motion, MotionProps } from 'framer-motion';
import { NAVBAR_BACKGROUND_COLOR, NAVBAR_WIDTH } from './NavbarCommon';
import { Link as ReactRouterLink } from 'react-router-dom';

export const NAVBAR_ICON_COLOR = 'rgba(107, 114, 128, 1)';
export const NAVBAR_ACTIVE_ICON_COLOR = brandPrimaryColor;
export const NAVBAR_BUTTON_HOVER_BACKGROUND_COLOR = 'rgba(83,83,83,1)';
export const NAVBAR_BUTTON_LABEL_COLOR = 'rgba(209,213,219,1)';

const MotionBox = motion(Box);

export const DesktopNavbarButton: React.FunctionComponent<{
  label: string;
  notificationCount?: boolean;
  isActive?: boolean;
  color?: string;
  showLabel?: boolean;
  icon: ReactNode;
  to?: string | null;
  onClick?: () => void;
  buttonProps?: ButtonProps;
  disableTooltip?: boolean;
  dataCy: string;
}> = ({
  label,
  notificationCount,
  isActive,
  color,
  showLabel,
  icon,
  to,
  onClick,
  buttonProps,
  disableTooltip,
  dataCy,
}) => {
  const iconColor = isActive ? NAVBAR_ACTIVE_ICON_COLOR : NAVBAR_ICON_COLOR;

  const iconWithColor = React.Children.map(icon, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        style: { color: color || iconColor },
      } as React.ComponentProps<typeof Icon>);
    }
    return child;
  });

  const buttonBackgroundColor = isActive
    ? NAVBAR_BUTTON_HOVER_BACKGROUND_COLOR
    : 'transparent';

  const motionProps: MotionProps = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.2, delay: 0.1, type: 'spring' },
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.();
    if (!to) event.preventDefault();
  };

  return (
    <Tooltip 
      label={!showLabel ? label : undefined} 
      placement="right"
      borderRadius={'md'}
      bg={NAVBAR_BACKGROUND_COLOR}
      hasArrow
      isDisabled={disableTooltip}
    >
      <Button
        as={ReactRouterLink}
        to={to}
        onClick={handleClick}
        h={12}
        w={[14, 14, '100%']}
        variant="unstyled"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        borderRadius={0}
        bg={buttonBackgroundColor}
        _hover={{ bg: NAVBAR_BUTTON_HOVER_BACKGROUND_COLOR }}
        position="relative"
        data-cy={dataCy}
        {...buttonProps}
      >
        <Box display="flex" alignItems="center" width="100%">
          <Center ml={`${NAVBAR_WIDTH * 0.2}px`} position="relative">
            {notificationCount && (
              <Box position="absolute" right={-2} top={-3}>
                <NotificationIcon color={'red.600'} />
              </Box>
            )}
            {iconWithColor}
          </Center>
          {showLabel && (
            <MotionBox {...motionProps} position="absolute" left={12}>
              <Text color={NAVBAR_BUTTON_LABEL_COLOR}>{label}</Text>
            </MotionBox>
          )}
        </Box>
      </Button>
    </Tooltip>
  );
};
