import {
  SimpleGrid,
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { UseQueryResult } from '@tanstack/react-query';
import {
  FleetVehicle,
  GetTotalTimeEstimateResponse,
  getVehicleConfig,
  HydratedDeliveryStop,
} from '@tradeaze-packages/schemas';
import { secondsToDurationText } from '@tradeaze/shared/utils';
import { RegPlate } from '../../../shared';

export const DeliveryScheduleInfo = ({
  fleetVehicle,
  totalTimeQuery,
  orderedStops,
  isLoadingSchedule,
}: {
  fleetVehicle: FleetVehicle;
  totalTimeQuery: UseQueryResult<GetTotalTimeEstimateResponse>;
  orderedStops: HydratedDeliveryStop[];
  isLoadingSchedule?: boolean;
}) => {
  return (
    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
      <Stat size="sm">
        <StatLabel>Vehicle Type</StatLabel>
        <StatNumber>
          {getVehicleConfig(fleetVehicle.deliveryVehicleId).name}
        </StatNumber>
        <StatHelpText mb={0}>
          {fleetVehicle.make} {fleetVehicle.model}
        </StatHelpText>
      </Stat>
      <Stat size="sm">
        <StatLabel>Reg Plate</StatLabel>
        <StatNumber>
          <RegPlate regNumber={fleetVehicle.regNumber} />
        </StatNumber>
      </Stat>
      <Stat size="sm">
        <StatLabel>Dimensions (L&times;W&times;H)</StatLabel>
        <StatNumber>
          {fleetVehicle.lengthCapacity} &times; {fleetVehicle.widthCapacity}{' '}
          &times; {fleetVehicle.heightCapacity} m
        </StatNumber>
      </Stat>
      <Stat size="sm">
        <StatLabel>Weight Capacity</StatLabel>
        <StatNumber>{fleetVehicle.weightCapacity} kg</StatNumber>
      </Stat>
      <Stat size="sm">
        <StatLabel>Total Time Estimate</StatLabel>
        <StatNumber display="flex" alignItems="center" flexWrap="wrap">
          {totalTimeQuery.isFetching || isLoadingSchedule ? (
            <Spinner size="xs" mt={1} />
          ) : totalTimeQuery.isError ? (
            'Error'
          ) : totalTimeQuery.data ? (
            secondsToDurationText(totalTimeQuery.data.duration)
          ) : (
            '-'
          )}
        </StatNumber>
        {!totalTimeQuery.isFetching &&
          totalTimeQuery.data?.driverToNextStop &&
          !isLoadingSchedule && (
            <StatHelpText>
              (including{' '}
              {secondsToDurationText(
                totalTimeQuery.data.driverToNextStop.duration,
              )}{' '}
              to next stop)
            </StatHelpText>
          )}
      </Stat>
      <Stat size="sm">
        <StatLabel>Stops Remaining</StatLabel>
        {isLoadingSchedule ? (
          <Spinner size="xs" mt={1} />
        ) : (
          <StatNumber>{orderedStops.length}</StatNumber>
        )}
      </Stat>
    </SimpleGrid>
  );
};
