import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import { useElementHeight } from '../hooks/useElementHeight';
import { MapMarkerTooltip } from './MapMarkerTooltip';
import { MapPopup } from './MapPopup';

export type MapMarkerMode = 'highlighted' | 'normal' | 'faded';

interface MarkerContainerProps {
  mode?: MapMarkerMode;
  onHover?: () => void;
  onHoverLeave?: () => void;
  isHovered?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  popupContent?: React.ReactNode;
  showPopup?: boolean;
  tooltipIcon?: React.ReactNode;
  tooltipText?: string;
  showTooltip?: boolean;
}

export const MarkerContainer: React.FC<MarkerContainerProps> = ({
  mode = 'normal',
  onHover,
  onHoverLeave,
  isHovered,
  onClick,
  children,
  popupContent,
  showPopup = false,
  tooltipIcon,
  tooltipText,
  showTooltip = false,
}) => {
  const { elementRef: markerRef, elementHeight: markerHeight } =
    useElementHeight();

  const getOpacityByMode = (isHovered?: boolean) => {
    switch (mode) {
      case 'faded':
        return isHovered ? 1 : 0.5;
      case 'highlighted':
      default:
        return 1;
    }
  };

  const getScaleByMode = () => {
    return mode === 'highlighted' ? 'scale(1.1)' : 'scale(1)';
  };

  return (
    <Box
      position="relative"
      cursor={onClick ? 'pointer' : undefined}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
      onClick={onClick}
      opacity={getOpacityByMode(isHovered)}
      transition="all 0.2s"
    >
      {/* Popup and tooltip */}
      <Box
        fontSize={11}
        position="absolute"
        bottom={`${markerHeight}px`}
        left="50%"
        transform="translateX(-50%)"
        width="fit-content"
      >
        <VStack mb={2}>
          {showPopup && popupContent && (
            <MapPopup onClick={onClick}>{popupContent}</MapPopup>
          )}

          {showTooltip && (tooltipText || tooltipIcon) && (
            <MapMarkerTooltip icon={tooltipIcon} text={tooltipText} />
          )}
        </VStack>
      </Box>

      {/* Marker */}
      <Box
        ref={markerRef}
        transform={getScaleByMode()}
        transition="transform 0.2s"
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
};
