import { createStringValidator } from './validationFactory';

const NINO_REGEX =
  /^\s*(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIQUV])[A-Z](?![DFIQUVO])[A-Z]\s?\d{2}\s?\d{2}\s?\d{2}\s?[ABCD]\s*$/i;

const NINO_SPACING_REGEX = /(.{2})(?=.{1})/g;

/**
 * Validator for UK National Insurance numbers (NINOs)
 *
 * @see [NINO Format Manual - HMRC](https://www.gov.uk/hmrc-internal-manuals/national-insurance-manual/nim39110)
 * @see [GOV.UK Design System - National Insurance Numbers](https://design-system.service.gov.uk/patterns/national-insurance-numbers/)
 */
export const ninoValidator = createStringValidator({
  utilityName: 'National Insurance number',
  regex: NINO_REGEX,
  inputOptions: {
    inputMode: 'alphanumeric',
    maxLength: 13,
  },
  formatOptions: {
    removeSeparators: true,
    transform: 'uppercase',
    postFormat: (value) => {
      // Format as QQ 12 34 56 C
      return value.replace(NINO_SPACING_REGEX, '$1 ');
    },
  },
});
