import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Divider, GridItem, SimpleGrid, VStack } from '@chakra-ui/react';
import { HydratedDeliveryStop, OrderType } from '@tradeaze-packages/schemas';
import {
  formatAddressDisplayName,
  formatDate,
  getItemDimensionString,
} from '@tradeaze/shared/utils';
import React from 'react';
import { TrackingLink } from '../order/order-summary/TrackingLink';
import { ProofOfDelivery } from '../order/proof-of-delivery';
import {
  Attribute,
  CopyableTextValue,
  Courier,
  PriceValue,
  TextValue,
  TitleValue,
  WorkTypeTag,
} from '../shared';
import { MultiContactsSection } from './MultiContactsSection';

const sectionColumns = [2, 3, 2, 2, 3];

const getWorkType = (
  deliveryStop: HydratedDeliveryStop,
): 'SHIFT' | 'GIG' | 'EXTERNAL' => {
  const hasGigJob =
    deliveryStop.delivery?.gigJob &&
    deliveryStop.delivery?.gigJob.status !== 'CANCELLED';

  const hasExternalJob =
    deliveryStop.delivery?.externalJob &&
    deliveryStop.delivery?.externalJob.status !== 'CANCELLED_EXTERNAL' &&
    deliveryStop.delivery?.externalJob.status !== 'CANCELLED_INTERNAL';

  if (hasGigJob) {
    return 'GIG';
  }
  if (hasExternalJob) {
    return 'EXTERNAL';
  }
  return 'SHIFT';
};

export const DeliveryStopSummary: React.FunctionComponent<{
  deliveryStop: HydratedDeliveryStop;
  proofOfDeliveryBucketname: string | null;
  isAdmin: boolean;
  testId: string;
  orderType: OrderType;
}> = ({
  deliveryStop,
  proofOfDeliveryBucketname,
  isAdmin,
  testId,
  orderType,
}) => {
  const deliveryItems =
    deliveryStop.deliveryItems || deliveryStop.delivery?.deliveryItems;

  const isMultiDropPickup =
    orderType === 'MULTI_DROP' && deliveryStop.type === 'PICK_UP';
  const isMultiCollectionDropoff =
    orderType === 'MULTI_COLLECTION' && deliveryStop.type === 'DROP_OFF';
  const showLoad = !isMultiDropPickup && !isMultiCollectionDropoff;

  const courier =
    deliveryStop.delivery?.rider || deliveryStop.delivery?.externalCourier;

  const workType = getWorkType(deliveryStop);

  const showWorkType = isAdmin;

  const showCourierNumber = isAdmin;

  const requiresAttachment =
    !!deliveryStop.requiresImage || !!deliveryStop.requiresSignature;

  const stopCompletedAndAttachmentRequired =
    requiresAttachment && !!deliveryStop.completedAt;

  return (
    <Box>
      <SimpleGrid columns={sectionColumns} spacing={4} mt={6}>
        <Attribute name="Address">
          <TextValue
            text={formatAddressDisplayName({
              addressLine1: deliveryStop.addressLine1,
              addressLine2: deliveryStop.addressLine2,
              addressLine3: deliveryStop.addressLine3,
              postCode: deliveryStop.postCode,
              city: deliveryStop.city,
            })}
          />
        </Attribute>
        {deliveryStop.companyName ? (
          <Attribute name="Company Name">
            <TextValue text={deliveryStop.companyName} />
          </Attribute>
        ) : null}
        {deliveryStop.collectionReference ? (
          <Attribute name="Pick Up Reference">
            <TextValue text={deliveryStop.collectionReference} />
          </Attribute>
        ) : null}
        {deliveryStop.requiresSignature ? (
          <Attribute name="Requires Signature">
            <CheckCircleIcon color={'green.500'} />
          </Attribute>
        ) : null}
        {deliveryStop.requiresImage ? (
          <Attribute name="Requires Image">
            <CheckCircleIcon color={'green.500'} />
          </Attribute>
        ) : null}
        {deliveryStop.delivery?.cancellationReason ? (
          <Attribute name="Cancellation Reason">
            <TextValue text={deliveryStop.delivery?.cancellationReason} />
          </Attribute>
        ) : null}
        {deliveryStop.arrivedAt ? (
          <Attribute name="Arrived At">
            <TextValue text={formatDate(deliveryStop.arrivedAt)} />
          </Attribute>
        ) : null}
        {deliveryStop.leftAt ? (
          <Attribute name="Left At">
            <TextValue text={formatDate(deliveryStop.leftAt)} />
          </Attribute>
        ) : null}
        {deliveryStop.completedAt ? (
          <Attribute name="Completed At">
            <TextValue text={formatDate(deliveryStop.completedAt)} />
          </Attribute>
        ) : null}
      </SimpleGrid>
      {courier && (
        <>
          <Divider />
          <SimpleGrid mt={6} columns={3} spacing={4}>
            <Attribute name="Courier">
              <Courier courier={courier} />
            </Attribute>
            {showCourierNumber && courier.contactNumber ? (
              <Attribute name="Courier Number">
                <CopyableTextValue text={courier.contactNumber} />
              </Attribute>
            ) : null}
            {showWorkType && workType ? (
              <Attribute name="Work Type" px={1}>
                <WorkTypeTag workType={workType} />
              </Attribute>
            ) : null}
            {deliveryStop.deliveryId ? (
              <GridItem colSpan={2}>
                <Attribute name="Tracking URL">
                  <TrackingLink deliveryId={deliveryStop.deliveryId} />
                </Attribute>
              </GridItem>
            ) : null}
          </SimpleGrid>
        </>
      )}
      {showLoad && deliveryItems && deliveryItems.length > 0 ? (
        <>
          <Divider />
          <VStack alignItems={'flex-start'} gap={2} mt={6}>
            {deliveryItems?.map((item, index) => (
              <React.Fragment key={item.id}>
                {index > 0 ? <Divider /> : null}
                <TitleValue text={item.name} />
                <SimpleGrid columns={[1, 2, 3]} spacing={4} width={'100%'}>
                  {item.description ? (
                    <Attribute name="Description">
                      <TextValue text={item.description} />
                    </Attribute>
                  ) : null}
                  <Attribute name="Dimensions">
                    {getItemDimensionString(item)}
                  </Attribute>
                  <Attribute name="Weight">{item.weight}kg</Attribute>
                  <Attribute name="Price (exec. VAT)">
                    <PriceValue price={item.price} />
                  </Attribute>
                </SimpleGrid>
                {deliveryStop.heavySideItems ? (
                  <Attribute name="Heavy Side Items">
                    <CheckCircleIcon color={'green.500'} />
                  </Attribute>
                ) : null}
              </React.Fragment>
            ))}
          </VStack>
        </>
      ) : null}
      {deliveryStop.contacts.length ? (
        <>
          <Divider />
          <MultiContactsSection
            contacts={deliveryStop.contacts}
            testId={testId}
          />
        </>
      ) : null}
      {/* proof name */}
      {deliveryStop.proofName ? (
        <>
          <Divider mb={6} />
          <Attribute
            name={
              deliveryStop.type === 'PICK_UP'
                ? 'Collected From'
                : 'Delivered To'
            }
          >
            <TextValue text={deliveryStop.proofName} />
          </Attribute>
        </>
      ) : null}
      {proofOfDeliveryBucketname && (
        <ProofOfDelivery
          orderId={deliveryStop.orderId}
          stopId={deliveryStop.deliveryStopId}
          proofImages={deliveryStop.proofImages}
          proofSignatures={deliveryStop.proofSignatures}
          bucket={proofOfDeliveryBucketname}
          // ie, show to admins, and/or show if it should be there but it's missing
          showMessageIfEmpty={isAdmin || stopCompletedAndAttachmentRequired}
          allowUpload={isAdmin}
          allowDelete={isAdmin}
          podLeftAlign
        />
      )}
    </Box>
  );
};
