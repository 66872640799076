import { Box, Heading, UnorderedList, ListItem } from '@chakra-ui/react';

export const HireInformation = () => {
  return (
    <Box mb={4}>
      <Heading size="md" mb={3}>Hire Information</Heading>
      <UnorderedList spacing={1} pl={6}>
        <ListItem>Operating hours: 8AM - 4PM</ListItem>
        <ListItem>Fuel included for up to 100 miles per shift</ListItem>
        <ListItem>Fees displayed for rental within & around the M25</ListItem>
        <ListItem>For rentals further afield, get in touch</ListItem>
        <ListItem>All shifts must end within London</ListItem>
        <ListItem>The booking company is responsible for managing operations</ListItem>
        <ListItem>Tradeaze is not liable for any item damages during shifts</ListItem>
        <ListItem>You will be contacted to confirm starting time and location</ListItem>
        <ListItem>Multi-day discounts available</ListItem>
      </UnorderedList>
    </Box>
  );
}; 