import { z } from 'zod';
import { FleetVehicleSchema, UpdateFleetVehicleSchema } from '../../../models';

export const UpdateFleetVehicleRequestSchema = UpdateFleetVehicleSchema;

/**
 * @deprecated Use FleetVehicleRequestSchema instead
 */
export const UpdateFleetVehicleBodySchema = FleetVehicleSchema;

export const UpdateFleetVehicleResponseSchema = FleetVehicleSchema;

export type UpdateFleetVehicleRequest = z.infer<
  typeof UpdateFleetVehicleRequestSchema
>;

export type UpdateFleetVehicleResponse = z.infer<
  typeof UpdateFleetVehicleResponseSchema
>;

/**
 * @deprecated Use UpdateFleetVehicleRequest instead
 */
export type UpdateFleetVehicleBody = z.infer<
  typeof UpdateFleetVehicleBodySchema
>;
