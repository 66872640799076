import * as Sentry from '@sentry/react';
import { Environment } from '@tradeaze-packages/schemas';
import posthog, { type PostHog } from 'posthog-js';

interface CreateSentryConfigOptions {
  environment: Environment;
  posthog: PostHog;
  dsn: string;
  appName: 'admin-app' | 'merchant-app' | 'tracking-app';
  version: string;
  sentryOrganization: string;
  sentryProjectId: string;
}

export const createSentryConfig = ({
  dsn,
  appName,
  version,
  sentryOrganization,
  sentryProjectId,
  environment,
}: CreateSentryConfigOptions): Sentry.BrowserOptions => {
  return {
    enabled: environment !== 'ci',
    dsn,
    integrations: [
      new posthog.SentryIntegration(
        posthog,
        sentryOrganization,
        Number(sentryProjectId),
      ),
    ],
    tracesSampleRate: environment === 'prod' ? 0.2 : 1.0,
    environment,
    release: `${appName}-${version}`,
    normalizeDepth: 10,
  };
};
