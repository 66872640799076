import { HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import {
  ExternalCourier,
  FleetVehicle,
  getVehicleConfig,
  RiderIdentity,
} from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { FaClock, FaRuler, FaWeightHanging } from 'react-icons/fa';
import { DeliveryVehicleIcon } from '../delivery';
import { IconText } from '../shared/IconText';
import { PhoneNumber } from '../shared/PhoneNumber';
import { WorkTypeTag } from '../shared/WorkTypeTag';

const useRiderPopupColors = () => ({
  borderColor: useColorModeValue('gray.200', 'gray.700'),
  capacityBg: useColorModeValue('gray.50', 'gray.700'),
  capacityBorder: useColorModeValue('gray.200', 'gray.600'),
  textColor: 'gray.600',
  dimensionIconColor: 'gray.500',
  riderIconColor: 'blue.500',
  timestampColor: 'gray.500',
  phoneHoverColor: 'blue.500',
});

const CapacityCard = ({ vehicle }: { vehicle: FleetVehicle }) => {
  const colors = useRiderPopupColors();

  return (
    <VStack
      align="stretch"
      spacing={0}
      px={2}
      py={1}
      borderRadius={8}
      border="1px solid"
      bg={colors.capacityBg}
      borderColor={colors.capacityBorder}
    >
      <HStack justify="space-between">
        <Text fontSize="xs" color={colors.textColor} fontWeight="medium">
          Capacity:{' '}
          <Text as="span" fontStyle={'italic'}>
            {vehicle.deliveryVehicleId &&
              getVehicleConfig(vehicle.deliveryVehicleId).name}
          </Text>
        </Text>
        {vehicle.deliveryVehicleId && (
          <DeliveryVehicleIcon
            fontSize={16}
            deliveryVehicle={vehicle.deliveryVehicleId}
          />
        )}
      </HStack>
      <IconText
        icon={FaRuler}
        text={`${vehicle.lengthCapacity}m × ${vehicle.widthCapacity}m × ${vehicle.heightCapacity}m`}
        color={colors.dimensionIconColor}
        fontSize="xs"
      />
      <IconText
        icon={FaWeightHanging}
        text={`${vehicle.weightCapacity}kg`}
        color={colors.dimensionIconColor}
        fontSize="xs"
      />
    </VStack>
  );
};

type RiderPopupContentProps = {
  rider?: RiderIdentity;
  externalCourier?: ExternalCourier;
  isAdmin: boolean;
  updatedAt?: string;
};

export const RiderPopupContent = ({
  rider,
  externalCourier,
  isAdmin,
  updatedAt,
}: RiderPopupContentProps) => {
  const colors = useRiderPopupColors();

  const firstName = rider?.firstName || externalCourier?.firstName;
  const lastName = rider?.lastName || externalCourier?.lastName;
  const contactNumber = rider?.contactNumber || externalCourier?.contactNumber;
  const vehicle = rider?.lastUsedVehicle;
  const workType = rider?.workType || 'EXTERNAL';

  return (
    <VStack align="stretch" spacing={1}>
      <HStack justify="space-between" spacing={4}>
        <Text fontWeight="bold" fontSize="sm">
          {firstName} {lastName}
        </Text>
        {!isAdmin && rider?.deliveryVehicle && (
          <DeliveryVehicleIcon
            fontSize={14}
            deliveryVehicle={rider.deliveryVehicle}
          />
        )}
        {isAdmin && <WorkTypeTag workType={workType} />}
      </HStack>
      {isAdmin && vehicle && <CapacityCard vehicle={vehicle} />}
      {isAdmin && contactNumber && <PhoneNumber phone={contactNumber} />}
      {updatedAt && (
        <IconText
          icon={FaClock}
          text={formatDate(new Date(updatedAt))}
          color={colors.timestampColor}
          fontSize="xs"
        />
      )}
    </VStack>
  );
};
