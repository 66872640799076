import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { DeliveryMarker, DeliveryMarkerMode } from '../DeliveryMarker';
import { MapMarkerProps } from '../Map';
import { adjustOverlappingPositions } from './adjustOverlappingPositions';

const getDeliveryMarkerMode = (
  riderId?: string,
  hoveredRiderId?: string,
  currentDeliveryId?: string,
  hoveredDeliveryId?: string,
): DeliveryMarkerMode => {
  if (hoveredDeliveryId === currentDeliveryId) {
    return 'highlighted';
  }

  if (hoveredDeliveryId && hoveredDeliveryId !== currentDeliveryId) {
    return 'faded';
  }

  if (hoveredRiderId && riderId === hoveredRiderId) {
    return 'highlighted';
  }

  if (hoveredRiderId && riderId !== hoveredRiderId) {
    return 'faded';
  }

  return 'normal';
};

const createStopSequenceMapForRider = (
  deliveries: Array<{
    riderId?: string | null;
    pickup?: { deliveryStopId: string; stopSequence?: number };
    dropOff?: { deliveryStopId: string; stopSequence?: number };
  }>,
  riderId?: string,
): Record<string, number> => {
  if (!riderId) return {};

  return deliveries
    .filter((d) => d.riderId === riderId)
    .flatMap((d) => [
      { id: d.pickup?.deliveryStopId, stopOrder: d.pickup?.stopSequence },
      { id: d.dropOff?.deliveryStopId, stopOrder: d.dropOff?.stopSequence },
    ])
    .sort((a, b) => (a.stopOrder ?? 0) - (b.stopOrder ?? 0))
    .map((stop, index) => ({
      id: stop.id,
      stopOrder: index,
    }))
    .reduce<Record<string, number>>((acc, stop) => {
      if (stop.id) {
        acc[stop.id] = stop.stopOrder;
      }
      return acc;
    }, {});
};

export const deliveriesToMarkers = ({
  deliveries,
  isAdmin = false,
  hoveredDeliveryId,
  hoveredOrderId,
  hoveredRiderId,
  selectedDeliveryId,
  onHover,
  onHoverLeave,
  onClickDelivery,
}: {
  deliveries: HydratedDelivery[];
  isAdmin?: boolean;
  hoveredDeliveryId?: string;
  hoveredOrderId?: string;
  hoveredRiderId?: string;
  selectedDeliveryId?: string;
  onHover?: (deliveryId: string) => void;
  onHoverLeave?: () => void;
  onClickDelivery?: (order: HydratedOrder) => void;
}): MapMarkerProps<'DELIVERY'>[] => {
  const allDeliveryStops = deliveries.flatMap((d) => [d.pickup, d.dropOff]);

  const shouldIncludeInAdjustment = (
    stop: HydratedDelivery['pickup' | 'dropOff'],
  ) => {
    const deliveryForStop = deliveries.find(
      (o) => o.deliveryId === stop.deliveryId,
    );

    const deliveryStatus = deliveryForStop?.status;

    if (deliveryStatus === 'CONFIRMED' || deliveryStatus === 'PENDING') {
      return true;
    }

    return false;
  };

  const adjustedStops = adjustOverlappingPositions(
    allDeliveryStops,
    shouldIncludeInAdjustment,
  );

  const deliveriesWithAdjustedStops = deliveries.map((d) => ({
    ...d,
    pickup: adjustedStops.find(
      (s) => s.deliveryStopId === d.pickup.deliveryStopId,
    ),
    dropOff: adjustedStops.find(
      (s) => s.deliveryStopId === d.dropOff.deliveryStopId,
    ),
  }));

  const hoveredRiderStopSequenceMap = createStopSequenceMapForRider(
    deliveriesWithAdjustedStops,
    hoveredRiderId,
  );

  return deliveriesWithAdjustedStops.reduce<MapMarkerProps<'DELIVERY'>[]>(
    (acc, delivery) => {
      if (!delivery.pickup || !delivery.dropOff) {
        return acc;
      }

      const firstStop = delivery.pickup;
      const lastStop = delivery.dropOff;

      const pickUpPosition = firstStop.position;
      const deliveryPosition = lastStop.position;

      const pickUpPostcode = firstStop.postCode;
      const deliveryPostcode = lastStop.postCode;

      if (
        !pickUpPosition ||
        !deliveryPosition ||
        !pickUpPostcode ||
        !deliveryPostcode
      ) {
        return acc;
      }

      if (!(delivery.status === 'CONFIRMED' || delivery.status === 'PENDING')) {
        return acc;
      }

      const isHovered =
        hoveredOrderId === delivery.orderId ||
        hoveredDeliveryId === delivery.deliveryId;

      const isSelected = selectedDeliveryId === delivery.deliveryId;

      acc.push(
        ...([delivery.pickup, delivery.dropOff]
          ?.map((ds) => {
            if (!ds.position) {
              return undefined;
            }

            const mode = getDeliveryMarkerMode(
              delivery.rider?.riderId,
              hoveredRiderId,
              delivery.deliveryId,
              hoveredDeliveryId,
            );

            return {
              id: ds.deliveryStopId,
              type: 'DELIVERY' as const,
              position: ds.position,
              updateZoom: true,
              isHovered,
              children: (
                <DeliveryMarker
                  mode={mode}
                  deliveryId={delivery.deliveryId}
                  isAdmin={isAdmin}
                  deliveryWindowEnd={ds.windowEnd}
                  deliveryPostcode={deliveryPostcode}
                  pickUpPostcode={pickUpPostcode}
                  rider={
                    delivery.rider
                      ? {
                          firstName: delivery.rider.firstName,
                          lastName: delivery.rider.lastName,
                        }
                      : undefined
                  }
                  deliveryVehicle={delivery.deliveryVehicleId}
                  stopType={ds.type}
                  orderId={delivery.orderId}
                  completedAt={ds.completedAt ?? undefined}
                  deliveryItems={delivery.deliveryItems}
                  stopNumber={hoveredRiderStopSequenceMap[ds.deliveryStopId]}
                  onClick={() =>
                    onClickDelivery?.(delivery.order as HydratedOrder)
                  }
                  isSelected={isSelected}
                  isHovered={isHovered}
                  onHover={onHover}
                  onHoverLeave={onHoverLeave}
                />
              ),
            };
          })
          .filter((m): m is NonNullable<typeof m> => Boolean(m)) ?? []),
      );

      return acc;
    },
    [] as MapMarkerProps<'DELIVERY'>[],
  );
};
