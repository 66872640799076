import { Button, HStack } from '@chakra-ui/react';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';
import {
  useCompleteStop,
  useUndoCompleteStop,
  useUnassignRider,
} from '@tradeaze/frontend/hooks';
import { ButtonWithConfirmation } from '../../shared';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { handleError } from '@tradeaze/frontend/utils';

const StopActionButtonWithConfirmation: React.FC<
  React.ComponentProps<typeof ButtonWithConfirmation>
> = ({ ...props }) => {
  return (
    <ButtonWithConfirmation size={'xs'} variant={'outline'} {...props}>
      {props.children}
    </ButtonWithConfirmation>
  );
};

const StopActionButtonLink: React.FC<
  React.ComponentProps<typeof Button> & {
    href?: string;
  }
> = ({ href, ...props }) => {
  return (
    <Button size={'xs'} variant={'outline'} as={Link} to={href} {...props} />
  );
};

export const StopActions: React.FC<{
  stop: HydratedDeliveryStop;
  currentScheduleRiderId: string;
}> = ({ stop, currentScheduleRiderId }) => {
  const isComplete = Boolean(stop.completedAt);

  const assignedRiderId = stop.delivery?.riderId;

  const isCurrentRiderAssigned =
    assignedRiderId && assignedRiderId === currentScheduleRiderId;

  const orderLink = `/orders/${stop.orderId}`;

  const completeStopMutation = useCompleteStop({
    orderId: stop.orderId,
    deliveryDate: stop.windowEnd ? new Date(stop.windowEnd) : undefined,
    riderId: assignedRiderId,
  });

  const undoCompleteMutation = useUndoCompleteStop({
    orderId: stop.orderId,
    deliveryDate: stop.windowEnd ? new Date(stop.windowEnd) : undefined,
    riderId: assignedRiderId,
  });

  const unassignStopMutation = useUnassignRider();

  const handleUndoComplete = () =>
    undoCompleteMutation.mutate(stop.deliveryStopId);

  const handleComplete = () => {
    if (isCurrentRiderAssigned) {
      completeStopMutation.mutate(stop.deliveryStopId);
    } else {
      toast.error('This driver is not assigned to this stop');
    }
  };

  const handleUnassign = () => {
    if (!stop.deliveryId || !assignedRiderId) {
      handleError(new Error('No rider or delivery to unassign'), {
        showNotification: true,
        notificationMessage: 'No rider or delivery to unassign',
        sendToSentry: true,
      });
      return;
    }

    unassignStopMutation.mutate({
      deliveryId: stop.deliveryId,
      riderId: assignedRiderId,
      shouldCancelAcceptedGigJobs: true,
      shouldCancelPendingGigJobs: false,
    });
  };

  const showUnassignButton = isCurrentRiderAssigned && !isComplete;

  return (
    <HStack>
      {isCurrentRiderAssigned &&
        (isComplete ? (
          <StopActionButtonWithConfirmation
            alertTitle="Undo Complete"
            alertDescription="Are you sure you want to undo completion of this stop?"
            onConfirm={handleUndoComplete}
            colorScheme="red"
            isLoading={undoCompleteMutation.isLoading}
          >
            Undo Complete
          </StopActionButtonWithConfirmation>
        ) : (
          <StopActionButtonWithConfirmation
            alertTitle="Complete"
            alertDescription="Are you sure you want to complete this stop?"
            onConfirm={handleComplete}
            colorScheme="green"
            isLoading={completeStopMutation.isLoading}
          >
            Complete
          </StopActionButtonWithConfirmation>
        ))}
      {showUnassignButton && (
        <StopActionButtonWithConfirmation
          alertTitle="Unassign"
          alertDescription="Are you sure you want to unassign this stop?"
          onConfirm={handleUnassign}
          colorScheme="red"
        >
          Unassign
        </StopActionButtonWithConfirmation>
      )}
      <StopActionButtonLink href={orderLink}>View Order</StopActionButtonLink>
    </HStack>
  );
};
