import { Box, Spinner, Text } from '@chakra-ui/react';
import { MAPBOX_PUBLIC_ACCESS_KEY } from '@tradeaze/frontend/utils';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';
import MapboxMap, {
  Anchor,
  Layer,
  MapRef,
  Marker,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { Z_INDEX } from '../constants';
import { RiderModalProvider } from './RiderModalProvider';
import { getMapBounds } from './utils/getMapBounds';

export interface MapPosition {
  latitude: number;
  longitude: number;
}

export interface MapView extends MapPosition {
  zoom: number;
}

export const DEFAULT_ZOOM = 9;

export const CENTRE_OF_LONDON_VIEW_STATE = {
  latitude: 51.509865,
  longitude: -0.118092,
  zoom: DEFAULT_ZOOM,
};

export type MapMarkerType =
  | 'PICK_UP'
  | 'DROP_OFF'
  | 'RIDER'
  // | 'ORDER' // todo - remove
  | 'DELIVERY'
  | 'MERCHANT'
  | 'ADDRESS';

type MapMarkerData<T extends MapMarkerType> = T extends 'PICK_UP'
  ? undefined
  : T extends 'DROP_OFF'
  ? undefined
  : T extends 'RIDER'
  ? { riderId: string }
  : // : T extends 'ORDER'
  // ? { orderId: string; deliveryTime: Date }
  T extends 'DELIVERY'
  ? { deliveryId: string; deliveryTime: Date }
  : T extends 'MERCHANT'
  ? { merchantId: string }
  : T extends 'ADDRESS'
  ? { addressId: string }
  : never;

export interface MapMarkerProps<T extends MapMarkerType = MapMarkerType> {
  id: string;
  type: T;
  position: MapPosition;
  children: React.ReactNode;
  data?: MapMarkerData<T>;
  anchor?: Anchor;
  isHovered?: boolean;
  updateZoom: boolean;
}

const roundCoordinate = (value: number, decimals = 3) => {
  const factor = Math.pow(10, decimals);

  return Math.round(value * factor) / factor;
};

const getSortedFitMarkerIds = (markers: MapMarkerProps[]) =>
  markers
    .filter((marker) => marker.updateZoom)
    .map((marker) => marker.id)
    .sort();

const mapStyleLink = {
  light: 'mapbox://styles/mapbox/light-v11',
  dimmed: 'mapbox://styles/tradeaze-admin/cm8xb0yb9000k01s4e78egqxf',
  streets: 'mapbox://styles/mapbox/streets-v12',
  navigation: 'mapbox://styles/mapbox/navigation-day-v1',
};

export const Map = React.memo<
  React.ComponentProps<typeof MapboxMap> & {
    markers?: MapMarkerProps<MapMarkerType>[];
    markerPadding?: {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    isError?: boolean;
    isLoading?: boolean;
    shouldFitBounds?: boolean;
    shouldUpdateBounds?: boolean;
    startPosition?: MapPosition;
    destinationPosition?: MapPosition;
    styleVariant?: keyof typeof mapStyleLink;
  }
>(
  ({
    markers = [],
    isLoading,
    isError,
    shouldFitBounds = true,
    styleVariant = 'dimmed',
    startPosition,
    destinationPosition,
    markerPadding = {
      top: 150,
      bottom: 100,
      left: 100,
      right: 100,
    },
    ...props
  }) => {
    const [viewState, setViewState] = useState<MapView | undefined>(
      CENTRE_OF_LONDON_VIEW_STATE,
    );

    const [ref, setRef] = useState<MapRef | null>(null);

    const handleMapMove = (event: ViewStateChangeEvent) => {
      setViewState(event.viewState);
    };

    const [hasFitBounds, setHasFitBounds] = useState(false);
    const [fitMarkerIds, setFitMarkerIds] = useState<string[]>([]);
    const [pathLineCoordinates, setPathLineCoordinates] = useState<number[][]>(
      [],
    );

    useEffect(() => {
      if (!startPosition || !destinationPosition) {
        return;
      }

      const startLat = roundCoordinate(startPosition.latitude);
      const startLng = roundCoordinate(startPosition.longitude);
      const destLat = roundCoordinate(destinationPosition.latitude);
      const destLng = roundCoordinate(destinationPosition.longitude);

      setPathLineCoordinates([
        [startLng, startLat],
        [destLng, destLat],
      ]);
    }, [startPosition, destinationPosition]);

    useEffect(() => {
      if (!ref || !shouldFitBounds || isLoading) {
        return;
      }
      if (markers.length === 0) {
        setHasFitBounds(false);
        setFitMarkerIds([]);
        return;
      }
      if (
        hasFitBounds &&
        (getSortedFitMarkerIds(markers).join() === fitMarkerIds.join() ||
          getSortedFitMarkerIds(markers).length < fitMarkerIds.length)
      ) {
        return;
      }
      if (markers.length > 0) {
        const positions = markers.map((marker) => ({
          lng: marker.position.longitude,
          lat: marker.position.latitude,
        }));
        const bounds = getMapBounds(positions);
        if (!bounds) {
          return;
        }
        ref.fitBounds(
          [
            [bounds.minLng, bounds.minLat],
            [bounds.maxLng, bounds.maxLat],
          ],
          {
            padding: markerPadding,
          },
        );
        setHasFitBounds(true);
        setFitMarkerIds(getSortedFitMarkerIds(markers));
      }
      // do not include marker padding or map will not drag
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers, ref, shouldFitBounds, isLoading]);

    return (
      <Box w="full" h="full">
        {isLoading && (
          <Box zIndex={1} position={'absolute'} top={'28'} right={10}>
            <Spinner size={'xl'} />
          </Box>
        )}
        {isError && (
          <Box
            zIndex={1}
            position={'absolute'}
            top={'28'}
            right={10}
            bg="white"
            p={4}
            borderRadius={10}
            shadow={'xl'}
          >
            <Text color={'red.500'}>Error</Text>
          </Box>
        )}
        <RiderModalProvider>
          <MapboxMap
            {...viewState}
            mapboxAccessToken={MAPBOX_PUBLIC_ACCESS_KEY}
            initialViewState={viewState}
            onMove={handleMapMove}
            mapStyle={mapStyleLink[styleVariant]}
            reuseMaps={true}
            ref={(ref) => setRef(ref)}
            maxZoom={19}
            minZoom={4}
            {...props}
          >
            {markers.map(({ position, children, anchor, isHovered }, index) => (
              <Marker
                key={index + position.latitude + position.longitude}
                latitude={position.latitude}
                longitude={position.longitude}
                anchor={anchor || 'bottom'}
                style={{
                  zIndex: isHovered
                    ? Z_INDEX.MAP_MARKER_HOVER
                    : Z_INDEX.MAP_MARKER,
                }}
              >
                {children}
              </Marker>
            ))}
            <Source
              id="path-line"
              type="geojson"
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: pathLineCoordinates,
                },
              }}
            >
              <Layer
                id="lineLayer"
                type="line"
                source="path-line"
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': '#404042',
                  'line-width': 3,
                }}
              />
            </Source>
          </MapboxMap>
        </RiderModalProvider>
      </Box>
    );
  },
);
