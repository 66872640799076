import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AppName } from '@tradeaze-packages/schemas';
import { useCheckAppVersion } from '@tradeaze/frontend/hooks';
import { useEffect } from 'react';
import { IoWarningOutline } from 'react-icons/io5';

const DISABLE_OUTDATED_APP_POPUP = import.meta.env['VITE_IS_LOCAL'] === 'true';

export const OutdatedAppPopup: React.FC<{
  appName: Exclude<AppName, 'rider-app'>;
  onRefresh?: () => void;
  onIgnore?: () => void;
}> = (props) => {
  const { appName, onIgnore, onRefresh } = props;

  const { isLatest } = useCheckAppVersion(appName);

  const modalDisclosure = useDisclosure();

  useEffect(() => {
    if (DISABLE_OUTDATED_APP_POPUP) {
      return;
    }
    if (isLatest === false) {
      modalDisclosure.onOpen();
    }
  }, [isLatest]);

  const handleRefresh = () => {
    window.location.reload();
    onRefresh?.();
  };

  const handleIgnore = () => {
    modalDisclosure.onClose();
    onIgnore?.();
  };

  return (
    <Modal
      isOpen={modalDisclosure.isOpen}
      onClose={modalDisclosure.onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <IoWarningOutline fontSize={24} />
            <Text>Outdated App Version</Text>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Text>
              You are using an old version of the app. Please reload the page to
              get the latest version.
            </Text>
            <Text color={'blackAlpha.500'} fontSize={14}>
              You may experience bugs or missing features on your current
              version
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter justifyItems={'flex-end'}>
          <HStack>
            <Button
              onClick={handleIgnore}
              colorScheme="red"
              variant={'outline'}
            >
              Ignore
            </Button>
            <Button onClick={handleRefresh}>Reload</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
