import { z } from 'zod';
import { postcodeValidator } from '../../utils';

export const LookupAddressesQuerySchema = z.object({
  postcode: postcodeValidator.makeSchema(),
});

export const LookupAddressesResponseSchema = z.object({
  suggestions: z.array(z.object({
    address: z.string(),
    url: z.string(),
    id: z.string(),
  })),
});

export type LookupAddressesQuery = z.infer<typeof LookupAddressesQuerySchema>;
export type LookupAddressesResponse = z.infer<typeof LookupAddressesResponseSchema>;