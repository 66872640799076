import { useMutation } from '@tanstack/react-query';
import { unassignRider } from '@tradeaze-packages/api-client';
import { getApiErrorMessage } from '@tradeaze/shared/utils';
import toast from 'react-hot-toast';
import {
  getAdminDeliveriesQueryKey,
  getRiderDeliveryScheduleQueryKey,
} from '../delivery';
import { getDeliveryQueryKey } from '../delivery';
import { getDeliverySchedulesForDateQueryKey } from '../delivery';
import { useInvalidateQueries } from '../utility';
import { CustomUseMutationOptions } from '../shared';
import { getOrderQueryKey } from './useGetOrder';
import { getAdminOrdersQueryKey } from './useGetAdminOrders';
import { getOrderCountsQueryKey } from './useGetOrderCounts';

export const useUnassignRider = (
  options?: CustomUseMutationOptions<typeof unassignRider>,
) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationFn: unassignRider,
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      
      invalidateQueries([
        getDeliverySchedulesForDateQueryKey(),
        getOrderQueryKey(),
        getDeliveryQueryKey(variables.deliveryId),
        getAdminDeliveriesQueryKey(),
        getAdminOrdersQueryKey(),
        getRiderDeliveryScheduleQueryKey(),
        getOrderCountsQueryKey(),
      ]);

      toast.success('Unassigned');
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context);

      toast.error(getApiErrorMessage(error));
    },
  });
};
