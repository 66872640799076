import { useMutation } from '@tanstack/react-query';
import { cancelOrder } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getOrderQueryKey } from './useGetOrder';
import { getMerchantOrdersQueryKey } from './useGetMerchantOrders';

export const useCancelOrder = (
  options?: CustomUseMutationOptions<typeof cancelOrder>
) => {
  const invalidateQueryKeys = useInvalidateQueries();

  return useMutation({
    mutationFn: cancelOrder,
    onSuccess: (data, variables, context) => {
      invalidateQueryKeys([
        getOrderQueryKey(variables.orderId),
        getMerchantOrdersQueryKey(),
      ]);
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
