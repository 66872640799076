import React from 'react';

export const VanSRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-5 0 200 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="95" y="20" fill="#232324" fontSize="12" fontFamily="Arial">
        Width
      </text>
      <text x="-5" y="110" fill="#232324" fontSize="12" fontFamily="Arial">
        Height
      </text>
      <path
        d="M39.6441 60.7918L41.4414 57.3596H37.0939L38.8912 60.7918H39.6441ZM38.8912 154.149L37.0939 157.582H41.4414L39.6441 154.149H38.8912ZM38.8912 60.4486V154.493H39.6441V60.4486H38.8912Z"
        fill="#AF9032"
      />
      <path
        d="M157.741 33.8372L162.021 35.5722V31.2147L157.741 32.9792V33.8372ZM65.3526 32.9792L61.0051 31.2147V35.5721L65.3526 33.8372V32.9792ZM158.169 32.9792H64.9245V33.8372H158.169V32.9792Z"
        fill="#AF9032"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.7565 173.126C77.7719 173.292 77.7805 173.46 77.7805 173.629V187.405C77.7805 190.248 75.4811 192.553 72.6441 192.553H58.6131C56.5354 192.553 54.7454 191.317 53.9364 189.537C53.6299 189.024 53.4767 188.423 53.4767 187.735V187.405V173.629V124.43L68.0025 75.2912C68.2507 74.2583 68.7592 73.4836 69.5289 72.9672C70.2985 72.4506 71.1554 72.1924 72.0988 72.1924H150.313C151.071 72.1924 151.759 72.4765 152.377 73.0446C152.996 73.6128 153.425 74.3616 153.664 75.2912L168.19 124.43V173.618C168.19 173.621 168.19 173.626 168.19 173.629V187.405C168.19 187.409 168.19 187.413 168.19 187.417V187.735C168.19 188.423 168.037 189.024 167.73 189.537C166.921 191.317 165.132 192.553 163.054 192.553H149.023C146.186 192.553 143.886 190.248 143.886 187.405V173.629C143.886 173.46 143.895 173.292 143.91 173.126H77.7565ZM160.741 118.232H60.9254L72.8435 78.3901H148.823L160.741 118.232ZM81.8759 151.936C80.448 153.668 78.6931 154.533 76.612 154.533C74.5309 154.533 72.7614 153.685 71.3043 151.988C69.8482 150.291 69.1196 148.205 69.1196 145.732C69.1196 143.258 69.8336 141.155 71.2607 139.423C72.6886 137.692 74.4435 136.826 76.5246 136.826C78.6058 136.826 80.3752 137.674 81.8323 139.371C83.2884 141.068 84.017 143.153 84.017 145.628C84.017 148.102 83.303 150.204 81.8759 151.936ZM150.406 151.936C148.978 153.668 147.223 154.533 145.142 154.533C143.061 154.533 141.291 153.685 139.834 151.988C138.378 150.291 137.65 148.205 137.65 145.732C137.65 143.258 138.364 141.155 139.791 139.423C141.219 137.692 142.974 136.826 145.055 136.826C147.136 136.826 148.905 137.674 150.362 139.371C151.818 141.068 152.547 143.153 152.547 145.628C152.547 148.102 151.833 150.204 150.406 151.936ZM162.976 166.929H58.691V124.43H162.976V166.929Z"
        fill="#A7A7A8"
      />
      <path
        d="M154.994 53.002H67.0088C61.292 53.002 56.6576 56.8439 56.6576 61.5831V153.358C56.6576 158.097 61.292 161.939 67.0088 161.939H154.994C160.71 161.939 165.345 158.097 165.345 153.358V61.5831C165.345 56.8439 160.71 53.002 154.994 53.002Z"
        fill="#F9F9F9"
      />
      <path
        d="M154.994 50.8233H67.0088C61.292 50.8233 56.6576 54.742 56.6576 59.576V153.186C56.6576 158.02 61.292 161.939 67.0088 161.939H154.994C160.71 161.939 165.345 158.02 165.345 153.186V59.576C165.345 54.742 160.71 50.8233 154.994 50.8233Z"
        stroke="#A7A7A8"
        stroke-width="6"
      />
      <path
        d="M151.066 61.717H70.9361C69.0529 61.717 67.5263 62.9635 67.5263 64.5011V148.261C67.5263 149.799 69.0529 151.045 70.9361 151.045H151.066C152.949 151.045 154.476 149.799 154.476 148.261V64.5011C154.476 62.9635 152.949 61.717 151.066 61.717Z"
        fill="#D5D5D5"
      />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
    </svg>
  );
};
