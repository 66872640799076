import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, HStack, IconButton } from '@chakra-ui/react';
import { useOptimiseSchedule } from '@tradeaze/frontend/hooks';
import { FaMagic } from 'react-icons/fa';

export const DeliveryScheduleControls = ({
  handleOptimiseSchedule,
  optimiseMutation,
  canSave,
  handleSave,
  handleCancelChanges,
  isLoadingSave,
}: {
  handleOptimiseSchedule: () => void;
  optimiseMutation: ReturnType<typeof useOptimiseSchedule>;
  canSave: boolean;
  handleSave: () => void;
  handleCancelChanges: () => void;
  isLoadingSave: boolean;
}) => {
  return (
    <HStack spacing={2} justifyContent="space-between">
      <Button
        size="sm"
        variant="ghost"
        leftIcon={<FaMagic />}
        onClick={handleOptimiseSchedule}
        isLoading={optimiseMutation.isLoading}
        isDisabled={optimiseMutation.isLoading}
        colorScheme="blue"
      >
        Optimise
      </Button>
      <ButtonGroup size="sm" spacing={1}>
        <Button
          colorScheme="green"
          leftIcon={<CheckIcon />}
          isDisabled={!canSave}
          onClick={handleSave}
          isLoading={isLoadingSave}
        >
          Save
        </Button>
        <IconButton
          aria-label="Cancel Changes"
          icon={<CloseIcon />}
          variant="ghost"
          colorScheme="red"
          isDisabled={!canSave}
          onClick={handleCancelChanges}
        />
      </ButtonGroup>
    </HStack>
  );
};
