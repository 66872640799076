import { Tag, TagLabel, TagProps, ThemingProps } from '@chakra-ui/react';
import { ClockInWorkType, WorkType } from '@tradeaze-packages/schemas';

type WorkTypeTagProps = TagProps & {
  workType: ClockInWorkType | 'EXTERNAL';
  size?: 'sm' | 'md';
};

const tagConfig: Record<WorkType, {label: string, colorScheme: ThemingProps['colorScheme']}> = {
  SHIFT: {
    label: 'SHIFT',
    colorScheme: 'yellow',
  },
  GIG: {
    label: 'GIG',
    colorScheme: 'cyan',
  },
  EXTERNAL: {
    label: 'EXTERNAL',
    colorScheme: 'purple',
  },
  UNASSIGNED: {
    label: 'UNASSIGNED',
    colorScheme: 'gray',
  },
}

export const WorkTypeTag = ({ workType, size = 'sm', ...props }: WorkTypeTagProps) => {
  const config = tagConfig[workType];
  return (
    <Tag
      colorScheme={config.colorScheme}
      size={size}
      fontWeight={'bold'}
      border={`1px solid`}
      borderColor={`${config.colorScheme}.300`}
      minW="fit-content"
      {...props}
    >
      <TagLabel data-cy={`work-type-${workType.toLowerCase()}`}>
        {config.label}
      </TagLabel>
    </Tag>
  );
};
