import {
  CreateOrder,
  CreatePickupStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import jewsonLogo from '../../../lib/assets/jewson.svg';

export enum MerchantConfigKey {
  JEWSON = 'JEWSON',
  MP_MORAN = 'MP_MORAN',
  TRAVIS_PERKINS = 'TRAVIS_PERKINS',
}

export function getMerchantConfigKey(
  merchantAccount: MerchantAccount,
): MerchantConfigKey | null {
  if (merchantAccount.featureFlags?.jewson) {
    return MerchantConfigKey.JEWSON;
  }
  if (merchantAccount.featureFlags?.mpMoran) {
    return MerchantConfigKey.MP_MORAN;
  }
  if (merchantAccount.featureFlags?.travisPerkins) {
    return MerchantConfigKey.TRAVIS_PERKINS;
  }
  return null;
}

export interface MerchantFormAttributeConfig {
  alert?: {
    message: string;
  };
}

export interface MerchantFormConfig {
  createOrder: {
    [K in keyof CreateOrder]?: MerchantFormAttributeConfig;
  };
  createPickupStop: {
    [K in keyof CreatePickupStop]?: MerchantFormAttributeConfig;
  };
}

interface MerchantConfig {
  logo?: { src: string; alt?: string };
  form?: MerchantFormConfig;
}

export const merchantConfig: Record<MerchantConfigKey, MerchantConfig> = {
  [MerchantConfigKey.JEWSON]: {
    logo: {
      src: jewsonLogo,
      alt: 'Jewson Logo',
    },
    form: {
      createOrder: {
        merchantOrderReference: {
          alert: {
            message:
              'Please raise a PO that relates to the total delivery price.',
          },
        },
      },
      createPickupStop: {
        collectionReference: {
          alert: {
            message: `Please enter your WIP number here. If collecting from a different branch, also include their pickup reference.`,
          },
        },
      },
    },
  },
  [MerchantConfigKey.MP_MORAN]: {},
  [MerchantConfigKey.TRAVIS_PERKINS]: {},
};
