import {
  HydratedDelivery,
  RiderAccount,
  RiderIdentity,
  RiderLocationMap,
  WorkTypeSchema,
} from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import {
  createRiderMarker,
  MapMarkerProps,
  RiderMarkerMode,
} from '../../../map';

type RiderMarkersProps = {
  riders?: (RiderIdentity & Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  riderLocations?: RiderLocationMap;
  isAdmin?: boolean;
  hoveredRiderId?: string;
  hoveredDelivery?: { riderId?: string | null };
  onRiderToggleHover?: (riderId: string, isHovered: boolean) => void;
  selectedDelivery?: HydratedDelivery;
};

const getRiderMarkerMode = (
  rider: RiderIdentity & Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>,
  selectedDelivery?: HydratedDelivery,
  hoveredRiderId?: string,
  hoveredDelivery?: { riderId?: string | null },
): RiderMarkerMode => {
  // Hovering over another rider - fade rider
  if (hoveredRiderId && hoveredRiderId !== rider.riderId) return 'faded';

  // Delivery is selected
  if (selectedDelivery) {
    if (selectedDelivery.riderId === rider.riderId) return 'highlighted';
    // Fade rider unless he/she is a SHIFT rider
    return rider.workType === WorkTypeSchema.Enum.SHIFT ? 'normal' : 'faded';
  }

  // Hovering over a delivery stop marker
  if (hoveredDelivery) {
    // If no rider assigned to delivery, keep all riders visible
    if (!hoveredDelivery.riderId) return 'normal';
    // If rider is assigned to delivery, highlight if it's the hovered rider
    if (hoveredDelivery.riderId === rider.riderId) return 'highlighted';
    // Fade rider unless he/she is a SHIFT rider
    return rider.workType === WorkTypeSchema.Enum.SHIFT ? 'normal' : 'faded';
  }

  return 'normal';
};

export const useDashboardRiderMarkers = ({
  riders,
  riderLocations,
  isAdmin,
  hoveredRiderId,
  hoveredDelivery,
  onRiderToggleHover,
  selectedDelivery,
}: RiderMarkersProps) => {
  return useMemo<MapMarkerProps[]>(() => {
    if (!riders || !riderLocations) {
      return [];
    }

    return riders
      .map((rider) => {
        const location = riderLocations[rider.riderId];
        if (!location) return undefined;

        const mode = getRiderMarkerMode(
          rider,
          selectedDelivery,
          hoveredRiderId,
          hoveredDelivery,
        );

        return createRiderMarker(rider, location, {
          isAdmin,
          alwaysShowDetails: false,
          hideClockedOut: true,
          isHovered: hoveredRiderId === rider.riderId,
          setIsHovered: (isHovered: boolean) =>
            onRiderToggleHover?.(rider.riderId, isHovered),
          mode,
        });
      })
      .filter((marker): marker is MapMarkerProps => Boolean(marker));
  }, [
    riders,
    riderLocations,
    isAdmin,
    hoveredRiderId,
    hoveredDelivery,
    onRiderToggleHover,
    selectedDelivery,
  ]);
};
