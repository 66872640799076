import { z } from 'zod';
import { EventTypeSchema } from './event-types';
import { DropoffDeliveryStopSchema } from '../models/delivery-stop-schema';

/**
 * SMS Notification Data
 * @description
 * This is the data that is used to generate SMS notifications
 */

const SendTrackingLinkNotificationDataSchema = z.object({
  deliveryId: z.string(),
  dropOff: DropoffDeliveryStopSchema,
});

export const SmsNotificationInputSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(EventTypeSchema.Values.PICKUP_COMPLETE),
    data: SendTrackingLinkNotificationDataSchema,
  }),
]);

// Export SMS types
export type SendTrackingLinkNotificationData = z.infer<typeof SendTrackingLinkNotificationDataSchema>;
export type SmsNotificationInput = z.infer<typeof SmsNotificationInputSchema>; 