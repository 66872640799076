import { Text } from '@chakra-ui/react';
import { FindAddressesInput } from '../../../location';
import { UseFormReturn } from 'react-hook-form';
import {
  CreateDeliveryStop,
  GetLookupAddressResponse,
} from '@tradeaze-packages/schemas';

type PostCodeSectionProps = {
  handleSelectLocation: (address: GetLookupAddressResponse) => void;
  form: UseFormReturn<CreateDeliveryStop>;
  cypressTestId: string;
  manualAddressFieldsVisible: boolean;
};

export const PostCodeSection = ({
  handleSelectLocation,
  form,
  cypressTestId,
  manualAddressFieldsVisible,
}: PostCodeSectionProps) => {
  const isError =
    form?.formState.errors.postCode ||
    form?.formState.errors.addressLine1 ||
    form?.formState.errors.city;

  return (
    <>
      <Text fontWeight={'bold'} my={2}>
        Postcode
      </Text>
      <FindAddressesInput
        onAddressSelected={handleSelectLocation}
        isError={Boolean(isError) && !manualAddressFieldsVisible}
        cypressTestId={cypressTestId}
      />
    </>
  );
};
