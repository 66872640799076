import { useState, useCallback, useMemo } from 'react';

export const useMarkerHover = () => {
  const [hoveredDeliveryId, setHoveredDeliveryId] = useState<string>();
  const [hoveredOrderId, setHoveredOrderId] = useState<string>();
  const [hoveredRiderId, setHoveredRiderId] = useState<string>();

  const onHoverDelivery = useCallback(
    (deliveryId: string) => setHoveredDeliveryId(deliveryId),
    [],
  );

  const onHoverLeaveDelivery = useCallback(
    () => setHoveredDeliveryId(undefined),
    [],
  );

  const onHoverOrder = useCallback(
    (orderId: string) => setHoveredOrderId(orderId),
    [],
  );

  const onHoverLeaveOrder = useCallback(() => setHoveredOrderId(undefined), []);

  const onRiderToggleHover = useCallback(
    (riderId: string, isHovered: boolean) =>
      setHoveredRiderId(isHovered ? riderId : undefined),
    [],
  );

  return useMemo(
    () => ({
      hoveredDeliveryId,
      hoveredOrderId,
      hoveredRiderId,
      onHoverDelivery,
      onHoverLeaveDelivery,
      onHoverOrder,
      onHoverLeaveOrder,
      onRiderToggleHover,
    }),
    [
      hoveredDeliveryId,
      onHoverDelivery,
      onHoverLeaveDelivery,
      hoveredOrderId,
      onHoverOrder,
      onHoverLeaveOrder,
      hoveredRiderId,
      onRiderToggleHover,
    ],
  );
};
