import { z } from 'zod';

const totalPriceBreakdownSchema = z.object({
  taxAmountCents: z.number().optional(),
  priceCents: z.number(),
});

export const nashQuoteResponseSchema = z.object({
  id: z.string(),
  createdTime: z.string(),
  expireTime: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerIsInternal: z.boolean(),
  currency: z.string(),
  priceCents: z.number(),
  taxAmountCents: z.number().nullable(),
  nashFeeCents: z.number().nullable(),
  totalPriceCents: z.number(),
  totalPriceBreakdown: totalPriceBreakdownSchema,
  providerLogo: z.string().nullish(),
  pickupWindow: z.string().nullish(),
  dropoffEta: z.string().nullish(),
  tollFeeCents: z.number().nullish(),
  tags: z.string().nullish(),
  type: z.string().nullish(),
});

export const nashFailedQuoteSchema = z.object({
  id: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  errorMessage: z.string().nullable(),
});

export const nashGetQuotesResponseSchema = z.object({
  id: z.string(),
  externalId: z.string().nullable(),
  quotes: z.array(nashQuoteResponseSchema),
  failedQuotes: z.array(nashFailedQuoteSchema).nullish(),
  portalUrl: z.string(),
  publicTrackingUrl: z.string().nullish(),
});

export const nashGetRefreshQuotesResponseSchema = z.object({
  quotes: z.array(nashQuoteResponseSchema),
});

export const nashRefreshQuotesResponseSchema =
  nashGetRefreshQuotesResponseSchema;

export const nashSelectQuoteResponseSchema = z.object({
  job: z.object({
    id: z.string(),
    createdAt: z.string(),
    portalUrl: z.string(),
    jobConfigurations: z.array(
      z.object({
        publicTrackingUrl: z.string().nullish(),
      }),
    ),
  }),
});

export const NashDeliveryModeSchema = z.enum(['scheduled', 'now']);

const itemDimensionsSchema = z.object({
  depth: z.number().nullable(),
  height: z.number().nullable(),
  width: z.number().nullable(),
});

const itemSchema = z.object({
  description: z.string().nullable().optional(),
  count: z.number(),
  dimensions: itemDimensionsSchema,
  weight: z.number(),
});

export const nashGetQuotesRequestBodySchema = z.object({
  pickupPhoneNumber: z.string(),
  pickupBusinessName: z.string().nullable(),
  dropoffPhoneNumber: z.string(),
  dropoffBusinessName: z.string().nullable(),
  deliveryMode: NashDeliveryModeSchema,
  externalId: z.string(),
  pickupStartTime: z.string().optional(),
  pickupEndTime: z.string().optional(),
  dropoffStartTime: z.string().optional(),
  dropoffEndTime: z.string(),
  description: z.string().nullable().optional(),
  currency: z.literal('GBP'),
  valueCents: z.string().nullable(),
  requirements: z.array(z.string()),
  referenceId: z.string().nullable().optional(),
  pickupAddressNumber: z.string().nullable(),
  pickupAddressFormattedStreet: z.string(),
  pickupAddressCity: z.string(),
  pickupAddressState: z.string(),
  pickupAddressZip: z.string(),
  pickupAddressCountry: z.string(),
  pickupLat: z.number().optional(),
  pickupLng: z.number().optional(),
  dropoffAddressNumber: z.string().nullable(),
  dropoffAddressFormattedStreet: z.string(),
  dropoffAddressCity: z.string(),
  dropoffAddressState: z.string(),
  dropoffAddressZip: z.string(),
  dropoffAddressCountry: z.string(),
  dropoffLat: z.number().optional(),
  dropoffLng: z.number().optional(),
  items: z.array(itemSchema),
});

export const NashUpdateOrderRequestBodySchema = z
  .object({
    externalId: z.string().nullable(),
    pickupAddress: z.string().nullable(),
    pickupPlaceId: z.string().nullable(),
    pickupPhoneNumber: z.string(),
    pickupBusinessName: z.string().nullable(),
    pickupFirstName: z.string().nullable(),
    pickupLastName: z.string().nullable(),
    pickupInstructions: z.string().nullable(),
    pickupStoreLocationId: z.string().nullable(),
    pickupExternalStoreLocationId: z.string().nullable(),
    pickupBarcodes: z.array(z.string()).nullable(),
    pickupEmail: z.string().nullable(),
    dropoffAddress: z.string().nullable(),
    dropoffPlaceId: z.string().nullable(),
    dropoffPhoneNumber: z.string().nullable(),
    dropoffBusinessName: z.string().nullable(),
    dropoffFirstName: z.string().nullable(),
    dropoffLastName: z.string().nullable(),
    dropoffInstructions: z.string().nullable(),
    dropoffBarcodes: z.array(z.string()).nullable(),
    dropoffEmail: z.string().nullable(),
    deliveryMode: NashDeliveryModeSchema.nullable(),
    pickupStartTime: z.string().optional(),
    pickupEndTime: z.string().optional(),
    dropoffStartTime: z.string().optional(),
    dropoffEndTime: z.string().optional(),
    description: z.string().nullable(),
    itemsCount: z.number().nullable(),
    currency: z.literal('GBP'),
    valueCents: z.string().nullable(),
    tipAmountCents: z.string().nullable(),
    requirements: z.array(z.string()),
    minimumVehicleSize: z.string().nullable(),
    dispatchStrategyId: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    referenceId: z.string().nullable(),
    pickupAddressNumber: z.string().nullable(), // The number on a house.
    pickupAddressSecondarynumber: z.string().nullable(), // Secondary number on an address e.g. an apartment building.
    pickupAddressFormattedStreet: z.string().nullable(),
    pickupAddressCity: z.string().nullable(),
    pickupAddressCounty: z.string().nullable(),
    pickupAddressState: z.string().nullable(),
    pickupAddressZip: z.string().nullable(),
    pickupAddressCountry: z.string().nullable(),
    pickupLat: z.number().nullable(),
    pickupLng: z.number().nullable(),
    dropoffAddressNumber: z.string().nullable(), // The number on a house.
    dropoffAddressSecondarynumber: z.string().nullable(), // Secondary number on an address e.g. an apartment building.
    dropoffAddressFormattedStreet: z.string().nullable(),
    dropoffAddressCounty: z.string().nullable(),
    dropoffAddressState: z.string().nullable(),
    dropoffAddressCity: z.string().nullable(),
    dropoffAddressZip: z.string(),
    dropoffAddressCountry: z.string(),
    dropoffLat: z.number().nullable(),
    dropoffLng: z.number().nullable(),
    weight: z.number().nullable(),
    height: z.number().nullable(),
    depth: z.number().nullable(),
    width: z.number().nullable(),
    requestedDeliveryWindow: z
      .object({
        deliveryWindowId: z.string(),
        date: z.string(),
        shifts: z.array(z.string()),
        capacity: z.number().nullable(),
      })
      .nullable(),
    items: z.array(itemSchema),
  })
  .partial();

export const nashRefreshQuotesRequestBodySchema = z.object({
  orderId: z.string(), // this is the Nash order ID, not the internal order ID
});

export const nashSelectQuoteRequestBodySchema = z.object({
  quoteId: z.string(),
});

export const NashUpdateJobRequestBodySchema = z
  .object({
    pickupPhoneNumber: z.string().nullable(),
    pickupBusinessName: z.string().nullable(),
    pickupFirstName: z.string().nullable(),
    pickupLastName: z.string().nullable(),
    pickupInstructions: z.string().nullable(),
    pickupStoreLocationId: z.string().nullable(),
    pickupBarcodes: z.array(z.string()).nullable(),
    dropoffPhoneNumber: z.string().nullable(),
    dropoffFirstName: z.string().nullable(),
    dropoffLastName: z.string().nullable(),
    dropoffBusinessName: z.string().nullable(),
    dropoffInstructions: z.string().nullable(),
    dropoffBarcodes: z.array(z.string()).nullable(),
    packageDescription: z.string().nullable(),
    itemsCount: z.number().min(1),
    currency: z.string(),
    packageValue: z.number().nullable(),
    tipAmountCents: z.number().nullable(),
    packageRequirements: z.array(z.string()).nullable(),
    jobMetadata: z.record(z.string(), z.string()).nullable(),
    referenceId: z.string().nullable(),

    // can't update these fields during dispatch
    packagePickupStartTime: z.string().nullable(),
    packagePickupEndTime: z.string().nullable(),
    packageDropoffStartTime: z.string().nullable(),
    packageDropoffEndTime: z.string().nullable(),
    pickupAddress: z.string().nullable(),
    dropoffAddress: z.string().nullable(),
  })
  .partial();

export const NashProofOfDeliveryTypeSchema = z.enum([
  'photo_proof_of_pickup',
  'signature_proof_of_pickup',
  'photo_proof_of_delivery',
  'signature_proof_of_delivery',
]);

export const NashProofOfDeliverySchema = z.object({
  type: NashProofOfDeliveryTypeSchema,
  url: z.string(),
});

export const NashDeliverySchema = z.object({
  id: z.string(),
  type: z.string(),
  status: z.string(),
  proofOfDelivery: z.array(NashProofOfDeliverySchema).nullish(),
  pickupEta: z.string().nullable(),
  dropoffEta: z.string().nullable(),
  priceCents: z.number().nullable(),
  currency: z.string().nullable(),
  nashFeeCents: z.number().nullable(),
  courierName: z.string().nullable(),
  courierPhoneNumber: z.string().nullable(),
  courierLocation: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .nullable(),
});

export const NashTaskSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  status: z.string(),
  providerId: z.string().nullable(),
  delivery: NashDeliverySchema.nullable(),
  pickupStartTime: z.string().nullable(),
  pickupEndTime: z.string().nullable(),
  dropoffStartTime: z.string().nullable(),
  dropoffEndTime: z.string().nullable(),
  quotes: z.array(nashQuoteResponseSchema).nullish(),
  winnerQuote: nashQuoteResponseSchema
    .pick({
      id: true,
      providerId: true,
      providerName: true,
    })
    .nullish(),
});

export const NashJobConfigurationSchema = z.object({
  tasks: z.array(NashTaskSchema),
  delivery: NashDeliverySchema.nullish(),
  package: z.object({
    pickupEndTime: z.string().nullish(),
    pickupStartTime: z.string().nullish(),
    dropoffEndTime: z.string().nullish(),
    dropoffStartTime: z.string().nullish(),
  }),
  publicTrackingUrl: z.string().nullish(),
});

export const NashJobSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  portalUrl: z.string(),
  externalIdentifier: z.string().nullable(),
  isActive: z.boolean(),
  jobConfigurations: z.array(NashJobConfigurationSchema),
});

export const NashGetJobResponseSchema = NashJobSchema;

export type NashQuote = z.infer<typeof nashQuoteResponseSchema>;

export type NashQuoteResponse = z.infer<typeof nashQuoteResponseSchema>;

export type NashTask = z.infer<typeof NashTaskSchema>;

export type NashFailedQuote = z.infer<typeof nashFailedQuoteSchema>;

export type NashGetQuotesResponse = z.infer<typeof nashGetQuotesResponseSchema>;

export type NashRefreshQuotesResponse = z.infer<
  typeof nashRefreshQuotesResponseSchema
>;

export type NashProofOfDeliveryType = z.infer<
  typeof NashProofOfDeliveryTypeSchema
>;

export type NashDeliveryModeType = z.infer<typeof NashDeliveryModeSchema>;

export type NashExternalQuoteRequestBody = z.infer<
  typeof nashGetQuotesRequestBodySchema
>;

export type NashUpdateOrderRequestBody = z.infer<
  typeof NashUpdateOrderRequestBodySchema
>;

export type NashUpdateJobRequestBody = z.infer<
  typeof NashUpdateJobRequestBodySchema
>;

export type NashRefreshQuotesRequestBody = z.infer<
  typeof nashRefreshQuotesRequestBodySchema
>;

export type NashSelectQuoteRequestBody = z.infer<
  typeof nashSelectQuoteRequestBodySchema
>;
export type NashGetJobResponse = z.infer<typeof NashGetJobResponseSchema>;
