import { z } from 'zod';
import { WebhookConfigSchema } from '../../../notifications';

export const DeleteWebhookConfigResponseSchema = WebhookConfigSchema.pick({
  eventId: true,
});

export type DeleteWebhookConfigResponse = z.infer<
  typeof DeleteWebhookConfigResponseSchema
>;
