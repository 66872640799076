import { z } from 'zod';
import { EventTypeSchema } from './event-types';

/**
 * Push Notification Data
 * @description
 * This is the data that is used to generate push notifications
 */
const OrderCancelledNotificationDataSchema = z.object({
  riderId: z.string(),
  riderPushToken: z.string(),
  pickUpPostcode: z.string(),
});

const DeliveryCancelledNotificationDataSchema = z.object({
  orderId: z.string(),
  deliveryId: z.string(),
  riderId: z.string(),
  riderPushToken: z.string(),
  pickUpPostcode: z.string(),
});

const DeliveryAssignedNotificationDataSchema = z.object({
  deliveryId: z.string(),
  pickUpPostcode: z.string(),
  deliveryPostcode: z.string(),
  riderId: z.string(),
  riderPushToken: z.string(),
});

const DeliveryUnassignedNotificationDataSchema =
  DeliveryAssignedNotificationDataSchema;

const ScheduleUpdatedNotificationDataSchema = z.object({
  riderId: z.string(),
  nextStopPostcode: z.string(),
  riderPushToken: z.string(),
});

const FleetVehicleActionedNotificationDataSchema = z.object({
  riderId: z.string(),
  regNumber: z.string(),
  riderPushToken: z.string(),
});

const RiderActionedNotificationDataSchema = z.object({
  riderId: z.string(),
  riderPushToken: z.string(),
});
const GigJobCreatedActionedNotificationDataSchema = z.object({
  gigJobId: z.string(),
  riderPushToken: z.string(),
  riderId: z.string(),
  postCodes: z.string(),
});

export const PushNotificationInputSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(EventTypeSchema.Values.SCHEDULE_UPDATED),
    data: ScheduleUpdatedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
    data: z.array(OrderCancelledNotificationDataSchema),
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
    data: z.array(OrderCancelledNotificationDataSchema),
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
    data: DeliveryCancelledNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
    data: DeliveryCancelledNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_ASSIGNED),
    data: DeliveryAssignedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_UNASSIGNED),
    data: DeliveryUnassignedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_APPROVED),
    data: FleetVehicleActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_REJECTED),
    data: FleetVehicleActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.RIDER_APPROVED),
    data: RiderActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.RIDER_REJECTED),
    data: RiderActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.GIG_JOB_CREATED),
    data: z.array(GigJobCreatedActionedNotificationDataSchema),
  }),
]);

// Export push notification types
export type ScheduleUpdatedNotificationData = z.infer<typeof ScheduleUpdatedNotificationDataSchema>;
export type OrderCancelledNotificationData = z.infer<typeof OrderCancelledNotificationDataSchema>;
export type GigJobCreatedNotificationData = z.infer<typeof GigJobCreatedActionedNotificationDataSchema>;
export type DeliveryCancelledNotificationData = z.infer<typeof DeliveryCancelledNotificationDataSchema>;
export type DeliveryAssignedNotificationData = z.infer<typeof DeliveryAssignedNotificationDataSchema>;
export type FleetVehicleActionedNotificationData = z.infer<typeof FleetVehicleActionedNotificationDataSchema>;
export type RiderActionedNotificationData = z.infer<typeof RiderActionedNotificationDataSchema>;
export type PushNotificationInput = z.infer<typeof PushNotificationInputSchema>; 