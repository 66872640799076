import { SuccessfulQuoteSchema, FailedQuoteSchema } from '../../../models/external-quote-schema';
import { z } from "zod";

export const GetQuotesResponseSchema = z.object({
  successfulQuotes: z.array(SuccessfulQuoteSchema),
  failedQuotes: z.array(FailedQuoteSchema),
});

export type GetQuotesResponse = z.infer<
  typeof GetQuotesResponseSchema
>;

