import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Stack,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  Box,
  Text,
} from '@chakra-ui/react';
import {
  Address,
  MerchantDefinedCompanyAddressSchema,
  MerchantDefinedCompanyAddress,
  Position,
  GetLookupAddressResponse,
} from '@tradeaze-packages/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSection } from '../FormSection';
import { GrLocation } from 'react-icons/gr';
import { FindAddressesInput } from '../../location/FindAddressesInput';
import {
  getAddressesQueryKey,
  useCreateMerchantCompanyAddress,
  useGetManualAddressPosition,
} from '@tradeaze/frontend/hooks';
import { toast } from 'react-hot-toast';

export interface AddressFormProps {
  address?: Address;
  companyId?: string;
  merchantId?: string;
  onSubmit: () => void;
}

export const AddressForm = ({
  address,
  companyId,
  merchantId,
  onSubmit,
}: AddressFormProps) => {
  const methods = useForm<MerchantDefinedCompanyAddress>({
    defaultValues: address ?? {
      companyId,
      merchantId,
      addressType: 'DROP_OFF',
    },
    mode: 'all',
    resolver: zodResolver(MerchantDefinedCompanyAddressSchema),
  });

  const {
    mutateAsync: createMerchantCustomerAddress,
    isLoading: isLoadingCreateMerchantCustomerAddress,
  } = useCreateMerchantCompanyAddress({
    invalidateQueryKeys: [
      getAddressesQueryKey({
        addressType: 'DROP_OFF',
        companyId,
        merchantId,
      }),
    ],
    onSuccess: () => {
      onSubmit();
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isValid },
    clearErrors,
  } = methods;

  const postCode = watch('postCode');

  const handleFormSubmit = handleSubmit(
    (data) => {
      createMerchantCustomerAddress(data);
    },
    () => {
      toast.error('Invalid form');
    }
  );

  const handleSetPosition = useCallback(
    (position: Position | undefined) => {
      setValue('position', position);
    },
    [setValue]
  );

  const [isManuallyEditingAddress, setIsManuallyEditingAddress] =
    useState(false);

  useGetManualAddressPosition({
    isManualAddress: isManuallyEditingAddress,
    postCode,
    handleSetPosition,
  });

  const handleSelectDeliveryLocation = (address: GetLookupAddressResponse) => {
    const position = {
      latitude: address.latitude,
      longitude: address.longitude,
    };
    setValue('addressLine1', address.line_1);
    setValue('postCode', address.postcode);
    setValue('city', address.town_or_city);
    setValue('position', position);

    clearErrors('addressLine1');
    clearErrors('postCode');
    clearErrors('city');

    setIsManuallyEditingAddress(true);
  };

  const handleManualAddress = () => {
    setIsManuallyEditingAddress(true);
    setValue('addressLine1', '');
    setValue('postCode', '');
    setValue('city', '');
    setValue('position', undefined);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Stack spacing={4}>
          <FormSection name="Address" icon={<GrLocation />}>
            <Stack spacing={4}>
              <Box>
                <FindAddressesInput
                  onAddressSelected={handleSelectDeliveryLocation}
                />
                <Text
                  fontSize={12}
                  decoration={'underline'}
                  cursor={'pointer'}
                  my={2}
                  onClick={handleManualAddress}
                  color={'blackAlpha.500'}
                  data-cy="manual-address-link"
                >
                  Can't find address? Click here to enter manually
                </Text>
              </Box>
              {isManuallyEditingAddress ? (
                <>
                  <FormControl
                    id="addressLine1"
                    isInvalid={Boolean(errors.addressLine1)}
                  >
                    <Input
                      id="addressLine1"
                      placeholder="Address"
                      {...register('addressLine1')}
                    />
                    <FormErrorMessage>
                      {errors.addressLine1?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="postCode"
                    isInvalid={Boolean(errors.postCode)}
                  >
                    <Input
                      id="postCode"
                      placeholder="Post Code"
                      {...register('postCode')}
                    />
                    <FormErrorMessage>
                      {errors.postCode?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl id="city" isInvalid={Boolean(errors.city)}>
                    <Input id="city" placeholder="City" {...register('city')} />
                    <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
                  </FormControl>
                </>
              ) : null}
            </Stack>
          </FormSection>
          <Button
            isLoading={isLoadingCreateMerchantCustomerAddress}
            onClick={handleFormSubmit}
            isDisabled={!isValid}
            data-cy="save-address-button"
          >
            Save
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AddressForm;
