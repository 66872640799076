import { Center, Container, Heading, Spinner, Show, Box } from '@chakra-ui/react';
import {
  useCreateMerchantAccount,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import { CreateMerchantProfile } from '@tradeaze-packages/schemas';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';
import {
  TwoColumnLayout,
  buildDefaultMerchantAccountDetails,
  useFormMapMarkers,
  PageErrorWithRetry,
  NAVBAR_HEIGHT_PX,
} from '@tradeaze/frontend/ui-web';
import { Layout, Map, MerchantAccountForm } from '@tradeaze/frontend/ui-web';

const CreateAccountPage = () => {
  const navigate = useNavigate();

  const { mapMarkers, addMerchantMarker, removeMerchantMarker } =
    useFormMapMarkers();

  const { cognitoUserId, cognitoUsername } = useAuthContext();

  const { data: merchantAccount, isLoading, error, refetch } =
    useGetMerchantAccount(cognitoUserId);

  const is404 = error?.status === 404;

  const { mutate: createMerchantAccount, isLoading: isLoadingCreate } =
    useCreateMerchantAccount({
      onSuccess: () => {
        navigate('/');
      },
    });

  const handleSaveChanges = async (data: CreateMerchantProfile) => {
    if (!cognitoUserId) {
      toast.error(
        'Cannot create an account without a user id. Please login again.'
      );
      return;
    }
    if (!cognitoUsername) {
      toast.error(
        'Cannot create merchant account without a username. Please login again.'
      );
      return;
    }
    createMerchantAccount({
      ...data,
      merchantId: cognitoUserId,
      username: cognitoUsername,
    });
  };

  if (!cognitoUserId || !cognitoUsername) {
    return (
      <Center h="60vh">
        <Heading size="sm">
          You must be logged in to create a merchant account.
        </Heading>
      </Center>
    );
  }

  if (isLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (error instanceof Error && !is404) {
    return (
      <PageErrorWithRetry
        text={error.message || 'Failed to load account information'}
        onRetry={refetch}
        isLoading={isLoading}
      />
    );
  }

  if (merchantAccount) {
    return <Navigate to={'/account'} />;
  }

  const CreateAccountComponent = (
    <Container maxW={'container.sm'}>
      <Heading size="md" mb={6}>
        Create Merchant Account
      </Heading>
      <MerchantAccountForm
        handleSaveChanges={handleSaveChanges}
        isLoading={isLoadingCreate}
        isCreatingNew={true}
        removeMerchantMarker={removeMerchantMarker}
        handleAddMerchantMarker={addMerchantMarker}
        initialAccountDetails={buildDefaultMerchantAccountDetails({
          merchantId: cognitoUserId,
          username: cognitoUsername,
        })}
      />
    </Container>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={<Box mt={NAVBAR_HEIGHT_PX}>{CreateAccountComponent}</Box>}
          right={<Map markers={mapMarkers} />}
        />
      </Show>
      <Show below="md">
        <Layout>{CreateAccountComponent}</Layout>
      </Show>
    </>
  );
};

export default CreateAccountPage;
