import { Validator } from '@tradeaze-packages/schemas';
import {
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

export const useRegisterAndFormat = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
) => {
  return <
    TFieldName extends FieldPath<TFieldValues>,
    TFieldType extends PathValue<TFieldValues, TFieldName>,
  >(
    name: TFieldName,
    validator: Validator<TFieldType>,
    options?: RegisterOptions<TFieldValues, TFieldName>,
  ) => {
    return form.register(name, {
      ...options,
      onChange: (e) => {
        const normalisedValue = validator.normalise(e.target.value);
        if (normalisedValue !== e.target.value) {
          form.setValue(name, normalisedValue as TFieldType);
          form.trigger(name);
        }
        options?.onChange?.(e);
      },
    });
  };
};
