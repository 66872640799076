import { Box } from '@chakra-ui/react';
import React from 'react';
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_PX } from '../navbar';

type Props = React.ComponentProps<typeof Box> & {
  isAuthScreen?: boolean;
  lowPadding?: boolean;
};

export const Layout = ({
  children,
  isAuthScreen,
  lowPadding,
  ...rest
}: Props) => {
  const mobilePaddingTop = `${NAVBAR_HEIGHT + 24}px`;
  const desktopWidth = isAuthScreen
    ? '100%'
    : `calc(100% - ${NAVBAR_HEIGHT_PX})`;
  const px = [4, 6, 6, 12];

  return (
    <Box
      position="relative"
      height="100vh"
      overflowY="auto"
      left={[0, 0, 0, isAuthScreen ? 0 : NAVBAR_HEIGHT_PX]}
      top={0}
      px={px}
      pt={[mobilePaddingTop, mobilePaddingTop, mobilePaddingTop, 8]}
      pb={36}
      width={['100%', '100%', '100%', desktopWidth]}
      {...rest}
    >
      <Box maxWidth="container.xxl" marginX="auto" minHeight="100%">
        {children}
      </Box>
    </Box>
  );
};
