import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { lookupAddresses } from '@tradeaze-packages/api-client';

type Response = Awaited<ReturnType<typeof lookupAddresses>>;

export const useFindAddresses = (
  postcode: string,
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryKey: ['findAddresses', postcode],
    queryFn: async () => await lookupAddresses({ postcode }),
    enabled: false, // use refetch to trigger,
    ...options,
  });
