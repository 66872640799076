import { Stack, Text, HStack, Icon } from '@chakra-ui/react';
import { SiteContact } from '@tradeaze-packages/schemas';
import { BaseCard } from '../shared';
import { FaUser, FaPhone } from 'react-icons/fa';
import { DeleteButton } from './DeleteButton';

interface SiteContactBookCardProps {
  siteContact: SiteContact;
  onDelete?: () => void;
  isDeleting?: boolean;
}

export const SiteContactBookCard = ({
  siteContact,
  onDelete,
  isDeleting,
}: SiteContactBookCardProps) => {
  return (
    <BaseCard isClickable={false}>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Stack spacing={2}>
          <HStack spacing={2} alignItems="center">
            <Icon as={FaUser} color="gray.500" boxSize={4} />
            <Text
              fontSize="md"
              fontWeight="medium"
              color="gray.700"
              data-cy="site-contact-name"
            >
              {siteContact.contactName}
            </Text>
          </HStack>
          <HStack spacing={2} alignItems="center">
            <Icon as={FaPhone} color="gray.500" boxSize={3.5} />
            <Text fontSize="sm" color="gray.600" data-cy="site-contact-number">
              {siteContact.contactNumber}
            </Text>
          </HStack>
        </Stack>
        {onDelete && (
          <DeleteButton
            title="Delete Site Contact"
            description="Are you sure you want to delete this site contact?"
            onConfirm={onDelete}
            isLoading={isDeleting}
            dataCy="delete-site-contact"
          />
        )}
      </Stack>
    </BaseCard>
  );
};
