import { IoOptions } from 'react-icons/io5';
import { OrderSection } from '../../order-section';
import { DeliveryVehicles } from './DeliveryVehicles';
import {
  CreateDeliveryItem,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { DeliveryDate } from './DeliveryDate';
import { DeliveryOption } from './DeliveryOption';
import { DeliveryPrice } from './DeliveryPrice';
import { Box, Divider, Show } from '@chakra-ui/react';
import { useDeliveryDetailsSection } from '../../hooks';
import { VehicleModal } from '../../vehicle-modal';
import React from 'react';
import { PreviewVehicleButton } from '../../PreviewVehicleButton';
import { DeliveryWindow } from './DeliveryWindow';

type DeliveryDetailsSectionProps = {
  sectionName: string;
  merchantAccount?: MerchantAccount;
  initialDate?: Date;
  isAdmin: boolean;
  hidePriorityDelivery: boolean;
  isAnyStopOverThreshold: boolean;
  isDeliveryPriceLoading: boolean;
  itemDetails: CreateDeliveryItem;
  isSectionComplete: boolean;
  deliveryPriceErrorMessage: string | null;
  isPrimaryView?: boolean;
  setIsPrimaryView?: React.Dispatch<React.SetStateAction<boolean>>;
  isVehicleModalOpen?: boolean;
  setIsVehicleModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  hideSection?: boolean;
  isMultiDrop?: boolean;
};

export const DeliveryDetailsSection = ({
  sectionName,
  merchantAccount,
  initialDate,
  hidePriorityDelivery,
  isAdmin,
  isAnyStopOverThreshold,
  isDeliveryPriceLoading,
  itemDetails,
  isSectionComplete,
  deliveryPriceErrorMessage,
  isPrimaryView = true,
  setIsPrimaryView,
  isVehicleModalOpen = false,
  setIsVehicleModalOpen,
  hideSection = false,
  isMultiDrop = false,
}: DeliveryDetailsSectionProps) => {
  const {
    form,
    filteredVehicles,
    handleOptionAndItemsOnDeliveryVehicle,
    fromDeliveryDateTime,
    deliveryOptionsByVehicle,
    handleSelectDeliveryDate,
    isSaturday,
    isPastDate,
    deliveryVehicleId,
    deliveryOptionId,
    deliveryOptionsError: optionsError, 
    deliveryOptionsLoading: optionsLoading,
    isInvalidScheduledTime,
    scheduledTime,
    handleChangeScheduledTime,
    isVehicleAvailable,
    deliveryWindowStart,
    deliveryWindowEnd,
    showDeliveryPrice,
    deliveryPrice,
    serviceCharge,
    totalTradeazeCharges,
    selectedVehicleDetail,
    scaleDimensionsMaintainingAspectRatio,
  } = useDeliveryDetailsSection({
    merchantAccount,
    initialDate,
    hidePriorityDelivery,
    isAdmin,
    isAnyStopOverThreshold,
    itemDetails,
    isMultiDrop,
  });

  return (
    <OrderSection
      name={sectionName}
      icon={<IoOptions />}
      isComplete={isSectionComplete}
      hideSection={hideSection}
    >
      <Box display={'flex'} flexDirection={'column'} gap={8}>
        <DeliveryVehicles
          form={form}
          filteredVehicles={filteredVehicles}
          handleOptionAndItemsOnDeliveryVehicle={
            handleOptionAndItemsOnDeliveryVehicle
          }
        />
        <DeliveryDate
          fromDeliveryDateTime={fromDeliveryDateTime}
          handleSelectDeliveryDate={handleSelectDeliveryDate}
          isAdmin={isAdmin}
          isSaturday={isSaturday}
          isPastDate={isPastDate}
        />
        <DeliveryOption
          deliveryVehicleId={deliveryVehicleId}
          isAnyStopOverThreshold={isAnyStopOverThreshold}
          deliveryOptionsQueryLoading={optionsLoading}
          deliveryOptionsByVehicle={deliveryOptionsByVehicle}
          deliveryOptionsQueryError={optionsError}
          deliveryOptionId={deliveryOptionId}
          isInvalidScheduledTime={isInvalidScheduledTime}
          scheduledTime={scheduledTime}
          handleChangeScheduledTime={handleChangeScheduledTime}
          isVehicleAvailable={isVehicleAvailable}
        />
        <Divider />
        <DeliveryWindow
          deliveryWindowStart={deliveryWindowStart}
          deliveryWindowEnd={deliveryWindowEnd}
          deliveryOptionsQueryLoading={optionsLoading}
        />
        <Divider />
        <DeliveryPrice
          isDeliveryPriceLoading={isDeliveryPriceLoading}
          showDeliveryPrice={showDeliveryPrice}
          isAdmin={isAdmin}
          deliveryPrice={deliveryPrice}
          serviceCharge={serviceCharge}
          totalTradeazeCharges={totalTradeazeCharges}
          deliveryPriceErrorMessage={deliveryPriceErrorMessage}
        />
      </Box>
      {isVehicleModalOpen !== undefined &&
      isPrimaryView !== undefined &&
      setIsVehicleModalOpen &&
      setIsPrimaryView ? (
        <Show above={'md'}>
          <VehicleModal
            isVehicleModalOpen={isVehicleModalOpen}
            setIsVehicleModalOpen={setIsVehicleModalOpen}
            selectedVehicle={selectedVehicleDetail}
            loadDimensions={scaleDimensionsMaintainingAspectRatio}
            isPrimaryView={isPrimaryView}
            setIsPrimaryView={setIsPrimaryView}
            isVehicleAvailable={isVehicleAvailable}
            measurementUnit={itemDetails.widthUnit}
          />
          <PreviewVehicleButton setIsVehicleModalOpen={setIsVehicleModalOpen} />
        </Show>
      ) : null}
    </OrderSection>
  );
};
