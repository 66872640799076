import { Auth } from 'aws-amplify';
import { globalExpireSession } from './globalExpireSession';
import { globalGetUser } from './globalGetAuthUser';

export const getJwt = async () => {
  try {
    const user = globalGetUser();
    if (!user) return null;

    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    globalExpireSession();
    return null;
  }
};

export const getAuthHeader =
  (): (() => Promise<{
    Authorization: string;
  }>) =>
  async () => {
    const jwt = await getJwt();

    return {
      Authorization: `Bearer ${jwt}`,
    };
  };
