import { EtaStatus } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';

export const buildStatusDescription = ({
  etaStatus,
  deliveryStatus,
  stopIndex,
  isToday,
  proofName,
  deliveredAt,
}: {
  etaStatus?: EtaStatus | null;
  deliveryStatus?: string;
  stopIndex?: number | null;
  isToday?: boolean;
  proofName?: string | null;
  deliveredAt?: string | null;
}) => {
  switch (etaStatus) {
    case 'CANCELLED':
      return `This delivery has been cancelled.`;
    case 'ASSIGNED': {
      if (deliveryStatus === 'PENDING') {
        return 'Awaiting confirmation - please check back for updates.';
      }
      if (!isToday) {
        return 'Delivery confirmed - please check back for updates.';
      }
      if (stopIndex === null || stopIndex === undefined) {
        return `Driver assigned - pickup is scheduled.`;
      }
      if (stopIndex === 0) {
        return `Driver assigned - pickup is their next stop.`;
      }
      if (stopIndex === 1) {
        return `Driver assigned - there's 1 stop before the pickup.`;
      }
      return `Driver assigned - there's ${stopIndex} stops before the pickup.`;
    }
    case 'PICKUP_ENROUTE':
      return `The driver is on the way to the pickup location.`;
    case 'PICKUP_ARRIVED':
      return 'The driver has arrived at the pickup location.';
    case 'PICKUP_COMPLETE': {
      if (stopIndex === null || stopIndex === undefined) {
        return `Pickup complete - awaiting drop-off.`;
      }
      if (stopIndex === 0) {
        return `Pickup complete - drop-off is the next stop.`;
      }
      if (stopIndex === 1) {
        return `Pickup complete - there's 1 stop before the drop-off.`;
      }
      return `Pickup complete - there's ${stopIndex} stops before the drop-off.`;
    }
    case 'PICKUP_FAILED':
      return `The driver was unable to collect this delivery.`;
    case 'DROPOFF_ENROUTE':
      return `The driver is on the way to the drop-off location.`;
    case 'DROPOFF_ARRIVED':
      return 'The driver has arrived at the drop-off location.';
    case 'DROPOFF_COMPLETE': {
      if (proofName && deliveredAt) {
        return `This delivery has been completed! It was received by ${proofName} at ${formatDate(
          deliveredAt,
        )}.`;
      }
      return 'This delivery has been completed!';
    }
    case 'DROPOFF_FAILED':
      return `The driver was unable to complete this delivery.`;
    case 'NOT_ASSIGNED': {
      if (deliveryStatus === 'PENDING') {
        return 'Awaiting confirmation - please check back for updates.';
      }
      return `Delivery confirmed - please check back for updates.`;
    }
    default:
      return '';
  }
};
