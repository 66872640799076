import { z } from 'zod';
import { EventTypeSchema } from './event-types';
import type { Block, KnownBlock } from '@slack/types';

/**
 * Slack Notification Data
 * @description
 * This is the data that is used to generate Slack notifications
 */
const CommonSlackNotificationDataSchema = z.object({
  channelId: z.string(),
  message: z.string().optional(),
  blocks: z.array(z.custom<Block | KnownBlock>()).optional(),
});

export const SlackNotificationInputSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(EventTypeSchema.Values.ORDER_CREATED),
    data: CommonSlackNotificationDataSchema,
  }),
]);

// Collection of all slack notification types
export type SlackNotificationInput = z.infer<typeof SlackNotificationInputSchema>;
