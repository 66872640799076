import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { FiLock } from 'react-icons/fi';
import {
  MerchantConfigKey,
  MerchantFormConfig,
} from '../merchant/config/merchantConfig';
import { FormAttributeAlert } from './FormAttributeAlert';
import { useFormConfig } from './FormConfigContext';
import { FormInputNote } from './FormInputNote';

type FormAttributeProps<T extends FieldValues> = React.ComponentProps<
  typeof Box
> &
  ReturnType<UseFormRegister<T>> & {
    id: Path<T>;
    label: string;
    description?: string;
    inputLabel?: string;
    error?: {
      message: string;
    };
    isRequired?: boolean;
    isDisabled?: boolean;
    isGreyedOut?: boolean;
    placeholder?: string;
    inputType?: 'text' | 'tel' | 'number' | 'textarea' | 'checkbox' | 'radio';
    options?: Array<{ value: string; label: string }>;
    disabledTooltip?: string;
    unit?: string;
    currency?: string;
    inputCypressId?: string;
    merchantConfigKey?: MerchantConfigKey | null;
    formName?: keyof MerchantFormConfig;
    control?: Control<T>;
  };

/**
 * Example use:
 *
 * ```jsx
 * <FormAttribute
 *   id={stopKeys.addressLine1}
 *   label="Address"
 *   isRequired
 *   isDisabled={!isManualAddress}
 *   placeholder="Address"
 *   mb={4}
 *   error={stopErrors?.addressLine1}
 *   {...register(stopKeys.addressLine1)}
 * />
 * ```
 *
 */
export const FormAttribute = React.forwardRef(
  <T extends FieldValues>(
    {
      id,
      label,
      description,
      inputLabel,
      error,
      placeholder,
      inputType,
      unit,
      currency,
      name,
      disabledTooltip,
      isRequired,
      isDisabled,
      isGreyedOut,
      onBlur,
      onChange,
      inputCypressId,
      merchantConfigKey,
      formName,
      options,
      control,
      ...props
    }: FormAttributeProps<T>,
    forwardedRef: React.Ref<any>,
  ) => {
    let input: React.ReactNode;

    const formAttributeConfig = useFormConfig(id);

    // There were complaints about the oapcity of important information when disabled
    const customDisabledStyles = {
      opacity: 1,
      cursor: 'not-allowed',
    };

    const inputProps = {
      id,
      placeholder,
      name,
      onBlur,
      onChange,
      ref: forwardedRef,
      sx: {
        ...(isGreyedOut
          ? {
              backgroundColor: '#F9F9F9',
              color: '#7F7F80',
              border: '1px solid #D5D5D5',
              _disabled: customDisabledStyles,
            }
          : { _disabled: customDisabledStyles }),
      },
    };

    switch (inputType) {
      case 'radio':
        input = control ? (
          <Controller
            name={id}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} isDisabled={isDisabled}>
                <Stack spacing={2} mt={2}>
                  {options?.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
        ) : null;
        break;
      case 'checkbox':
        input = (
          <Checkbox
            {...inputProps}
            sx={{
              ...inputProps.sx,
              marginTop: '8px',
            }}
          >
            {inputLabel}
          </Checkbox>
        );
        break;
      case 'textarea':
        input = <Textarea {...inputProps} />;
        break;
      case 'number': {
        if (unit) {
          input = (
            <InputGroup>
              <Input
                {...inputProps}
                type={'number'}
                step={0.01}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <InputRightElement
                pointerEvents="none"
                color="blackAlpha.600"
                fontSize="1.2em"
                children={unit}
              />
            </InputGroup>
          );
        } else if (currency) {
          input = (
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="blackAlpha.600"
                fontSize="1.2em"
                children={currency}
              />
              <Input
                {...inputProps}
                type={'number'}
                step={0.01}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </InputGroup>
          );
        } else {
          input = <Input {...inputProps} type={'number'} step={0.01} />;
        }
        break;
      }
      case 'text':
      case 'tel':
      default:
        input = (
          <Input
            {...inputProps}
            {...(inputType === 'tel' && { type: inputType })}
            data-cy={inputCypressId}
          />
        );
        break;
    }

    return (
      <Box {...props}>
        <FormControl
          isRequired={isRequired}
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
        >
          <Flex>
            <FormLabel
              htmlFor={id}
              mr={2}
              sx={{ _disabled: customDisabledStyles }}
            >
              {label}
            </FormLabel>
            {!isRequired ? (
              <Text color={'blackAlpha.500'} textAlign={'right'}>
                Optional
              </Text>
            ) : null}
          </Flex>
          {disabledTooltip && isDisabled ? (
            <InputGroup>
              {input}
              {isDisabled && (
                <Tooltip
                  borderRadius={'md'}
                  label={disabledTooltip}
                  aria-label={disabledTooltip}
                >
                  <InputRightElement>
                    <Icon as={FiLock} />
                  </InputRightElement>
                </Tooltip>
              )}
            </InputGroup>
          ) : (
            input
          )}
          {description ? <FormInputNote>{description}</FormInputNote> : null}
          <FormErrorMessage>
            <Text data-cy={`${id.toLocaleLowerCase()}-error`}>
              {error?.message}
            </Text>
          </FormErrorMessage>
        </FormControl>
        {formAttributeConfig?.alert ? (
          <Box mt={2} mb={4}>
            <FormAttributeAlert {...formAttributeConfig.alert} />
          </Box>
        ) : null}
      </Box>
    );
  },
);
