import { z } from 'zod';
import { processStringBoolean } from '../../../utils';

export const UnassignRiderBodySchema = z.object({
  shouldCancelAcceptedGigJobs: z.preprocess(processStringBoolean, z.boolean()),
  shouldCancelPendingGigJobs: z.preprocess(processStringBoolean, z.boolean()),
});

export const UnassignRiderResponseSchema = z.object({
  deliveryId: z.string(),
  riderId: z.string().nullable(),
});

export const UnassignRiderErrorSchema = z.object({
  message: z.string(),
});

export type UnassignRiderBody = z.infer<typeof UnassignRiderBodySchema>;
export type UnassignRiderResponse = z.infer<typeof UnassignRiderResponseSchema>;
export type UnassignRiderError = z.infer<typeof UnassignRiderErrorSchema>;
