import { z } from 'zod';
import { ExternalFleetIdSchema } from './external-fleet';

export const ExternalQuoteRequestStatusSchema = z.enum([
  'PENDING',
  'ACCEPTED',
  'JOB_CANCELLED'
]);

export const ExternalQuoteRequestSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  externalId: z.string().nullable(),
  externalFleetId: ExternalFleetIdSchema,
  deliveryId: z.string(),
  deliveryDeadline: z.string(),
  status: ExternalQuoteRequestStatusSchema,
  selectedQuoteId: z.string().nullable(),
  returnedQuotes: z.record(z.unknown()).nullish(),
});

export const CreateExternalQuoteRequestSchema = ExternalQuoteRequestSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type ExternalQuoteRequestStatus = z.infer<typeof ExternalQuoteRequestStatusSchema>;
export type CreateExternalQuoteRequest = z.infer<typeof CreateExternalQuoteRequestSchema>;
export type ExternalQuoteRequest = z.infer<typeof ExternalQuoteRequestSchema>; 