import {
  Box,
  Divider,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { UploadProof } from './UploadProof';
import {
  useInvalidateQueries,
  getOrderQueryKey,
} from '@tradeaze/frontend/hooks';
import { ButtonWithConfirmation } from '../../shared';
import { CloseIcon } from '@chakra-ui/icons';
import { Storage } from 'aws-amplify';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';

export const ProofOfDelivery: React.FC<
  React.ComponentProps<typeof Box> & {
    orderId: string;
    stopId?: string;
    bucket: string;
    showMessageIfEmpty?: boolean;
    allowUpload?: boolean;
    allowDelete?: boolean;
    podLeftAlign?: boolean;
    proofImages: HydratedDeliveryStop['proofImages'];
    proofSignatures: HydratedDeliveryStop['proofSignatures'];
  }
> = ({
  orderId,
  stopId,
  bucket,
  showMessageIfEmpty,
  allowUpload,
  allowDelete,
  podLeftAlign,
  proofImages,
  proofSignatures,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const podWidth = isMobile ? '100%' : podLeftAlign ? '50%' : '100%';

  const invalidateQueries = useInvalidateQueries();

  const onMutation = () => {
    invalidateQueries([getOrderQueryKey(orderId)]);
  };

  const onUploadComplete = () => {
    onMutation();
  };

  const handleDeleteImage = async (key: string) => {
    await Storage.remove(key.replace('public/', ''), {
      bucket,
      level: 'public',
    });
    onMutation();
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const allAttachments = useMemo<
    {
      key: string | null;
      uri: string;
    }[]
  >(() => {
    return [
      ...(proofImages?.map(({ key, url }) => ({ key, uri: url })) ?? []),
      ...(proofSignatures?.map(({ key, url }) => ({ key, uri: url })) ?? []),
    ];
  }, [proofImages, proofSignatures]);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const isEmpty = allAttachments.length === 0;

  const showAttachments = !isEmpty;
  const showEmptyMessage = isEmpty && showMessageIfEmpty;
  const showUpload = allowUpload && stopId;

  if (!showAttachments && !showEmptyMessage && !showUpload) {
    return null;
  }

  return (
    <>
      <Divider />
      <VStack alignItems={'flex-start'} mt={6} {...props}>
        <Text fontWeight={'bold'}>Attachments</Text>
        {showAttachments && (
          <HStack flexWrap={'wrap'} alignItems={'flex-start'} spacing={4}>
            {allAttachments.map((a, index) => (
              <Box key={index + a.uri} position={'relative'}>
                {allowDelete && a.key && (
                  <ButtonWithConfirmation
                    alertTitle="Delete Image"
                    alertDescription="Are you sure you want to delete this image?"
                    onConfirm={() => {
                      if (!a.key) {
                        return;
                      }
                      handleDeleteImage(a.key);
                    }}
                    position={'absolute'}
                    right={-2}
                    top={-2}
                    colorScheme="red"
                    size={'xs'}
                    padding={0}
                  >
                    <CloseIcon fontSize={9} />
                  </ButtonWithConfirmation>
                )}
                <Image
                  src={a.uri}
                  cursor={'pointer'}
                  border={'1px solid'}
                  borderColor={'blackAlpha.500'}
                  borderRadius={'md'}
                  boxSize={'120px'}
                  fit={'cover'}
                  alt={`Proof of delivery ${index + 1}`}
                  onClick={() => openImageViewer(index)}
                />
              </Box>
            ))}
          </HStack>
        )}
        {showEmptyMessage && <Text>No attachments</Text>}
        {showUpload && (
          <Box mt={3}>
            <UploadProof
              orderId={orderId}
              stopId={stopId}
              bucket={bucket}
              onUploadComplete={onUploadComplete}
            />
          </Box>
        )}
      </VStack>
      {showAttachments && isViewerOpen && (
        <ImageViewer
          src={allAttachments.map((a) => a.uri)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: '#ffffffa6',
            paddingTop: '150px',
            paddingBottom: '60px',
            width: podWidth,
          }}
          closeOnClickOutside={true}
          leftArrowComponent={<AiFillCaretLeft fontSize={42} color={'black'} />}
          rightArrowComponent={
            <AiFillCaretRight fontSize={42} color={'black'} />
          }
        />
      )}
    </>
  );
};
