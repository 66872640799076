import { QueryKey, useQuery } from '@tanstack/react-query';
import { getMerchantAccount } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getMerchantQueryKey = (id?: string | null): QueryKey => [
  'getMerchantAccount',
  { id },
];

export const useGetMerchantAccount = (
  cognitoUserId?: string | null,
  options?: CustomUseQueryOptions<typeof getMerchantAccount>,
) => {
  return useQuery({
    queryKey: getMerchantQueryKey(cognitoUserId),
    queryFn: () => getMerchantAccount(cognitoUserId as string),
    enabled: !!cognitoUserId,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
