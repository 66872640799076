import { z } from 'zod';
import {
  CreateGigJobSchema,
  DeliveryVehicleIdSchema,
  GigJobBoardSchema,
  GigJobSchema
} from '../../../models';

export const CreateGigJobBodySchema = CreateGigJobSchema.extend({
  deliveryVehicleIds: z.array(DeliveryVehicleIdSchema).min(1, 'At least one delivery vehicle should be selected.'),
})

export const CreateGigJobResponseSchema = GigJobSchema.extend({
  deliveryIds: z.array(z.string()),
  gigJobBoard: z.array(GigJobBoardSchema),
});

export type MakeGigJob = z.infer<typeof CreateGigJobSchema>;

export type CreateGigJobBody = z.infer<typeof CreateGigJobBodySchema>;

export type CreateGigJobResponse = z.infer<typeof CreateGigJobResponseSchema>;
