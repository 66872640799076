import { z } from 'zod';
import {
    NashJobSchema
} from './nash-model-schema';

export const NashWebhookTypeSchema = z.enum(['delivery', 'courier_location']);

export const NashDeliveryEventSchema = z.enum([
  'created',
  'assigned_driver',
  'not_assigned_driver',
  'pickup_enroute',
  'pickup_arrived',
  'pickup_complete',
  'dropoff_enroute',
  'dropoff_arrived',
  'dropoff_complete',
  'canceled_by_nash',
  'canceled_by_provider',
  'canceled_by_customer',
  'return_in_progress',
  'return_arrived',
  'returned',
  'failed',
]);

export const NashCourierLocationEventSchema = z.enum(['updated']);

export const NashWebhookEventSchema = z.union([
  NashDeliveryEventSchema,
  NashCourierLocationEventSchema,
]);

export const NashWebhookBodySchema = z.object({
  type: NashWebhookTypeSchema,
  event: NashWebhookEventSchema,
  data: NashJobSchema,
});

export type NashWebhookType = z.infer<typeof NashWebhookTypeSchema>;
export type NashDeliveryEvent = z.infer<typeof NashDeliveryEventSchema>;
export type NashCourierLocationEvent = z.infer<
  typeof NashCourierLocationEventSchema
>;
export type NashWebhookEvent = z.infer<typeof NashWebhookEventSchema>;
export type NashWebhookBody = z.infer<typeof NashWebhookBodySchema>;
