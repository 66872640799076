import { FaMapMarkerAlt } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { MarkerContainer } from './MarkerContainer';

export const DropOffMarker: React.FC<{
  stopSequence?: number;
  isCompleted?: boolean;
}> = ({ stopSequence, isCompleted }) => {
  return (
    <MarkerContainer
      tooltipText={`Drop Off${
        stopSequence !== undefined ? ` - ${stopSequence + 1}` : ''
      }`}
      tooltipIcon={<GiCheckMark size={16} color={'#38A169'} />}
      showTooltip={true}
      mode={isCompleted ? 'faded' : 'normal'}
    >
      <FaMapMarkerAlt size={34} color={'#38A169'} />
    </MarkerContainer>
  );
};
