import {
  Alert,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormAttribute } from '../form';
import { ManVanFormData, ManVanTermsSchema } from './types';

interface BookingFormProps {
  register: UseFormRegister<ManVanFormData>;
  control: Control<ManVanFormData>;
  errors: FieldErrors<ManVanFormData>;
  isSubmitting: boolean;
  isSuccess: boolean;
  minDate: string;
  handleSubmit: () => void;
}

export const ManVanBookingForm = ({
  register,
  control,
  errors,
  isSubmitting,
  isSuccess,
  minDate,
  handleSubmit,
}: BookingFormProps) => {
  return (
    <Stack spacing={6}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 4, md: 8 }}
      >
        <FormControl isRequired isInvalid={!!errors.date}>
          <FormLabel>Date Required</FormLabel>
          <Input type="date" {...register('date')} min={minDate} />
          <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
        </FormControl>
        <FormAttribute
          id="bookedBy"
          label="Booked By"
          error={errors.bookedBy}
          data-cy="booked-by"
          placeholder="Enter your name"
          width="100%"
          isRequired
          {...register('bookedBy')}
        />
        <FormAttribute
          id="contactNumber"
          label="Contact Number"
          error={errors.contactNumber}
          data-cy="contact-number"
          placeholder="Enter your phone number"
          width="100%"
          inputType="tel"
          isRequired
          {...register('contactNumber')}
        />
      </Stack>
      <FormAttribute
        id="termsAccepted"
        label="Terms and Conditions"
        error={errors.termsAccepted}
        data-cy="terms-and-conditions"
        inputType="radio"
        options={[
          {
            value: ManVanTermsSchema.Enum.WITHIN_M25,
            label: 'I agree to only use this van within & around the M25 area',
          },
          {
            value: ManVanTermsSchema.Enum.OUTSIDE_M25,
            label:
              'I want to take the van further afield - I will get in touch & accept the pricing may be different to above',
          },
        ]}
        width="100%"
        isRequired
        control={control}
      />
      <Button
        size="lg"
        isLoading={isSubmitting}
        loadingText="Submitting"
        onClick={handleSubmit}
        my={4}
      >
        Submit Enquiry
      </Button>
      {isSuccess && (
        <Alert
          status="success"
          variant="subtle"
          borderRadius="md"
          animation="none"
        >
          ManVan Hire Enquiry Submitted! We will be in touch shortly.
        </Alert>
      )}
    </Stack>
  );
};
