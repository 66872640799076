import { useCallback } from 'react';
import {
  MerchantFormConfig,
  merchantConfig,
  MerchantConfigKey,
} from '../merchant/config/merchantConfig';
import { FormAttributeConfig } from './FormConfigContext';
import { FormConfigProvider } from './FormConfigProvider';

interface MerchantFormConfigProviderProps {
  formName: keyof MerchantFormConfig;
  merchantConfigKey?: MerchantConfigKey | null;
  children: React.ReactNode;
}

export const MerchantFormConfigProvider: React.FC<
  MerchantFormConfigProviderProps
> = ({ formName, merchantConfigKey, children }) => {
  const getFormAttributeConfig = useCallback(
    (fieldName: string): FormAttributeConfig | null => {
      if (!merchantConfigKey) return null;

      const config =
        merchantConfig[merchantConfigKey]?.form?.[formName]?.[
          fieldName as keyof MerchantFormConfig[typeof formName]
        ];
      if (!config?.alert) return null;

      return {
        alert: {
          ...config.alert,
          logo: merchantConfig[merchantConfigKey]?.logo,
        },
      };
    },
    [merchantConfigKey, formName],
  );

  return (
    <FormConfigProvider getFormAttributeConfig={getFormAttributeConfig}>
      {children}
    </FormConfigProvider>
  );
};
