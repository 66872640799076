import {
  Alert,
  AlertIcon,
  Flex,
  Image,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { FormAttributeAlertConfig } from './FormConfigContext';

// Can extend this to include other props if needed
type FormAttributeAlertProps = FormAttributeAlertConfig;

const createNarrowStyles = (sx?: SystemStyleObject): SystemStyleObject => {
  if (!sx) return {};
  return {
    '@container (max-width: 450px)': sx,
  };
};

export const FormAttributeAlert = ({
  message,
  logo,
}: FormAttributeAlertProps) => {
  return (
    <Alert status="info" sx={{ containerType: 'inline-size' }}>
      <AlertIcon sx={createNarrowStyles({ display: 'none' })} />
      <Flex
        align="center"
        width="100%"
        gap={2}
        sx={createNarrowStyles({
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
        })}
      >
        <Text flexGrow={1}>{message}</Text>
        {logo && (
          <Image src={logo.src} alt={logo.alt ?? 'Logo'} height="20px" />
        )}
      </Flex>
    </Alert>
  );
};
