import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import {
  DropOffSection,
  ItemDetailsSection,
} from '../../order-form-sections';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { UseFormReturn } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { MultiDropPickupReference } from './MultiDropPickupReference';
import { getMerchantConfigKey } from '../../../merchant/config/merchantConfig';

type MultiDropAddDeliveryProps = {
  merchantAccount?: MerchantAccount;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  isDropOffComplete: boolean;
  addDeliveryMarker: AddMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  showExpanded: boolean;
  restrictedEditing: boolean;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  isItemDetailsComplete: boolean;
  handleAddDelivery: () => void;
  deliveryCount: number;
  buttonLabel: string;
  isDisableDropOffCollectionReference: boolean;
  pickupReference?: string | null;
  formattedPickupAddress: string;
  stopId: number;
};

export const MultiDropAddDelivery = ({
  merchantAccount,
  dropOffForm,
  isDropOffComplete,
  addDeliveryMarker,
  removeDeliveryMarker,
  showExpanded,
  restrictedEditing,
  itemForm,
  isItemDetailsComplete,
  handleAddDelivery,
  deliveryCount,
  buttonLabel,
  isDisableDropOffCollectionReference,
  pickupReference,
  formattedPickupAddress,
  stopId,
}: MultiDropAddDeliveryProps) => {
  return (
    <>
      <MultiDropPickupReference
        defaultPickupAddress={merchantAccount?.address ?? ''}
        defaultPickupPostcode={merchantAccount?.postCode ?? ''}
        isSectionComplete={isDropOffComplete}
        form={dropOffForm}
        sectionName="Pick Up"
        isDisableDropOffCollectionReference={
          isDisableDropOffCollectionReference
        }
        pickupReference={pickupReference}
        formattedPickupAddress={formattedPickupAddress}
        merchantConfigKey={merchantAccount && getMerchantConfigKey(merchantAccount)}
      />
      <DropOffSection
        sectionName={`Drop Off - Drop ${deliveryCount}`}
        merchantAccount={merchantAccount}
        form={dropOffForm}
        isSectionComplete={isDropOffComplete}
        addMarker={addDeliveryMarker}
        removeMarker={removeDeliveryMarker}
        showExpanded={showExpanded}
        restrictedEditing={restrictedEditing}
        cypressTestId="dropoff-section"
        stopId={stopId}
      />
      <ItemDetailsSection
        sectionName={`Item Details - Drop ${deliveryCount}`}
        isSectionComplete={isItemDetailsComplete}
        form={itemForm}
        loadLabel={'Load Estimate for Drop Off'}
        hideSection={restrictedEditing}
      />
      <Button onClick={handleAddDelivery} data-cy={'add-drop-button'}>
        {buttonLabel}
      </Button>
    </>
  );
};
