import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import {
  BikeRearIcon,
  BikeSideIcon,
  CarRearIcon,
  CarSideIcon,
  FlatbedRearIcon,
  FlatbedSideIcon,
  LutonRearIcon,
  LutonSideIcon,
  VanSRearIcon,
  VanSSideIcon,
  VanMRearIcon,
  VanMSideIcon,
  VanLRearIcon,
  VanLSideIcon,
  VanXLRearIcon,
  VanXLSideIcon,
} from '../../assets/itemSizingIcons';
import React from 'react';

type VehicleIconProps = {
  isPrimary: boolean;
  vehicleID: DeliveryVehicleId;
  loadDimensions: (sideView: boolean) => {
    xArea: number;
    yArea: number;
    xPosition: string;
    yPosition: string;
  } | null;
};

type IconMap = {
  [key in VehicleIconProps['vehicleID']]: React.FC<{
    width?: number;
    height?: number;
  }>;
};

export const ModalVehicleIcon = ({
  isPrimary,
  vehicleID,
  loadDimensions,
}: VehicleIconProps) => {
  const selectIcon = (
    vehicleID: VehicleIconProps['vehicleID'],
    isPrimaryView: boolean,
  ): React.FC<{
    width?: number;
    height?: number;
    xArea?: number;
    yArea?: number;
    xPosition?: string;
    yPosition?: string;
  }> => {
    const iconMap: IconMap = {
      BIKE: isPrimaryView ? BikeSideIcon : BikeRearIcon,
      BICYCLE: isPrimaryView ? BikeSideIcon : BikeRearIcon,
      MOTORBIKE: isPrimaryView ? BikeSideIcon : BikeRearIcon,
      CAR: isPrimaryView ? CarSideIcon : CarRearIcon,
      VAN_SMALL: isPrimaryView ? VanSSideIcon : VanSRearIcon, // todo - get an SVG for Van Small
      VAN_MEDIUM: isPrimaryView ? VanMSideIcon : VanMRearIcon,
      VAN_LARGE: isPrimaryView ? VanLSideIcon : VanLRearIcon,
      VAN_XLARGE: isPrimaryView ? VanXLSideIcon : VanXLRearIcon,
      LUTON: isPrimaryView ? LutonSideIcon : LutonRearIcon,
      FLATBED: isPrimaryView ? FlatbedSideIcon : FlatbedRearIcon,
      HIAB: isPrimaryView ? FlatbedSideIcon : FlatbedRearIcon, // todo - get an SVG for Hiab
      LORRY_MEDIUM: isPrimaryView ? LutonSideIcon : LutonRearIcon, // todo - get an SVG for Lorry M
    };

    return iconMap[vehicleID];
  };

  const load = loadDimensions(isPrimary);

  const IconComponent = selectIcon(vehicleID, isPrimary);

  if (!IconComponent) {
    return <div>Error: Icon not found for vehicle ID {vehicleID}</div>;
  }

  return (
    <IconComponent
      height={300}
      xArea={load?.xArea}
      yArea={load?.yArea}
      xPosition={load?.xPosition}
      yPosition={load?.yPosition}
    />
  );
};
