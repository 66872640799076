import { RiderIdentity, RiderLocation } from '@tradeaze-packages/schemas';
import { getIsOnDuty } from '@tradeaze/shared/utils';
import { MapMarkerProps } from '../Map';
import { RiderMarker, RiderMarkerMode } from '../RiderMarker';
import { shouldShowRiderMarker } from '../utils/shouldShowRiderMarker';

export const createRiderMarker = (
  rider: RiderIdentity & {
    lastClockedInAt?: string | null;
    onDuty?: boolean | null;
  },
  location: RiderLocation,
  options: {
    isAdmin?: boolean;
    alwaysShowDetails?: boolean;
    hideClockedOut?: boolean;
    isHovered?: boolean;
    setIsHovered?: (isHovered: boolean) => void;
    mode?: RiderMarkerMode;
  },
): MapMarkerProps | undefined => {
  const {
    isAdmin,
    alwaysShowDetails = false,
    hideClockedOut = true,
    isHovered = false,
    setIsHovered,
    mode,
  } = options;

  if (
    !shouldShowRiderMarker({
      updatedAt: location.updatedAt,
      isOnDuty: getIsOnDuty(rider.lastClockedInAt ?? null, rider.onDuty),
      hideClockedOut,
    })
  ) {
    return undefined;
  }

  return {
    id: rider.riderId,
    type: 'RIDER',
    children: (
      <RiderMarker
        rider={rider}
        updatedAt={location.updatedAt}
        alwaysShowDetails={alwaysShowDetails}
        isAdmin={isAdmin ?? false}
        isPopupOpen={isHovered}
        onOpenPopup={() => setIsHovered?.(true)}
        onClosePopup={() => setIsHovered?.(false)}
        isHovered={isHovered}
        mode={mode}
      />
    ),
    position: location,
    anchor: 'center',
    updateZoom: false,
    isHovered,
  };
};
