import { z } from 'zod';

export const ExternalJobStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'PICKUP_COMPLETE',
  'PICKUP_FAILED',
  'DROPOFF_FAILED',
  'DROPOFF_COMPLETE',
  'CANCELLED_EXTERNAL',
  'CANCELLED_INTERNAL',
]);

export const ExternalJobSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  externalFleetId: z.string(),
  externalId: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerLogo: z.string().nullish(),
  status: ExternalJobStatusSchema,
  trackingUrl: z.string(),
  price: z.number(),
  currency: z.string(),
  quoteId: z.string(),
  deliveryId: z.string(),
  externalUrl: z.string(),
  cancelledAt: z.string().nullish(),
  externalQuoteRequestId: z.string().nullish(), // todo - once data migration is complete, make this required
});

export const CreateExternalJobSchema = ExternalJobSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type ExternalJobStatus = z.infer<typeof ExternalJobStatusSchema>;
export type ExternalJob = z.infer<typeof ExternalJobSchema>;
export type CreateExternalJob = z.infer<typeof CreateExternalJobSchema>;